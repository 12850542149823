import {Form, Select} from 'antd';

const {Option} = Select;

const TypeFormItem = ({
                          setSelectedAlertType
                      }) => {
    const alertTypes = ["SITE_DOWN", "LOW_SITE_AVAILABILITY", "LOW_NETWORK_AVAILABILITY"]

    return (
        <Form.Item
            name="alertType"
            label="Type"
            rules={[
                {
                    required: true,
                    message: "Alert type"
                },
            ]}
        >
            <Select
                placeholder="Select type"
                mode="single"
                allowClear
                onChange={setSelectedAlertType}
            >
                {
                    alertTypes.map(type => {
                        return <Option value={type}>{type}</Option>
                    })
                }

            </Select>
        </Form.Item>
    );
}

export default TypeFormItem;