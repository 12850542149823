export const remoteActionEventTypes = {
  GET_SESSION_COST_REQUEST: 'GET_SESSION_COST_REQUEST',
  GET_SESSION_COST_RESPONSE: 'GET_SESSION_COST_RESPONSE',
  CONNECTOR_UNPLUG_NOTIFICATION_REQUEST: 'CONNECTOR_UNPLUG_NOTIFICATION_REQUEST',
  DATA_TRANSFER_REQUEST: 'DATA_TRANSFER_REQUEST',
  AUTHORIZE_REQUEST: 'AUTHORIZE_REQUEST',
  SEND_UPDATED_PRICE_REQUEST: 'SEND_UPDATED_PRICE_REQUEST',
  GET_IMAGE_DATA_REQUEST: 'GET_IMAGE_DATA_REQUEST',
  GET_PRICE_REQUEST: 'GET_PRICE_REQUEST',
  GET_SESSION_FEE_REQUEST: 'GET_SESSION_FEE_REQUEST',
  GET_PRICE_SUMMARY_REQUEST: 'GET_PRICE_SUMMARY_REQUEST',
  GET_PRICE_SUMMARY_RESPONSE: 'GET_PRICE_SUMMARY_RESPONSE',
  LOGGING: 'LOGGING',
  PAYMENT_AUTH_INFO_REQUEST: 'PAYMENT_AUTH_INFO_REQUEST',
  PAYMENT_CAPTURE_INFO_REQUEST: 'PAYMENT_CAPTURE_INFO_REQUEST',
  SESSION_INFO_REQUEST: 'SESSION_INFO_REQUEST',
  SESSION_INSTRUMENTATION_REQUEST: 'SESSION_INSTRUMENTATION_REQUEST',
  METER_VALUES_REQUEST: 'METER_VALUES_REQUEST',
  BOOT_NOTIFICATION_REQUEST: 'BOOT_NOTIFICATION_REQUEST',
  HEARTBEAT_REQUEST: 'HEARTBEAT_REQUEST',
  BOOT_NOTIFICATION_CONFIRMATION: 'BOOT_NOTIFICATION_CONFIRMATION',
  CHANGE_CONFIGURATION_REQUEST: 'CHANGE_CONFIGURATION_REQUEST',
  START_TRANSACTION_REQUEST: 'START_TRANSACTION_REQUEST',
  STOP_TRANSACTION_REQUEST: 'STOP_TRANSACTION_REQUEST',
  AUTHORIZE_CONFIRMATION: 'AUTHORIZE_CONFIRMATION',
  SESSION_INFO_CONFIRMATION: 'SESSION_INFO_CONFIRMATION',
  START_TRANSACTION_CONFIRMATION: 'START_TRANSACTION_CONFIRMATION',
  STOP_TRANSACTION_CONFIRMATION: 'STOP_TRANSACTION_CONFIRMATION',
  REMOTE_STOP_TRANSACTION_REQUEST: 'REMOTE_STOP_TRANSACTION_REQUEST',
  REMOTE_STOP_TRANSACTION_CONFIRMATION: 'REMOTE_STOP_TRANSACTION_CONFIRMATION',
  REMOTE_START_TRANSACTION_REQUEST: 'REMOTE_START_TRANSACTION_REQUEST',
  REMOTE_START_TRANSACTION_CONFIRMATION: 'REMOTE_START_TRANSACTION_CONFIRMATION',
  DATA_TRANSFER_CONFIRMATION: 'DATA_TRANSFER_CONFIRMATION',
  GET_SESSION_FEE_CONFIRMATION: 'GET_SESSION_FEE_CONFIRMATION',
  GET_CUSTOMER_DETAILS_RESPONSE: 'GET_CUSTOMER_DETAILS_RESPONSE',
  GET_PRICE_CONFIRMATION: 'GET_PRICE_CONFIRMATION',
  PAYMENT_CAPTURE_INFO_CONFIRMATION: 'PAYMENT_CAPTURE_INFO_CONFIRMATION',
  GET_15118_CERTIFICATE_REQUEST: 'GET_15118_CERTIFICATE_REQUEST',
  GET_15118_CERTIFICATE_CONFIRMATION: 'GET_15118_CERTIFICATE_CONFIRMATION',
  SEND_UPDATED_PRICE_CONFIRMATION: 'SEND_UPDATED_PRICE_CONFIRMATION',
  UNLOCK_CONNECTOR_REQUEST: 'UNLOCK_CONNECTOR_REQUEST',
  TRIGGER_MESSAGE_REQUEST: 'TRIGGER_MESSAGE_REQUEST',
  UNLOCK_CONNECTOR_CONFIRMATION: 'UNLOCK_CONNECTOR_CONFIRMATION',
  TRIGGER_MESSAGE_CONFIRMATION: 'TRIGGER_MESSAGE_CONFIRMATION',
  UPDATE_FIRMWARE_REQUEST: 'UPDATE_FIRMWARE_REQUEST',
  UPDATE_FIRMWARE_CONFIRMATION: 'UPDATE_FIRMWARE_CONFIRMATION',
  RESET_REQUEST: 'RESET_REQUEST',
  RESET_CONFIRMATION: 'RESET_CONFIRMATION',
  GET_DIAGNOSTICS_REQUEST: 'GET_DIAGNOSTICS_REQUEST',
  GET_DIAGNOSTICS_CONFIRMATION: 'GET_DIAGNOSTICS_CONFIRMATION',
  GET_CONFIGURATION_REQUEST: 'GET_CONFIGURATION_REQUEST',
  GET_CONFIGURATION_CONFIRMATION: 'GET_CONFIGURATION_CONFIRMATION',
  CHANGE_AVAILABILITY_REQUEST: 'CHANGE_AVAILABILITY_REQUEST',
  CHANGE_AVAILABILITY_CONFIRMATION: 'CHANGE_AVAILABILITY_CONFIRMATION',
  DIAGNOSTICS_STATUS_NOTIFICATION_REQUEST: 'DIAGNOSTICS_STATUS_NOTIFICATION_REQUEST',
  FIRMWARE_STATUS_NOTIFICATION_REQUEST: 'FIRMWARE_STATUS_NOTIFICATION_REQUEST',
  CONNECTION_CREATED: 'CONNECTION_CREATED',
  CONNECTION_REMOVED: 'CONNECTION_REMOVED',
  STATUS_NOTIFICATION_REQUEST: 'STATUS_NOTIFICATION_REQUEST',
  CHANGE_CONFIGURATION_CONFIRMATION: 'CHANGE_CONFIGURATION_CONFIRMATION',
};

export const remoteActionTypes = {
  // removing for now since we can't do this
  // but keeping in case we will be able to in the future
  // EVENT_BASED: {
  //   value: 'EVENT_BASED',
  //   label: 'Execute when charger event occurs',
  // },
  SCHEDULED: {
    value: 'SCHEDULED',
    label: 'Execute at a scheduled date',
  },
  IMMEDIATE: {
    value: 'IMMEDIATE',
    label: 'Execute now',
  },
};

export const stateUpdateRemoteActionTypes = {
  IMMEDIATE: {
    value: 'IMMEDIATE',
    label: 'Execute now',
  },
};

export const remoteActionResetTypes = {
  HARD: 'Hard',
  SOFT: 'Soft',
};

export const triggerMessageRequestTypes = [
  {
    value: 'BootNotification',
    label: 'Boot Notification',
  },
  {
    value: 'DiagnosticsStatusNotification',
    label: 'Diagnostics Status Notification',
  },
  {
    value: 'FirmwareStatusNotification',
    label: 'Firmware Status Notification',
  },
  {
    value: 'Heartbeat',
    label: 'Heartbeat',
  },
  {
    value: 'MeterValues',
    label: 'Meter Values',
  },
  {
    value: 'SignV2GCertificate',
    label: 'Sign V2G Certificate'
  },
  {
    value: 'StatusNotification',
    label: 'Status Notification',
  }
];

export const devicesActions = [
  {
    name: 'update_firmware',
    label: 'Update Firmware',
  },
  {
    name: 'hard_reset',
    label: 'Hard Reset',
  },
  {
    name: 'soft_reset',
    label: 'Soft Reset',
  },
  {
    name: 'update_state',
    label: 'Update State',
  },
  {
    name: 'trigger_message_request',
    label: 'Trigger Message Request',
  },
];

export const deviceDetailActions = [
  {
    name: 'update_firmware',
    label: 'Update Firmware',
  },
  {
    name: 'hard_reset',
    label: 'Hard Reset',
  },
  {
    name: 'soft_reset',
    label: 'Soft Reset',
  },
  {
    name: 'trigger_message_request',
    label: 'Trigger Message Request',
  },
  {
    name: 'get_diagnostics_request',
    label: 'Get Diagnostics Request',
  },
];
