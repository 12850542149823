import { Select } from 'antd';
import { allCols } from '_constants/devicesTableColConstants';
import { cols } from '_pages/Devices/devicesTableConfig'

const ColumnsSelector = ({...props }) => {
  const { Option } = Select;
  const children = [];
  const defaultCols = [];
  const stays = cols.map(column => column.dataIndex);
  // check if there are cached selected columns from local storage.
  if (localStorage.getItem('selectedColumns') !== null) {
    const columns = JSON.parse(localStorage.getItem('selectedColumns'));
    columns.forEach((column, index) => {
      if (!stays.includes(column.dataIndex)) defaultCols.push(<Option key={column.dataIndex}>{column.title}</Option>)
    })
  }
  allCols.forEach((column, index) => {
    if (!cols.includes(column)) children.push(<Option key={column.dataIndex}>{column.title}</Option>)
  });

  return (
      <Select
          mode="multiple"
          placeholder="Select columns"

          defaultValue={defaultCols}
          showSearch={false}
          maxTagCount={2}
          style={{marginLeft:'.5rem', minWidth: '15%'}}
          {...props}
      >
        {children}
      </Select>
  );
}

export default ColumnsSelector;