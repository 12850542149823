import { Input, Button, Space, Row, Col } from 'antd';
import { tableFilterType } from '_constants';
import { DatePicker } from 'antd';
import './styles.less';

const { RangePicker } = DatePicker;

const SearchFilterForm = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  searchInputRef,
  autoFocus,
  fieldType = tableFilterType.SEARCH
}) => (
  <div className='search-filter-form'>
    <Space>
      <div className="search-form">
        {fieldType === tableFilterType.SEARCH && (
          <Input
            size="medium"
            autoFocus={autoFocus}
            ref={searchInputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
          />
        )}
        {fieldType === tableFilterType.DATE_RANGE && (
          <RangePicker
            size="medium"
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={(value) => {
              setSelectedKeys(value);
              confirm();
            }}
          />
        )}
      </div>
    </Space>
    <Row>
      <Col span={12} className="left-col">
        <Button
          onClick={() => clearFilters()}
          size="medium"
          block
          className='button'
        >
          Cancel
        </Button>
      </Col>
      <Col span={12}>
        <Button
          onClick={() => confirm()}
          size="medium"
          className='button'
          block
        >
          Apply
        </Button>
      </Col>
    </Row>
  </div>
);

export default SearchFilterForm;