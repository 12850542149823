import {Input, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useGetLocationsFilterOptionsQuery, useGetLocationsQuery} from "../../_services/LocationsAPI";
import RefreshBtn from "../RefreshButton";
import {useState} from "react";

const LocationsTable = ({
                            onRowClick
                        }) => {
    const [queryParams, setQueryParams] = useState({
        id: "",
        operators: [],
        address: "",
        city: "",
        states: []
    })
    const {
        data: locations = [],
        isFetching: isFetchingLocations,
        refetch: refetchLocations
    } = useGetLocationsQuery(queryParams);
    const {
        data: locationFilterOptions = [],
        refetch: refetchLocationFilterOptions
    } = useGetLocationsFilterOptionsQuery();

    // Column filters that generates based on the column data
    const getColumnFilters = (columnData) => {
        return columnData ? columnData.map(e => {
            return {
                text: e,
                value: e
            }
        }) : []
    }

    // Column properties to enable column search functionality
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                {/*  Search box input */}
                <Input.Search
                    placeholder={`Search ${dataIndex}`}
                    // Dismiss search box on `enter` press
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                    allowClear
                    onSearch={(value, event) => {
                        setSelectedKeys([value])
                        confirm({closeDropdown: false})
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined/>),
    });

    const columns = [
        {
            title: 'Location ID',
            dataIndex: 'id',
            ...getColumnSearchProps("id"),
            sorter: (a, b) => b.id.localeCompare(a.id),
            ellipsis: true,
        },
        {
            title: 'Network Operator',
            dataIndex: "operator",
            filters: getColumnFilters(locationFilterOptions.operators),
            sorter: (a, b) => b.operator.localeCompare(a.operator),
            ellipsis: true,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            ...getColumnSearchProps("address"),
            sorter: (a, b) => b.address.localeCompare(a.address),
            ellipsis: true,
        },
        {
            title: 'City',
            dataIndex: 'city',
            ...getColumnSearchProps("city"),
            sorter: (a, b) => b.city.localeCompare(a.city),
            ellipsis: true,
        },
        {
            title: "State",
            dataIndex: "state",
            filters: getColumnFilters(locationFilterOptions.states),
            ellipsis: true,
            sorter: (a, b) => b.state.localeCompare(a.state),
        },
    ];

    return (
        <>
            <div className={'header-button-group'}>
                <RefreshBtn
                    onClick={() => {
                        refetchLocations()
                        refetchLocationFilterOptions()
                    }
                    }
                    tooltipText={'Refresh'}
                />
            </div>
            <Table
                rowClassName="clickable-row"
                columns={columns}
                dataSource={locations}
                rowKey={record => record.id}
                loading={isFetchingLocations}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onRowClick(record);
                        }
                    }
                }}
                onChange={(pagination, filters, sorter, extra) => {
                    setQueryParams({
                        id: filters.id ? filters.id : "",
                        operators: filters.operator ? filters.operator.toString() : [],
                        address: filters.address ? filters.address : "",
                        city: filters.city ? filters.city : "",
                        states: filters.state ? filters.state.toString() : []
                    })
                }}
            />
        </>)
}

export default LocationsTable;