export const processHeader = (title) => {
  const split = title.split('/').filter(item => item !== '');
  if (title.match(/\/devices\/(.*)\/logs/)) {
    return { title: 'Device Logs' };
  } else if (split.length === 2) {
    return processWithDetails(split[0]);
  } else if (!split[0]) {
    return { title: 'Home' };
  } else {
    return { title: formatHeader(split[0]) };
  }
}

const processWithDetails = (title) => {
  if (title.includes('device')) {
    return { title: null, backButtonText: 'All Devices', route: '/devices' };
  } else if (title.includes('alert')) {
    return { title: null, backButtonText: 'All Alerts', route: '/alerts' };
  } else if (title.includes('charging-group')) {
    return { title: null, backButtonText: 'All Charging Groups', route: '/charging-groups' };
  } else if (title.includes('locations')) {
    return { title: null, backButtonText: 'All Locations', route: '/locations' };
  }
}

const formatHeader = (header) => {
  return header ? header.split('-').join(" ") : "";
}