import { get, post, createParams, put, del } from "../_helpers";

function getChargingGroups({ page, pageSize, filters, sorter }, auth0Token) {
  const params = createParams(page, pageSize, filters, sorter, null);
  return get({ path: 'charging-groups', parameters: params }, auth0Token);
}

function getDeviceChargingGroup(deviceId, auth0Token) {
  return get({ path: `devices/${deviceId}/charging-group` }, auth0Token);
}

function getChargingGroupById({ id }, auth0Token) {
  return get({ path: `charging-groups/${id}` }, auth0Token);
}

function createChargingGroup(detail, auth0Token) {
  return post({path: 'charging-groups', body: detail }, auth0Token);
}

function updateChargingGroup(detail, auth0Token) {
  return put({path: `charging-groups/${detail.id}`, body: detail }, auth0Token);
}

function deleteChargingGroup(id, auth0Token) {
  return del({path: `charging-groups/${id}`}, auth0Token);
}

function publishChargingGroup(id, auth0Token) {
  return post({path: `charging-groups/${id}/publish`}, auth0Token);
}

export const chargingGroupService = {
  getChargingGroups,
  getChargingGroupById,
  getDeviceChargingGroup,
  createChargingGroup,
  updateChargingGroup,
  deleteChargingGroup,
  publishChargingGroup
};
