import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceLogsDataAction } from '_store/deviceLogs';
import { exportRawLogsDataAction } from '_store/logs';
import { useApi } from './useApi.hook';
import { defaultTablePageSize, timeFrame } from "../_constants";

//needs to accept selectedAlert if id in param
export const useDeviceLogs = (columns, device) => {
  const { data: logsData, loading, pagination, filters, sorter } = useSelector(state => state.deviceLogsReducer);
  const { auth0Token, loading: tokenLoading } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && device && device.chargeboxId && auth0Token && dispatch && filters) {
      dispatch(getDeviceLogsDataAction({
        chargeboxId: device.chargeboxId,
        page: 1,
        pageSize: defaultTablePageSize.logs,
        filters,
        sorter,
        auth0Token,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, device, auth0Token]);

  const getOcppLogs = () => {
    dispatch(getDeviceLogsDataAction({
      chargeboxId: device?.chargeboxId,
      page: 1,
      pageSize: defaultTablePageSize.logs,
      filters: {},
      sorter: {},
      auth0Token,
    }));
  }

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(getDeviceLogsDataAction({
      chargeboxId: device?.chargeboxId,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
      sorter,
      auth0Token,
    }));
  }

  const onClickExportRawLogs = (deviceId, startDate, endDate) => {
    dispatch(exportRawLogsDataAction({deviceId, startDate, endDate, auth0Token}));
  }

  return {
    logsData,
    loading,
    pagination,
    onChange,
    columns,
    getOcppLogs,
    timeFrame,
    onClickExportRawLogs
  };
};
