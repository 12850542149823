import { Button, Popconfirm, Table } from 'antd';
import { useNavigate } from "react-router-dom";
import { tableFilterType } from '_constants';
import { useTableFilter } from '_hooks/tableFilter.hook';
import ChargingGroupFormDrawer from './components/ChargingGroupFormDialog';
import './styles.less';
import { DeleteTwoTone, ExclamationCircleOutlined, setTwoToneColor } from "@ant-design/icons";
import * as permConsts from "../../_constants/permissionConstants";
import { usePermissions } from "../../_hooks/permissions.hook";
import { useState } from "react";

const ChargingGroupsView = ({
  columns,
  data,
  onChange,
  pagination,
  loading,
  sorter,
  tariffsData,
  tariffsLoading,
  getTariffsByCountryCode,
  handleSubmit,
  handleDelete
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);

  setTwoToneColor("#ff0000");

  const handleCreate = () => {
    setSelectedRecord(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const permissions = usePermissions();

  const navigateToDetails = (record) => {
    navigate(`/charging-groups/${record.id}`);
  }

  const canCreateChargingGroups = permissions.checkPermission(permConsts.components.chargingGroups, permConsts.actions.create);

  const canDeleteChargingGroups = permissions.checkPermission(permConsts.components.chargingGroups, permConsts.actions.delete);

  const newColumns = canDeleteChargingGroups ? [
    ...columns,
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '3%',
      render: () =>
        <Popconfirm okText="Yes" cancelText="No" title="Delete charging group?"
            onConfirm={() => {
              handleDelete(recordToDelete)
            }}
            icon={<ExclamationCircleOutlined style={{color: 'red'}}/>}>
        <Button><DeleteTwoTone/></Button>
      </Popconfirm>,
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            e.stopPropagation();
            setRecordToDelete(record);
          }
        }
      }
    }] : columns;

  const { columns: finalColumns } = useTableFilter(newColumns, 'name', 0, true, tableFilterType.SEARCH);

  const table = 
    <Table
      rowClassName="clickable-row"
      onRow={(record) => {
        return {
          onClick: () => {
            navigateToDetails(record);
          }
        }
      }}
      rowKey={record => record.id}
      columns={finalColumns}
      dataSource={data}
      loading={loading}
      sorter={sorter}
      onChange={onChange}
      pagination={pagination}
    />;
  return (
    <div>
      <div className={'header-button-group'}>
        { canCreateChargingGroups &&
          <Button
            className={'action-button'}
            onClick={handleCreate}
            type='primary'
            size="middle"
          >
            Create Charging Group
          </Button>
        }
      </div>
      {table}
      <ChargingGroupFormDrawer
        open={open}
        title={selectedRecord ? 'Update Charging Group' : 'Create Charging Group'}
        record={selectedRecord}
        tariffsData={tariffsData}
        tariffsLoading={tariffsLoading}
        getTariffsByCountryCode={getTariffsByCountryCode}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default ChargingGroupsView;