import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { chargingGroupService } from '_services';

export const getChargingGroupsDataAction = createAsyncThunk(
  'chargingGroups/get',
  async ({ page, pageSize, filters, sorter, auth0Token }) => {
    return chargingGroupService.getChargingGroups({ page, pageSize, filters, sorter }, auth0Token);
  }
);

export const getChargingGroupDataByIdAction = createAsyncThunk(
  'chargingGroups/id',
  async ({ id, auth0Token }) => {
    return chargingGroupService.getChargingGroupById({ id }, auth0Token);
  }
)

export const createChargingGroup = createAsyncThunk(
  'chargingGroups/create',
  async ({ auth0Token, ...payload }) => {
    return await chargingGroupService.createChargingGroup(payload, auth0Token); 
  }
);

export const updateChargingGroup = createAsyncThunk(
  'chargingGroups/update',
  async ({ auth0Token, ...payload }) => {
    return await chargingGroupService.updateChargingGroup(payload, auth0Token); 
  }
);

export const deleteChargingGroup = createAsyncThunk(
  'chargingGroups/delete',
  async ({ id, auth0Token }) => {
    return await chargingGroupService.deleteChargingGroup(id, auth0Token);
  }
);


const chargingGroupsSlice = createSlice({
  name: 'chargingGroups',
  initialState: {
    data: [],
    actionLoading: null,
    pagination: {
      total: 0,
    },
    filters: {},
    sorter: {},
    chargingGroup: {}
  },
  reducers: {},
  extraReducers: {
    [getChargingGroupsDataAction.fulfilled]: (state, action) => {
      state.data = action.payload.content;
      state.pagination.current = action.payload.number + 1;
      state.pagination.total = action.payload.totalElements;
      state.pagination.pageSize = action.payload.size;
      state.filters = action.meta.arg.filters;
      state.sorter = action.meta.arg.sorter;
      state.loading = false;
    },
    [getChargingGroupsDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getChargingGroupsDataAction.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
    },
    [getChargingGroupDataByIdAction.fulfilled]: (state, action) => {
      state.chargingGroup = action.payload;
      state.loading = false;
    },
    [getChargingGroupDataByIdAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getChargingGroupDataByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.chargingGroup = null;
    },
    [createChargingGroup.fulfilled]: (state, action) => {
      state.updating = false;
    },
    [createChargingGroup.pending]: (state, action) => {
      state.updating = true;
    },
    [createChargingGroup.rejected]: (state, action) => {
      state.updating = false;
    },
    [updateChargingGroup.fulfilled]: (state, action) => {
      state.updating = false;
    },
    [updateChargingGroup.pending]: (state, action) => {
      state.updating = true;
    },
    [updateChargingGroup.rejected]: (state, action) => {
      state.updating = false;
    },
    [deleteChargingGroup.fulfilled]: (state, action) => {
      state.updating = false;
    },
    [deleteChargingGroup.pending]: (state, action) => {
      state.updating = true;
    },
    [deleteChargingGroup.rejected]: (state, action) => {
      state.updating = false;
    },
  },
});

export default chargingGroupsSlice.reducer;
