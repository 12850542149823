import PermissionCheck from '_partial/PermissionCheck';
import {useParams} from "react-router-dom";
import * as permConsts from "../../_constants/permissionConstants";
import {useGetLocationQuery} from "../../_services/LocationsAPI";
import LocationTabs from "./components/LocationTabs/LocationTabs";

const Location = () => {
    const {locationId} = useParams();
    const {
        data: location,
    } = useGetLocationQuery(locationId);
    return (
        <PermissionCheck page={permConsts.components.devices}>
            <h1><b>{locationId}</b></h1>
            <LocationTabs location={location}/>
        </PermissionCheck>
    )
}
export default Location;