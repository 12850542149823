import { stringify } from 'querystring';
import { baseUrl } from '_constants';
/**
 * API Error - compatible with instanceof
 */
export class ApiError {
  constructor(code, message, body) {
    this.code = code;
    this.message = message;
    this.body = body;
  }
}
Object.setPrototypeOf(ApiError, Error);

/**
 * Handle response from a request (expect json)
 * @private
 *
 * @param {Object} response - Fetch response object
 */

export async function handleResponse(response) {
  const contentType = response.headers.get('content-type');
  const statusCode = response.status;
  const contentDisposition = response.headers.get("content-disposition");
  if (statusCode < 400) {
    // download csv endpoint returns content as 'text/plain' instead of 'text/csv'
    if (contentType && contentType.includes('octet-stream')) {
       // Extract file name from content-discomposion
      const filename = contentDisposition.substring(contentDisposition.indexOf('=') + 1);
      return response.blob()
        .then(blob => {
          return {
            filename,
            blob,
          };
        });
    }
    return response
      .json()
      .catch(() => null)
      .then((body) => body);
  }

  return response
    .json()
    .catch(() => null)
    .then((body) => {
      throw new ApiError(statusCode, response.statusText, body);
    });
}

export const downloadBlob = (filename, blob) => {
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

/**
 * Generic request
 *
 * @param {string} path - request path (no leading '/')
 * @param {Object} opts - options passed on to the fetch request
 */
export function request({ path, opts = {}, rootURL = '' }, auth0Token = null) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(opts.headers || {})
  };
  if (auth0Token) {
    headers['Authorization'] = `Bearer ${auth0Token}`;
  }
  return fetch(`${rootURL || baseUrl}/${path}`, {
    mode: 'cors',
    ...opts,
    headers,
  }).then(handleResponse);
}

/**
 * GET request
 *
 * @param {string} path - request path (no leading '/')
 * @param {Object} parameters - request parameters in object form
 * @param {Object} opts - options passed on to the fetch request
 */
export function get({ path, parameters = {}, opts = {} }, auth0Token = null) {
  const search = stringify(parameters);
  return request(
    {
      path: `${path}?${search}`,
      opts: {
        method: 'GET',
        ...opts,
      },
    },
    auth0Token
  );
}

/**
 * POST request
 *
 * @param {string} path - request path (no leading '/')
 * @param {Object} body - requesty body
 * @param {Object} opts - options passed on to the fetch request
 */
export function post({ path, body = {}, opts = {} }, auth0Token = null) {
  return request(
    {
      path,
      opts: {
        method: 'POST',
        body: JSON.stringify(body),
        ...opts,
      },
    },
    auth0Token
  );
}

/**
 * PUT request
 *
 * @param {string} path - request path (no leading '/')
 * @param {Object} body - requesty body
 * @param {Object} opts - options passed on to the fetch request
 */
export function put({ path, body = {}, opts = {} }, auth0Token = null, jsonPayload = true) {
  return request(
    {
      path,
      opts: {
        method: 'PUT',
        body: jsonPayload ? JSON.stringify(body) : body,
        ...opts,
      },
    },
    auth0Token
  );
}

/**
 * PUT request
 *
 * @param {string} path - request path (no leading '/')
 * @param {Object} body - requesty body
 * @param {Object} opts - options passed on to the fetch request
 */
 export function patch({ path, body = {}, opts = {} }, auth0Token = null, jsonPayload = true) {
  return request(
    {
      path,
      opts: {
        method: 'PATCH',
        body: jsonPayload ? JSON.stringify(body) : body,
        ...opts,
      },
    },
    auth0Token
  );
}
/**
 * DELETE request
 *
 * @param {string} path - request path (no leading '/')
 * @param {Object} parameters - request parameters in object form
 * @param {Object} opts - options passed on to the fetch request
 */
export function del({ path, body = {}, parameters = {}, opts = {} }, auth0Token = null) {
  const search = stringify(parameters);
  return request(
    {
      path: `${path}?${search}`,
      opts: {
        method: 'DELETE',
        body: JSON.stringify(body),
        ...opts,
      },
    },
    auth0Token
  );
}

export function deleteList({ path, body = {}, opts = {} }, auth0Token) {
  return request({
    path,
    opts: {
      method: 'DELETE',
      body: JSON.stringify(body),
      ...opts,
    },
  }, auth0Token);
}
