import {Tabs} from "antd";
import LocationDetailsTabItem from "./LocationDetailsTabItem/LocationDetailsTabItem";

const LocationTabs = ({
                          location = {}
                      }) => {
    return (
        <Tabs items={[
            {
                label: "Details",
                key: "details",
                children: <LocationDetailsTabItem location={location}></LocationDetailsTabItem>
            },
        ]}>
        </Tabs>
    )
}
export default LocationTabs;