import {useDispatch} from 'react-redux';
import {useApi} from './useApi.hook';
import {getDeviceDiagnosticsThunk} from "../_store/deviceDiagnostics";

export const useDeviceDiagnostics = (device) => {
    const {auth0Token: token} = useApi();

    const dispatch = useDispatch();

    const getDeviceDiagnostics = (date) => {
        dispatch(getDeviceDiagnosticsThunk({
            chargeboxId: device?.chargeboxId,
            date,
            token,
        }));
    }

    return {
        getDeviceDiagnostics,
        token
    };
};
