import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tariffsService } from '_services';

export const getTariffs = createAsyncThunk(
  'tariff',
  async ({ countryCode, auth0Token }) => {
    return await tariffsService.getTariffs(countryCode, auth0Token);
  }
);

const tariffsSlice = createSlice({
  name: 'tariffs', 
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: {
    [getTariffs.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getTariffs.pending]: (state, action) => {
      state.loading = true;
    },
    [getTariffs.rejected]: (state, action) => {
      state.loading = false;
    }
  }
});

export default tariffsSlice.reducer;