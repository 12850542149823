import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from '_constants';
import {security} from "../_helpers/Security";

export const locationsAPI = createApi({
    reducerPath: 'locationsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/`,
        prepareHeaders: async (headers, {getState}) => {
            const token = await security.getToken()
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getLocations: builder.query({
            query: (params) => {
                return `locations/overviews?id=${params.id}&operators=${params.operators.toString()}&address=${params.address}&city=${params.city}&states=${params.states.toString()}`
            }
        }),
        getLocationsFilterOptions: builder.query({
            query: () => {
                return `locations/filter-options`
            }
        }),
        getLocation: builder.query({
            query: (id) => {
                return `locations/${id}`
            }
        }),
    }),
});

export const {
    useGetLocationsQuery,
    useGetLocationsFilterOptionsQuery,
    useGetLocationQuery,
} = locationsAPI