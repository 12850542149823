import {DatePicker, Space, Table} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import {useDeviceDiagnostics} from "../../_hooks/deviceDiagnostics.hook";
import {useEffect} from "react";

/**
 * View EVSE Diagnostics.
 *
 * @param device
 * @returns {JSX.Element}
 * @constructor
 */
const DeviceDiagnosticsView = ({
                                   device
                               }) => {
    const dateFormat = 'YYYY-MM-DD';

    const {getDeviceDiagnostics, token} = useDeviceDiagnostics(device)

    const deviceDiagnosticsSlice = useSelector(state => state.deviceDiagnosticsSlice);

    const columns = [
        {
            title: 'Filename',
            dataIndex: 'fileName',
            render: (text, record) => <a target="_blank" href={record.url} rel="noreferrer">{text}</a>
        },
    ];

    // Fetch data on start up
    useEffect(() => {
        if (token) {
            const todayStr = moment().format(dateFormat)
            getDeviceDiagnostics(todayStr)
        }
        // eslint-disable-next-line
    }, [token])

    return (
        <>
            <Space direction="vertical" style={{
                display: 'flex',
            }}>
                {/* DATE PICKER */}
                <DatePicker
                    defaultValue={moment()}
                    onChange={(date, dateStr) => {
                        getDeviceDiagnostics(dateStr, dateStr)
                    }}
                    format={dateFormat}
                />

                {/* DIAGNOSTICS TABLE */}
                <Table
                    columns={columns}
                    dataSource={deviceDiagnosticsSlice?.data}
                    rowKey={record => record.fileName}
                />
            </Space>
        </>
    );
};

export default DeviceDiagnosticsView