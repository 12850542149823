import { Layout } from 'antd';
import GoBackButton from "../GoBackButton";
import { processHeader } from "./headerUtils";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

const { Header } = Layout;

const CustomHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const headerInfo = processHeader(pathname);

  const handleBack = (route) => {
    navigate(route);
  }

  // TODO refactor so that button bar allows for more context buttons

  return (
    <>
      {headerInfo.backButtonText &&
        <GoBackButton onClick={() => handleBack(headerInfo.route)} title={headerInfo.backButtonText} /> }
      {headerInfo.title && <Header className="site-layout-background site-title">
        {headerInfo.title}
      </Header>}
    </>
  );
}

export default CustomHeader;
