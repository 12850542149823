import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { deviceLogsService } from '_services';
import { defaultTablePageSize } from "../../_constants";

export const getDeviceLogsDataAction = createAsyncThunk(
  'deviceOcppLogs/get',
  async ({ chargeboxId, page, pageSize, filters, sorter, auth0Token }) => {
    const response = await deviceLogsService.getDeviceLogs({ chargeboxId, page, pageSize, filters, sorter }, auth0Token);
    response.filters = filters;
    response.sorter = sorter;
    return response;
  }
);

const deviceLogsSlice = createSlice({
  name: 'deviceLogs',
  initialState: {
    data: [],
    pagination: {
      pageSize: defaultTablePageSize.logs,
      total: 0,
    },
    filters: {},
    sorter: {},
  },
  reducers: {},
  extraReducers: {
    [getDeviceLogsDataAction.fulfilled]: (state, action) => {
      state.data = action.payload.content;
      state.pagination.current = action.payload.number + 1;
      state.pagination.total = action.payload.totalElements;
      state.pagination.pageSize = action.payload.size;
      state.filters = action.meta.arg.filters;
      state.sorter = action.meta.arg.sorter;
      state.loading = false;
    },
    [getDeviceLogsDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getDeviceLogsDataAction.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default deviceLogsSlice.reducer;
