import PageLoader from '_partial/PageLoader';
import { usePermissions } from '_hooks/permissions.hook';
import { Empty, Typography } from 'antd';
import { actions } from "../../_constants/permissionConstants";

const { Title } = Typography;

const PermissionCheck = (props) => {

  const { checkPermission, loading } = usePermissions();

  if (!checkPermission(props.page, actions.read)) {
    return (
      <Empty
        imageStyle={{
          height: 60,
        }}
        description={
          <Title level={5} type='secondary'>
            You don't have permission to see this page.
          </Title>
        }
      />
    );
  }
  if (loading) {
    return <PageLoader title="Loading ..."/>;
  }

  return (
    <>
      {props.children}
    </>
  );
}


export default PermissionCheck;
