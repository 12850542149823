import { get, post, put } from "../_helpers";

const basePath = 'configuration-settings';

function getDeviceConfiguration(chargeboxId, auth0Token) {
  return get({ path: `${basePath}/${chargeboxId}` }, auth0Token);
}

function createDeviceConfiguration(body, auth0Token) {
  return post({ path: `${basePath}/${body.chargeboxId}`, body: body ,opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

function updateDeviceConfiguration(body, auth0Token) {
  return put({ path: `${basePath}/${body.chargeboxId}`, body: body ,opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

export const configurationService = {
  getDeviceConfiguration,
  createDeviceConfiguration,
  updateDeviceConfiguration,
}