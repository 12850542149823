import {Table, Button, Space, DatePicker, Popover } from 'antd';
import DeltaDetailDrawer from '_components/DeltaDetailDrawer';
import { useState } from "react";
import moment from "moment";
import {errorNotification} from "../../_components/Notification";

const { RangePicker } = DatePicker;

const DeviceOcppLogsView = ({
  columns,
  data,
  chargeboxId,
  onChange,
  pagination,
  sorter,
  onClickExportRawLogs
}) => {
  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [exportBoxOpened, setExportBoxOpened] = useState(false);
  const [logData, setLogData] = useState([]);
  // TODO allow export of this table and set show row select
  // const [showRowSelect, setShowRowSelect] = useState(false);
  const showRowSelect = false; // remove when setShowRowSelect is implemented

  const handleOpen = (dataRecord) => {
    const data = [
      {
        label: 'Data',
        detail: dataRecord,
      },
    ];
    setLogData(data);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const additionalTableProps = showRowSelect ? {} : {
    className: 'clickable-row', onRow: (record) => {
      return {
        onClick: () => {
          handleOpen(record);
        }
      }
    }
  };

  const handleExportClicked = () => {
    if (dates[1].diff(dates[0], "days") > 7) {
      errorNotification("Failed to export", { message: "Dates must be a maximum of 7 days apart!"})
    } else {
      onClickExportRawLogs(chargeboxId, dates[0], dates[1]);
    }
    setExportBoxOpened(false)
  }

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = (dates[1] && dates[1].diff(current, 'days') > 7) || (current < moment().subtract(1, 'year'));
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (<>
    <div className="deviceDetailContainer">
      <div className="descriptionDetailContainer">
        <div className='export-raw-logs'>
          <Popover
              title="Export logs from date range:"
              content={
                <Space direction="vertical" size={12}>
                  <RangePicker
                      value={hackValue || value}
                      disabledDate={disabledDate}
                      onCalendarChange={val => setDates(val)}
                      onChange={val => setValue(val)}
                      onOpenChange={onOpenChange}
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format="YYYY-MM-DD HH:mm"
                  />
                  <Button onClick={handleExportClicked} type="primary" size="middle">Export</Button>
                </Space>
              }
              trigger="click"
              visible={exportBoxOpened}
              onOpenChange={(newOpen) => setExportBoxOpened(newOpen)}
          >
            <Button on onClick={() => setExportBoxOpened(true)}
              type="primary"
              size="middle"
            >Export Raw CSV Logs</Button>
          </Popover>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          sorter={sorter}
          rowKey='id'
          onChange={onChange}
          pagination={pagination}
          tableLayout='fixed'
          {...additionalTableProps}
        />
        <DeltaDetailDrawer
          open={open}
          title='Log Detail'
          width='60%'
          handleClose={handleClose}
          data={logData}
        />
      </div>
    </div>
  </>);
}

export default DeviceOcppLogsView;