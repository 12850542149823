import { get, post, put, del } from "../_helpers";

const basePath = 'global-configuration-key';

function getAllGlobalConfigurationKeys(auth0Token) {
  return get({ path: basePath }, auth0Token);
}

function addGlobalConfigurationKey(body, auth0Token) {
  return post({ path: basePath, body: body, opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

function updateGlobalConfigurationKeys(body, auth0Token) {
  return put({ path: basePath, body: body, opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

function deleteGlobalConfigurationKeys(id, auth0Token) {
  return del({ path: basePath, body: [id], opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

export const configurationKeysService = {
  getAllGlobalConfigurationKeys,
  addGlobalConfigurationKey,
  updateGlobalConfigurationKeys,
  deleteGlobalConfigurationKeys
}
