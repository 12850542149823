import {Tag, Tooltip} from "antd";
import {truncateString} from "_helpers";

const maxArrayLen = 3;
const maxStringLen = 19;

const DeviceTags = ({tags}) => {
    const result = [];

    if (tags) {
        for (let i = 0; i < tags.length; i++) {
            // the tags col in the devices page only shows at most 3 tags
            // other tags are shown as count, e.g., +2
            if (i < maxArrayLen) {
                const tagElem = (
                    <Tag key={tags[i]} color={"blue"}>{truncateString(tags[i], maxStringLen, maxStringLen)}</Tag>);
                if (tags[i].length > maxStringLen) {
                    // when a tag is too long, it is show as "tag content..."
                    // but when the user put the mouse on the tag, the tooltip shows the full tag content
                    result.push((<Tooltip title={tags[i]} key={tags[i]}>{tagElem}</Tooltip>));
                } else {
                    result.push(tagElem)
                }
            } else {
                // when there are more than 3 tags, it counts the number of left not shown tags
                let left = '+' + (tags.length - i)
                result.push(<Tag key={left} color={"blue"}>{left}</Tag>)
                break;
            }
        }
    }

    return (
        result
    );
}

export default DeviceTags;