import {Button, Form} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import SiteSelector from "./SiteSelector";
import {useState} from "react";

const SitesFormItem = ({
                           hidden,
                           selectedAlertType,
                           selectedSiteIds,
                           setSelectedSiteIds,
                       }) => {
    const [isSiteTableVisible, setIsSiteTableVisible] = useState(false)

    const [intermediateSelectedSiteIds, setIntermediateSelectedSiteIds] = useState([]);

    return (
        <Form.Item
            name="sites"
            label="Sites"
            rules={[
                {
                    validator: async () => {
                        if (selectedAlertType === "LOW_SITE_AVAILABILITY" && selectedSiteIds.length < 2) {
                            return Promise.reject(new Error('Must select at least 2 sites'));
                        } else if (selectedAlertType === "SITE_DOWN" && selectedSiteIds.length < 1) {
                            return Promise.reject(new Error('Must select at least 1 site'));
                        }
                    },
                },
            ]}
            hidden={hidden}
        >
            {/* BUTTON TO MAKE SITE SELECTOR VISIBLE */}
            <Button
                onClick={() => {
                    // Reset `intermediateSelectedIds` before modal becomes visible
                    setIntermediateSelectedSiteIds(selectedSiteIds)
                    setIsSiteTableVisible(true)
                }}
                icon={selectedSiteIds.length > 0 ? null : <PlusOutlined/>}
                block>
                {
                    selectedSiteIds.length > 0 ? `Selected ${selectedSiteIds.length} sites` : "Add sites"
                }

            </Button>

            {/* SITE SELECTOR MODAL */}
            <SiteSelector isVisible={isSiteTableVisible} setIsVisible={setIsSiteTableVisible}
                          selectedIds={selectedSiteIds} setSelectedIds={setSelectedSiteIds}
                          intermediateSelectedIds={intermediateSelectedSiteIds}
                          setIntermediateSelectedIds={setIntermediateSelectedSiteIds}
                          hideSelectAll={false}
                          maxSelectable={null}>
            </SiteSelector>
        </Form.Item>
    );
}

export default SitesFormItem;