import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {deviceDiagnosticsService} from '_services';

export const getDeviceDiagnosticsThunk = createAsyncThunk(
    'deviceDiagnostics/async/get',
    async ({chargeboxId, date, token}) => {
        return await deviceDiagnosticsService.getDiagnostics(chargeboxId, {
            date,
        }, token);
    }
);


export default createSlice({
    name: 'deviceDiagnostics',
    initialState: {
        data: [],
    },
    reducers: {},
    extraReducers: {
        [getDeviceDiagnosticsThunk.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getDeviceDiagnosticsThunk.pending]: (state, action) => {
            state.loading = true;
        },
        [getDeviceDiagnosticsThunk.rejected]: (state, action) => {
            state.loading = false;
        },
    },
}).reducer;