import { useDispatch, useSelector } from "react-redux";
import { useApi } from "./useApi.hook";
import { useEffect } from "react";
import {exportStatusOverviewAction, getStatusOverviewAction} from "../_store/statusOverview";

export const useStatusOverview = () => {
  const { statusOverview, statusOverviewLoading } =
    useSelector((state) => state.statusOverviewReducer);

  const { auth0Token, loading: tokenLoading } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && auth0Token) {
      dispatch(getStatusOverviewAction({auth0Token}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token]);

  const getStatusOverview = () => {
    dispatch(getStatusOverviewAction({auth0Token}));
  }

  const onClickExport = () => {
    dispatch(exportStatusOverviewAction({
      auth0Token
    }))
  }

  return {
    statusOverview,
    statusOverviewLoading,
    getStatusOverview,
    onClickExport
  };
};