import HomeView from './HomeView';
import PermissionCheck from '_partial/PermissionCheck';
import { useStatusOverview } from "../../_hooks/home.hook";
import { components } from "../../_constants/permissionConstants";

const HomeController = () => {
  const { ...statusLogic } = useStatusOverview();

  // home and devices page can share their permissions
  return (
    <PermissionCheck page={components.devices}>
      <HomeView {...statusLogic} />
    </PermissionCheck>
  );
}

export default HomeController;