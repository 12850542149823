import { Button } from 'antd';
import CreateOrUpdateAlertModal from '../../_components/CreateOrUpdateAlertModal/CreateOrUpdateAlertModal';
import { useState } from "react";
import AlertsTable from '../../_components/AlertsTable/AlertsTable'
import { PlusCircleFilled } from "@ant-design/icons";
import { usePermissions } from "../../_hooks/permissions.hook";
import * as permConsts from "../../_constants/permissionConstants";

/**
 * View that contains a {@link AlertsTable} for viewing alerts, and a button to open the {@link CreateOrUpdateAlertModal} for creating/updating an Alert.
 *
 * @param alertsSlice Slice of the state belonging to Alerts
 * @param getAlerts Function for retrieving Alerts from the backend
 * @param createOrUpdateAlert Function for creating/updating Alerts on the backend
 * @param deleteAlert Function for deleting Alerts from the backend
 * @returns {JSX.Element}
 * @constructor
 */
const AlertsView = ({
                        alertsSlice,
                        getAlerts,
                        createOrUpdateAlert,
                        deleteAlert
                    }) => {
    const [open, setOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const permissions = usePermissions();

    return (
        <>
            {/* BUTTON TO CREATE ALERT  */}
            <div className={'header-button-group'}>

              { permissions.checkPermission(permConsts.components.alerts, permConsts.actions.create) &&
                <Button
                  onClick={() => setOpen(true)}
                  type='primary'
                  size="middle"
                  icon={<PlusCircleFilled/>}
                >
                  Create Alert
                </Button>
              }
            </div>
            {/* ALERTS TABLE */}
            <AlertsTable alertsSlice={alertsSlice} setOpen={setOpen} setSelectedRecord={setSelectedRecord}
                         getAlerts={getAlerts}
                         deleteRecord={deleteAlert}/>
            {/* CREATE/UPDATE ALERT MODAL */}
            <CreateOrUpdateAlertModal
                open={open}
                setOpen={setOpen}
                selectedRecord={selectedRecord}
                setSelectedRecord={setSelectedRecord}
                records={alertsSlice.data}
                getAlerts={getAlerts}
                createOrUpdateAlert={createOrUpdateAlert}
            />
        </>
    );
}

export default AlertsView;