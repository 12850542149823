import {Form, Select, Tag} from 'antd';

const {Option} = Select;

const SeverityFormItem = () => {
    return (
        <Form.Item
            name="alertSeverity"
            label="Severity"
            rules={[
                {
                    required: true,
                    message: "Alert severity"
                },
            ]}
        >
            <Select
                placeholder="Select severity"
                mode="single"
                allowClear
            >
                <Option value={"LOW"}><Tag>LOW</Tag></Option>
                <Option value={"HIGH"}><Tag color="orange">HIGH</Tag></Option>
                <Option value={"CRITICAL"}><Tag color="red">CRITICAL</Tag></Option>
            </Select>
        </Form.Item>
    );
}

export default SeverityFormItem;