import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {message} from 'antd';
import {deviceService} from '_services';
import {buildDeviceEventPayload, buildDeviceStatePayload} from '_helpers/device';
import {stringify} from "querystring";
import {defaultTablePageSize} from "../../_constants";
import {downloadBlob} from '_helpers/api';

export const getDevicesDataAction = createAsyncThunk(
    'devices',
    async ({page, pageSize, filters, sorter, auth0Token}) => {
        return deviceService.getDevices({page, pageSize, filters, sorter}, auth0Token);
    }
);

export const exportDevicesDataAction = createAsyncThunk(
    'devices/export',
    async ({filters, auth0Token}) => {
        const response = await deviceService.exportDevices({filters}, auth0Token);
        if (response)
            downloadBlob(response.filename, response.blob);
    }
);

export const getDeviceDataByIdAction = createAsyncThunk(
    'devices/id',
    async ({id, auth0Token}) => {
        return deviceService.getDeviceById(id, auth0Token);
    }
);

export const getCertificatesForDevice = createAsyncThunk(
    'devices/chargeboxId/certificates',
    async ({chargeboxId, auth0Token}) => {
        return deviceService.getCertificatesForDevice(chargeboxId, auth0Token);
    }
);

// deviceIds MUST be the list of chargeboxIds
export const executeDeviceAction = createAsyncThunk(
    'devices/id/execute',
    async ({
               deviceIds,
               eventType,
               resetType,
               requestedMessage,
               remoteActionType,
               scheduledTime,
               startTime,
               stopTime,
               auth0Token,
               getAll,
               deselectedRows,
               currentFilters
           }) => {
        const params = stringify({getAll, deselected: deselectedRows, ...currentFilters});
        return deviceService.executeAction(params,
            buildDeviceEventPayload(eventType, deviceIds, resetType, remoteActionType, scheduledTime, requestedMessage, startTime, stopTime),
            auth0Token
        );
    }
)

// deviceIds MUST be the list of chargeboxIds
export const updateDevicesStateAction = createAsyncThunk(
    'devices/id/update_state',
    async ({
               chargeboxPartyIds,
               remoteActionType,
               scheduledTime,
               auth0Token,
               getAll,
               deselectedRows,
               currentFilters,
               ...otherData
           }) => {
        const params = stringify({getAll, deselected: deselectedRows, ...currentFilters});
        return deviceService.updateDevicesState(params,
            {...buildDeviceStatePayload(chargeboxPartyIds, remoteActionType, scheduledTime), ...otherData},
            auth0Token
        );
    }
)

export const getDevicesTagsDataAction = createAsyncThunk(
    'devices/tags',
    ({auth0Token}) => {
        return deviceService.getTags(auth0Token);
    }
);

export const getDevicesConnectorTypesDataAction = createAsyncThunk(
    'devices/connector-types',
    ({auth0Token}) => {
        return deviceService.getConnectorTypes(auth0Token);
    }
);

const devicesSlice = createSlice({
    name: 'device',
    initialState: {
        data: [],
        actionLoading: null,
        pagination: {
            pageSize: defaultTablePageSize.devices,
            total: 0,
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true
        },
        filters: {},
        sorter: {},
        device: {},
        deviceCertificates: []
    },
    reducers: {},
    extraReducers: {
        [getDevicesDataAction.fulfilled]: (state, action) => {
            state.data = action.payload.content;
            state.pagination.total = action.payload.totalElements;
            state.loading = false;
        },
        [getDevicesDataAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getDevicesDataAction.rejected]: (state, action) => {
            state.loading = false;
            state.data = [];
        },
        [exportDevicesDataAction.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [exportDevicesDataAction.pending]: (state, action) => {
            state.loading = true;
        },
        [exportDevicesDataAction.rejected]: (state, action) => {
            state.loading = false;
        },
        [getDeviceDataByIdAction.fulfilled]: (state, action) => {
            state.device = action.payload;
            state.loading = false;
        },
        [getDeviceDataByIdAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getDeviceDataByIdAction.rejected]: (state, action) => {
            state.device = {};
            state.loading = false;
        },
        [getCertificatesForDevice.fulfilled]: (state, action) => {
            state.deviceCertificates = action.payload;
            state.loading = false;
        },
        [getCertificatesForDevice.pending]: (state, action) => {
            state.loading = true;
        },
        [getCertificatesForDevice.rejected]: (state, action) => {
            state.deviceCertificates = [];
            state.loading = false;
        },
        [executeDeviceAction.fulfilled]: (state, action) => {
            state.actionLoading = false;
            message.info('Action is successfully executed!');
        },
        [executeDeviceAction.pending]: (state, action) => {
            state.actionLoading = true;
        },
        [executeDeviceAction.rejected]: (state, action) => {
            state.actionLoading = false;
            message.error('Action failed!');
        },
        [updateDevicesStateAction.fulfilled]: (state, action) => {
            state.actionLoading = false;
            message.info('State of devices are successfully updated!');
        },
        [updateDevicesStateAction.pending]: (state, action) => {
            state.actionLoading = true;
        },
        [updateDevicesStateAction.rejected]: (state, action) => {
            state.actionLoading = false;
            message.error('State update of devices is failed!');
        },
        [getDevicesTagsDataAction.fulfilled]: (state, action) => {
            state.tags = action.payload;
            state.tagsLoading = false;
        },
        [getDevicesTagsDataAction.pending]: (state) => {
            state.tagsLoading = true;
        },
        [getDevicesTagsDataAction.rejected]: (state) => {
            state.tagsLoading = true;
            message.error('Get tags failed!');
        },
        [getDevicesConnectorTypesDataAction.fulfilled]: (state, action) => {
            state.connectorTypes = action.payload;
            state.connectorTypesLoading = false;
        },
        [getDevicesConnectorTypesDataAction.pending]: (state) => {
            state.connectorTypesLoading = true;
        },
        [getDevicesConnectorTypesDataAction.rejected]: (state) => {
            state.connectorTypesLoading = true;
            message.error('Get connector types failed!');
        }
    },
});

export default devicesSlice.reducer;
