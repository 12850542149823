import { roleDetailTypeValues } from '_constants';
import { formatFullYearMonths } from '_helpers';

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    width: '15%',
    ellipsis: true,
  },
  {
    title: 'Log Type',
    dataIndex: 'detailType',
    filters: roleDetailTypeValues.map(item => ({
      text: item,
      value: item,
    })),
    width: '25%',
    ellipsis: true,
    filterMultiple: true,
    filterMode: 'menu',
    filterSearch: true,
  },
  {
    title: 'Chargebox ID',
    dataIndex: 'resources',
    filterSearch: true,
    ellipsis: true,
    width: '10%',
  },
  {
    title: 'Station ID',
    dataIndex: 'stationId',
    ellipsis: true,
    width: '10%',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    filterSearch: true,
    render: formatFullYearMonths,
    width: '20%',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Source',
    dataIndex: 'source',
    filterSearch: true,
    width: '20%',
    ellipsis: true,
  }
];

export { columns };