import { allCols } from "_constants/devicesTableColConstants";

// This file stores all default columns following indices in devicesTableColConstants
export const cols = [
    // 0-stationId
    allCols[0],
    // 1-chargeboxId
    allCols[1],
    // 2-operationalStatus
    allCols[2],
    // 4-make
    allCols[4],
    // 6-networkOperator
    allCols[6],
    // 8-location
    allCols[8],
    // 9-city
    allCols[9],
    // 10-state
    allCols[10],
    // 13-webSocketConnection
    allCols[13],
    // 14-heartbeatTimestamp
    allCols[14],
    // 15-communicationTimestamp
    allCols[15]
];
