import { Button, Form, Tooltip } from "antd";
import { HistoryOutlined, PlusCircleOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import './styles.less';

/**
 * Component that appears at bottom of state/configuration global key/device setting forms
 * add, save, and history button
 */
const KeyFormActionBar = ({disabled, disableAdd = false, onNewClick, onHistoryClick, onHelpClick}) => {
    return (
        <Form.Item className="formItem btnWrapper">
            {disableAdd ? null : <Tooltip placement="topLeft" title={"Add New Setting"}>
                <Button
                    disabled={disableAdd}
                    className="formBtn"
                    icon={<PlusCircleOutlined style={{fontSize: "25px"}}/>}
                    onClick={() => disableAdd ? null : onNewClick()}
                />
            </Tooltip>}

            <Tooltip placement="topLeft" title={"Save Updates"}>
                <Button
                    className="formBtn"
                    htmlType="submit"
                    type="primary"
                    disabled={disabled}
                    icon={<SaveOutlined style={{fontSize: "25px"}}/>}
                />
            </Tooltip>

            <Tooltip placement="topLeft" title={"History"}>
                <Button
                    className="formBtn"
                    icon={<HistoryOutlined style={{fontSize: "25px"}}/>}
                    onClick={() => onHistoryClick()}
                />
            </Tooltip>

            <Tooltip placement="topLeft" title={"Help"}>
                <Button
                    className="helpBtn"
                    icon={<QuestionCircleOutlined style={{fontSize: "25px"}}/>}
                    onClick={() => onHelpClick()}
                />
            </Tooltip>
        </Form.Item>
    )
}

export default KeyFormActionBar;