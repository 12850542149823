import { createParams } from "_helpers";
import { get, post, put, deleteList } from "_helpers/api";
import { stringify } from "querystring";

function getEvses({ page, pageSize, filters, sorter }, auth0Token, chargingGroupId, inChargingGroup = true) {
  const params = createParams(page, pageSize, filters, sorter, null);
  return get({
    path: `charging-groups/${chargingGroupId}/evses`,
    parameters: {
      ...params,
      inChargingGroup,
    },
  }, auth0Token);
}

function getEvseMessages(chargeboxId, page, auth0Token) {
  return get({
    path: `evse/messages/${chargeboxId}`,
    parameters: {
      page,
    },
  }, auth0Token);
}

function addEvseMessage(chargeboxId, body, auth0Token) {
  return put(
    {
      path: `evse/messages/${chargeboxId}`,
      body,
      opts: { headers: {
        "Content-Type": "application/json"
      } },
    },
    auth0Token,
    false,
  );
}

function updateChargingGroupByEvse(chargeboxId, chargingGroup, auth0Token) {
  return put({
    path: `charging-groups/evse/${chargeboxId}?${stringify({ chargingGroup })}`
  }, auth0Token)
}

function addEvses(chargingGroupId, body, getAll, filters, deselected, auth0Token) {
  const params = stringify({getAll, deselected, ...filters});
  return post(
    {
      path: `charging-groups/${chargingGroupId}/evses?${params}`,
      body,
      opts: { headers: {
        "Content-Type": "application/json"
      } },
    },
    auth0Token
  );
}

function deleteEvses(chargingGroupId, body, getAll, filters, deselected, auth0Token) {
  const params = stringify({getAll, deselected, ...filters});
  return deleteList(
    {
      path: `charging-groups/${chargingGroupId}/evses?${params}`,
      body,
      opts: { headers: { 
        "Content-Type": "application/json"
      } },
    },
    auth0Token
  );
}

export const evsesService = {
  getEvseMessages,
  addEvseMessage,
  getEvses,
  addEvses,
  deleteEvses,
  updateChargingGroupByEvse
};
