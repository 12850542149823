import { get } from '_helpers/api';

function getDiagnostics(deviceId, { date }, auth0Token = null) {
  const params = {
    date: date,
  };
  return get({ path: `devices/${deviceId}/diagnostics`, parameters: params }, auth0Token);
}

export const deviceDiagnosticsService = {
  getDiagnostics
};
