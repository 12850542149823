import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewEvsesAction,
  deleteEvsesAction,
  getEvsesAction,
  getNewEvsesAction,
} from '_store/evses';
import { useApi } from "./useApi.hook";
import { defaultTablePageSize } from "../_constants";

export const useEvses = (id) => {
  const {
    evses,
    newEvses,
    evsesLoading,
    newEvsesLoading,
    evsesUpdating,
    pagination,
    filters,
    sorter,
    newPagination,
    newFilters,
    newSorter,
  } = useSelector(state => state.evsesReducer);
  const { auth0Token, loading: tokenLoading } = useApi();
  const [isAddingEvse, setIsAddingEvse] = useState(false);
  const [selectedExistingRowKeys, setSelectedExistingRowKeys] = useState([]);
  const [selectedNewRowKeys, setSelectedNewRowKeys] = useState([]);
  const [addFilters, setAddFilters] = useState(null);
  const [deleteFilters, setDeleteFilters] = useState(null);
  const [deselectedRows, setDeselectedRows] = useState([]);
  const [getAllInPage, setGetAllInPage] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && auth0Token && dispatch) {
      dispatch(getEvsesAction({
        page: 1,
        pageSize: defaultTablePageSize.devices,
        filters,
        sorter,
        auth0Token,
        chargingGroupId: id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token]);

  const getEvsesForChargingGroup = () => {
    dispatch(getEvsesAction({
      page: 1,
      pageSize: defaultTablePageSize.devices,
      filters,
      sorter,
      auth0Token,
      chargingGroupId: id,
    }));
  }

  const onEvsesTableChange = (pagination, filters, sorter, extra) => {
    setDeleteFilters(filters);
    dispatch(getEvsesAction({
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
      sorter,
      auth0Token,
      chargingGroupId: id,
    }));
  };

  const onNewEvsesTableChange = (pagination, filters, sorter, extra) => {
    setAddFilters(filters);
    dispatch(getNewEvsesAction({
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
      sorter,
      auth0Token,
      chargingGroupId: id,
    }));
  };

  useEffect(() => {
    if (isAddingEvse === true) {
      dispatch(getNewEvsesAction({
        page: 1,
        pageSize: defaultTablePageSize.devices,
        filters: newFilters,
        sorter: newSorter,
        auth0Token,
        chargingGroupId: id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingEvse]);

  useEffect(() => {
    if (dispatch && evsesUpdating === false && auth0Token) {
      dispatch(getEvsesAction({
        page: 1,
        pageSize: defaultTablePageSize.devices,
        filters,
        sorter,
        auth0Token,
        chargingGroupId: id,
      }));
      setSelectedExistingRowKeys([]);
      setSelectedNewRowKeys([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evsesUpdating, dispatch, getEvsesAction]);

  const onClickAddEvses = () => {
    setIsAddingEvse(!isAddingEvse);
    setSelectedExistingRowKeys([]);
    setGetAllInPage(false);
    setDeselectedRows([]);
  };

  const selectExistingRow = (selectedRowKeys) => {
    setSelectedExistingRowKeys(selectedRowKeys);
  };

  const selectNewRow = (selectedRowKeys) => {
    setSelectedNewRowKeys(selectedRowKeys);
  };

  const onConfirmAddEvse = () => {
    dispatch(addNewEvsesAction({ chargingGroupId: id, body: selectedNewRowKeys, getAll:false, filters: addFilters,
        deselected: deselectedRows, auth0Token }));
    setIsAddingEvse(false);
    setAddFilters(null);
    setGetAllInPage(false);
    setDeselectedRows([]);
  };

  const onConfirmDeleteEvse = () => {
    dispatch(deleteEvsesAction({ chargingGroupId: id, body: selectedExistingRowKeys, getAll:false,
      filters: deleteFilters, deselected: deselectedRows, auth0Token }));
    setIsAddingEvse(false);
    setDeleteFilters(null);
    setGetAllInPage(false);
    setDeselectedRows([]);
  };

  const onSelectAll = (selected) => {
    setGetAllInPage(selected);
    setDeselectedRows([]);
  }

  const onDeselect = (record, selected) => {
    if (getAllInPage) {
      if (!selected && !deselectedRows.includes(record.chargeboxId)) {
        setDeselectedRows([...deselectedRows, record.chargeboxId]);
      } else {
        setDeselectedRows(deselectedRows.filter(row => row !== record.chargeboxId));
      }
    }
  }

  return {
    evses,
    newEvses,
    evsesLoading,
    newEvsesLoading,
    isAddingEvse,
    onClickAddEvses,
    selectedExistingRowKeys,
    selectedNewRowKeys,
    selectExistingRow,
    onEvsesTableChange,
    onNewEvsesTableChange,
    selectNewRow,
    onConfirmAddEvse,
    onConfirmDeleteEvse,
    pagination,
    filters,
    sorter,
    newPagination,
    newFilters,
    newSorter,
    evsesUpdating,
    onSelectAll,
    onDeselect,
    getEvsesForChargingGroup
  }
}
