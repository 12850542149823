import { Spin } from 'antd';
import './styles.less';

const PageLoader = (props) => {
  return (
    <div className="page-loader">
      <Spin tip={props.title}></Spin>
    </div>
  );
}


export default PageLoader;
