import {SearchOutlined} from "@ant-design/icons";
import SearchFilterForm from "../../_components/SearchFilterForm";
import {tableFilterType} from "../../_constants";

export function addColumnFilter(columns, tags, tagsLoading, connectorTypes, connectorTypesLoading) {
    let Icon = SearchOutlined;
    // if tagsLoading is undefined then the data is still loading
    const loading = (typeof tagsLoading == "undefined") || tagsLoading || (typeof connectorTypesLoading == "undefined") || connectorTypesLoading
    // these columns in devices table should be able to filter by input text
    const searchColumns = ["stationId", "chargeboxId", "make", "model", "networkOperator", "enterprise", "location", "city", "state", "generation", "plugInSuccessRate", "heartbeatRate", "powerLevels"];
    let tagValues = []
    let connectorTypeValues = []
    if (!loading) {
        tagValues = tags["tags"]
        connectorTypeValues = connectorTypes["connectorTypes"]
    }

    const getColumnSearchProps = (column) => {
        if (searchColumns.includes(column.dataIndex))
            return {
                filterDropdown: (props) => (
                    <SearchFilterForm
                        {...props}
                        autoFocus={true}
                        dataIndex={column.dataIndex}
                        fieldType={tableFilterType.SEARCH}
                    />
                ),
                filterIcon: filtered => <Icon style={{color: filtered ? '#1890ff' : undefined}}/>,
                onFilterDropdownVisibleChange: visible => {
                },
            }
        else
            return {}
    };

    const getFilterProps = (column, values, dataIndex) => {
        if (!loading && values.length > 0 && column.dataIndex === dataIndex) {
            const filterValues = values.map(item => ({
                text: item,
                value: item
            }))
            return {
                ...column, filters: filterValues,
            }
        } else {
            return {}
        }
    };

    return columns.map((column) => {
        return {...column, ...getColumnSearchProps(column), ...getFilterProps(column, tagValues, "tags"), ...getFilterProps(column, connectorTypeValues, "connectorTypes")}
    })
}