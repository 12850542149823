import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "./useApi.hook";
import { getDeviceStatesAction, getDeviceStatesHistoryAction } from "../_store/deviceStates";
import { deviceStatesService } from "../_services";
import { useParams } from "react-router-dom";
import { useOneDevice } from "./devices.hook";
import { openNotification, errorNotification } from "../_components/Notification";

export const useDeviceStates = () => {
  const {
    deviceStates,
    deviceStatesLoading,
    deviceStatesHistory,
    deviceStatesHistoryLoading,
  } = useSelector((state) => state.deviceStatesReducer);

  const { auth0Token, loading: tokenLoading } = useApi();
  const { deviceId } = useParams();
  const { device } = useOneDevice(deviceId);
  const [deviceStatesUpdating, setDeviceStatesUpdating] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && auth0Token && device && device.chargeboxId && dispatch) {
      dispatch(getDeviceStatesAction({ auth0Token, chargeboxId: device.chargeboxId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token, device]);

  const getDeviceStates = () => {
    dispatch(getDeviceStatesAction({ auth0Token, chargeboxId: device?.chargeboxId }));
  };

  const getDeviceStatesHistoryHandler = () => {
    if (!tokenLoading && auth0Token && device && device.chargeboxId && dispatch) {
      dispatch(getDeviceStatesHistoryAction({ auth0Token, chargeboxId: device?.chargeboxId }));
    }
  }

  const updateDeviceStatesHandler = (changedValues, message = null, description = null, header = null) => {
    const finalMessage = message ? message : "Device state saved";
    const finalDescription = description ? description : "Device state has been updated.";
    const finalHeader = header ? header : "Could not save state";

    setDeviceStatesUpdating(true);
    deviceStatesService
      .updateDeviceStates(changedValues, auth0Token)
      .then(() => {
        getDeviceStates(device);
        openNotification(finalMessage, finalDescription);
        setDeviceStatesUpdating(false);
      })
      .catch((err) => {
        errorNotification(finalHeader, err);
        setDeviceStatesUpdating(false);
      });
  };

  return {
    deviceStates,
    deviceStatesLoading,
    deviceStatesHistory,
    deviceStatesHistoryLoading,
    deviceStatesUpdating,
    updateDeviceStatesHandler,
    getDeviceStatesHistoryHandler,
    getDeviceStates,
  };
};
