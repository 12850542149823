import {Table} from "antd";

/**
 * Table to represent multiple EVSEs belonging to one device.
 * Intended to be used in Charging Groups Devices Table's expandable row structure.
 */
const EvsesTable = ({evses}) => {
    const columns = [
        {
            title: 'Station Id',
            dataIndex: 'stationId'
        }
    ]

    return (
        <>
            <Table
                className="charging-group-evses-table"
                key='evses'
                dataSource={evses}
                columns={columns}
                rowKey={record => record.stationId}
                pagination={{
                    defaultPageSize: 500,
                    hideOnSinglePage: true
                }}
            />
        </>
    )
};

export default EvsesTable