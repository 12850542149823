import {Modal} from "antd";

const HelperModal = ({
                         modalContent,
                         showInfoModal,
                         setShowInfoModal
                     }) => {
    const hideModal = () => {
        setShowInfoModal(false);
    }

    return (
        <>
            <Modal className={'info-modal'} visible={showInfoModal} footer={null} onCancel={hideModal}>
                {modalContent}
            </Modal>
        </>
    )
}

export default HelperModal;