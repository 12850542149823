import { Button, Space, Switch, Spin } from 'antd';
import { useDeviceLogsSocket } from '_hooks/useDeviceLogsSocket.hook';
import { SocketState } from '_constants';
import { formatFullYearMonths } from "../../_helpers";
import { useState } from "react";

const DeviceLiveLogsView = ({ device }) => {
  const {
    messages,
    readyState,
    wsLoading,
    toggleSocket,
    clearMessages,
  } = useDeviceLogsSocket(device);

  const [lastToggled, setLastToggled] = useState("N/A");

  const MessageItem = ({message}) => {
    // Regex that:
    // 1) removes escaped quotation marks around URLs and
    // 2) embeds them within an <a> tag
    // (courtesy of ChatGPT)
    let messageHtml = message.message.replace(/"(https?:\/\/[^\s"]+)"/g, '<a target="_blank" href="$1">$1</a>');
    return (
        <div className={message.origin === 'EVSE' ? 'message-item charger' : 'message-item server'}>
          {formatFullYearMonths(message.time)} (<b>{message.evseUid}</b>): <div dangerouslySetInnerHTML={{ __html: messageHtml }}></div>
        </div>
    )
  }

  const toggleSocketHelper = (checked) => {
    if (checked) {
      const today = new Date();
      setLastToggled(formatFullYearMonths(today.toUTCString()));
    }
    toggleSocket(checked);
  }

  return (<>
      <div className="deviceLiveLogs">
        <p>
          <span className='server-example'>Server response are in blue; </span>
          <span className='charger-example'>EVSE responses are in red. </span>
          <span>Live logs will close if you leave this page or automatically after one hour.
            You can switch tabs without ending the live log feed. </span>
          <span>Live logs last activated (only your sessions): <b>{lastToggled}</b></span>
        </p>

        <div className='toggleButton'>
          <Space align='center'>
            <div>Live Mode:</div>
            <Switch
              checkedChildren="Online"
              unCheckedChildren="Offline"
              checked={readyState === SocketState.OPEN}
              disabled={wsLoading}
              onChange={toggleSocketHelper}
            />
            {wsLoading ? <Spin size={"small"}/> : null}
          </Space>
          <Button
            type='primary'
            size='small'
            onClick={clearMessages}
          >Clear Logs</Button>
        </div>
        <div className="deviceLiveLogsContainer">
          {messages.map((message, index) => (
            <MessageItem key={index} message={message} />
          ))}
        </div>
      </div>
    </>);
}

export default DeviceLiveLogsView;