import { get } from '_helpers/api';
import { formatDateForApi } from '_helpers/date';
import { sortOptions } from '_constants';

function getLogs({ page, pageSize, filters, sorter }, auth0Token = null) {
  const params = {
    pageNumber: page - 1,
    pageSize,
    ...getParams({ filters, sorter }),
  };
  return get({ path: 'logs/ocpp', parameters: params }, auth0Token);
}

function exportLogs({ filters, sorter }, auth0Token = null) {
  const params = getParams({ filters, sorter });
  return get({ path: 'logs/ocpp/exporter/export-csv', parameters: params }, auth0Token);
}

function exportRawLogs({ deviceId, startDate, endDate }, auth0Token = null) {
  return get({ path: `logs/ocpp/exporter/export-raw-ocpp-logs/${deviceId}`, parameters: {
      startDate : startDate.toISOString(),
      endDate : endDate.toISOString()
    }
  }, auth0Token);
}

const getParams = ({ filters, sorter }) => {
  const params = {};
  if (sorter.field) {
    params['sortBy'] = sorter.field;
    params['sortOrder'] = sortOptions[sorter.order];
  }
  for (var key in filters) {
    const value = filters[key];
    if (!value) {
      continue;
    }
    if (key === 'time') {
      if (filters[key][0]) {
        params['start'] = formatDateForApi(filters[key][0]);
      }
      if (filters[key][1]) {
        params['end'] = formatDateForApi(filters[key][1]);
      }
    } else {
      params[key] = filters[key].join(',');
    }
  }
  return params;
};

export const logsService = {
  getLogs,
  exportLogs,
  exportRawLogs
};
