import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  Select,
} from 'antd';
import './styles.less';
import { triggerMessageRequestTypes } from '_constants';

const { Option } = Select;

const TriggerMessageRequestModal = ({
  open,
  data,
  title,
  handleClose,
  handleConfirm,
}) => {
  const [disabled, setDisabled] = useState(true);
  const initialValues = {};

  const [updatePayload, setUpdatePayload] = useState({});

  const onClickOk = (payload) => () => {
    handleConfirm(payload);
  }

  const handleKeySelect = (newChange) => {
    let payloadCopy = { ...updatePayload };
    payloadCopy['requestedMessage'] = newChange;

    setUpdatePayload(payloadCopy);
    setDisabled(false);
  };

  return (
    <Modal
      className='remote-action-confirm-modal'
      title={title}
      visible={open}
      onCancel={handleClose}
      bodyStyle={{
        height: 360,
      }}
      width={440}
      footer={
        <Row>
          <Col span={12}>
            <Button
              block
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              block
              disabled={disabled}
              onClick={onClickOk(updatePayload)}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form
            initialValues={initialValues}
            layout="vertical"
          >
            <Form.Item
              name={'requestedMessage'}
              label='Requested Message'
            >
              {/* <div className="formItemContent"> */}
                <Select onChange={handleKeySelect}>
                  {triggerMessageRequestTypes.map((item) => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              {/* </div> */}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default TriggerMessageRequestModal;