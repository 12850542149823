import { get } from "_helpers/api";

function getStatusOverview(auth0Token) {
  return get({ path: "devices/status" }, auth0Token);
}

function exportStatusOverview(auth0Token) {
  return get({ path: 'devices/status/export' }, auth0Token);
}

export const statusOverviewService = {
  getStatusOverview,
  exportStatusOverview
};
