import { Form, Switch } from "antd";
import { convertToLabel } from "_helpers";
import './styles.less';

const DeviceStateKeyFormItem = ({ itemKey, value, handleValuesChange }) => {
  return (
      <Form.Item
        className="formItem"
        key={itemKey}
        name={itemKey}
        label={convertToLabel(itemKey)}
      >
        <div className="formItemContent">
          {itemKey === 'chargeboxId' ? (
            value
          ) : (
            <Switch
            defaultChecked={value}
            onChange={(change) => handleValuesChange(change, itemKey)}
          />
          )}
        </div>
      </Form.Item>
  )
}

export default DeviceStateKeyFormItem;