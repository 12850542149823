export const timeGrainOptions = [
    {
      name: 'HOURLY',
      label: 'Hourly',
    },
    {
      name: 'DAILY',
      label: 'Daily',
    },
    {
      name: 'WEEKLY',
      label: 'Weekly',
    },
    {
      name: 'MONTHLY',
      label: 'Monthly',
    }
  ];