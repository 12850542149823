import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { evsesService } from "_services";
import { defaultTablePageSize } from "../../_constants";

export const getEvseMessagesAction = createAsyncThunk(
  "evse/get",
  async ({ chargeboxId, page, auth0Token }) => {
    return await evsesService.getEvseMessages(chargeboxId, page, auth0Token);
  }
);

export const addEvseMessageAction = createAsyncThunk(
  "evse/update",
  async ({ chargeboxId, auth0Token, body }) => {
    return await evsesService.addEvseMessage(chargeboxId, body, auth0Token);
  }
);

export const getEvsesAction = createAsyncThunk(
  "evses/get",
  async ({ page, pageSize, filters, sorter, auth0Token, chargingGroupId }) => {
    return await evsesService.getEvses({ page, pageSize, filters, sorter }, auth0Token, chargingGroupId);
  }
);

export const getNewEvsesAction = createAsyncThunk(
  "newEvses/get",
  async ({ page, pageSize, filters, sorter, auth0Token, chargingGroupId }) => await evsesService.getEvses({ page, pageSize, filters, sorter }, auth0Token, chargingGroupId, false)
);

export const addNewEvsesAction = createAsyncThunk(
  "newEvses/post",
  async ({ chargingGroupId, body, getAll, filters, deselected, auth0Token }) =>
    await evsesService.addEvses(chargingGroupId, body, getAll, filters, deselected, auth0Token)
);

export const deleteEvsesAction = createAsyncThunk(
  "evses/delete",
  async ({ chargingGroupId, body, getAll, filters, deselected, auth0Token }) =>
    await evsesService.deleteEvses(chargingGroupId, body, getAll, filters, deselected, auth0Token)
);

const evsesSlice = createSlice({
  name: "evses",
  initialState: {
    evse: [],
    evses: [],
    newEvses: [],
    evseLoading: false,
    evsesLoading: false,
    newEvsesLoading: false,
    evsesUpdating: false,
    evseUpdating: false,
    evsePagination: {
      pageSize: 3,
      total: 0
    },
    pagination: {
      pageSize: defaultTablePageSize.devices,
      total: 0,
      pageSizeOptions: [10, 25, 50],
      showSizeChanger: true
    },
    filters: {},
    sorter: {},
    newPagination: {
      pageSize: defaultTablePageSize.devices,
      total: 0,
      pageSizeOptions: [10, 25, 50],
      showSizeChanger: true
    },
    newFilters: {},
    newSorter: {},
  },
  reducers: {},
  extraReducers: {
    [getEvseMessagesAction.fulfilled]: (state, action) => {
      state.evse = action.payload.content;
      state.evsePagination.total = action.payload.totalElements;
      state.evsePagination.current = action.payload.number + 1;
      state.evsePagination.pageSize = action.payload.size;
      state.evseLoading = false;
    },
    [getEvseMessagesAction.pending]: (state, action) => {
      state.evseLoading = true;
    },
    [getEvseMessagesAction.rejected]: (state, action) => {
      state.evseLoading = false;
    },
    [addEvseMessageAction.fulfilled]: (state, action) => {
      state.evse = action.payload;
      state.evseUpdating = false;
    },
    [addEvseMessageAction.pending]: (state, action) => {
      state.evseUpdating = true;
    },
    [addEvseMessageAction.rejected]: (state, action) => {
      state.evseUpdating = false;
    },
    [getEvsesAction.fulfilled]: (state, action) => {
      state.evses = action.payload.content;
      state.pagination.current = action.payload.number + 1;
      state.pagination.total = action.payload.totalElements;
      state.pagination.pageSize = action.payload.size;
      state.filters = action.meta.arg.filters;
      state.sorter = action.meta.arg.sorter;
      state.evsesLoading = false;
    },
    [getEvsesAction.pending]: (state, action) => {
      state.evsesLoading = true;
    },
    [getEvsesAction.rejected]: (state, action) => {
      state.evsesLoading = false;
    },
    [getNewEvsesAction.fulfilled]: (state, action) => {
      state.newEvses = action.payload.content;
      state.newPagination.current = action.payload.number + 1;
      state.newPagination.total = action.payload.totalElements;
      state.newPagination.pageSize = action.payload.size;
      state.newFilters = action.meta.arg.filters;
      state.newSorter = action.meta.arg.sorter;
      state.newEvsesLoading = false;
    },
    [getNewEvsesAction.pending]: (state, action) => {
      state.newEvses = [];
      state.newEvsesLoading = true;
    },
    [getNewEvsesAction.rejected]: (state, action) => {
      state.newEvsesLoading = false;
    },
    [addNewEvsesAction.fulfilled]: (state, action) => {
      state.evsesUpdating = false;
    },
    [addNewEvsesAction.pending]: (state, action) => {
      state.evsesUpdating = true;
    },
    [addNewEvsesAction.rejected]: (state, action) => {
      state.evsesUpdating = false;
    },
    [deleteEvsesAction.fulfilled]: (state, action) => {
      state.evsesUpdating = false;
    },
    [deleteEvsesAction.pending]: (state, action) => {
      state.evsesUpdating = true;
    },
    [deleteEvsesAction.rejected]: (state, action) => {
      state.evsesUpdating = false;
    }
  },
});

export default evsesSlice.reducer;
