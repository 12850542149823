import { Layout, Menu } from 'antd';
import { navigationOptions, externalMenuOptions } from '_constants';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './sidebar.less';
import logo from '../../logo_ea.png';
import { useEffect, useState } from 'react';

const { Sider } = Layout;


const SideMenu = ({ collapsed, onCollapse, onClickLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = `/${location.pathname.split('/')[1]}`;
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
        ? "/dashboard"
        : currentPath,
  );

  useEffect(() => {
    if (currentPath) {
      if(currentPath !== current ) {
        setCurrent(currentPath);
      }
    }
  }, [currentPath, current]);
  const onClickMenuItem = (path) => (e) => {
    navigate(path);
    setCurrent(e.key);
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      width={270}
    >
      <div className="logo">
        <img src={logo} alt="Electrify America logo"/>
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[current]}>
        {navigationOptions.map(option => (
          <Menu.Item icon={option.icon} key={option.path} onClick={onClickMenuItem(option.path)}>
            {option.label}
          </Menu.Item>
        ))}
        {externalMenuOptions.map(item => (
          <Menu.Item icon={item.icon} key={item.url}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.label}
            </a>
          </Menu.Item>
        ))}
        <Menu.Item key='logout' icon={<LogoutOutlined />} onClick={onClickLogout}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
}


export default SideMenu;
