import { get } from '_helpers/api';
import { formatDateForApi } from '_helpers/date';
import { sortOptions } from '_constants';

function getDeviceLogs({ chargeboxId, page, pageSize, filters, sorter }, auth0Token = null) {
  const params = { pageNumber: page - 1, pageSize };
  if (sorter.field) {
    params['sortBy'] = sorter.field;
    params['sortOrder'] = sortOptions[sorter.order];
  }
  for (var key in filters) {
    const value = filters[key];
    if (!value) {
      continue;
    }
    if (key === 'time') {
      if (filters[key][0]) {
        params['start'] = formatDateForApi(filters[key][0]);
      }
      if (filters[key][1]) {
        params['end'] = formatDateForApi(filters[key][1]);
      }
    } else {
      params[key] = filters[key][0];
    }
  }

  // specifically pulling all STATUS_NOTIFICATION_REQUESTS for a specific chargebox
  params['detailType'] = "STATUS_NOTIFICATION_REQUEST";
  params['resources'] = chargeboxId;
  return get({ path: 'logs/ocpp', parameters: params }, auth0Token);
}

export const deviceLogsService = {
  getDeviceLogs,
};
