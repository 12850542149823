export const roleDetailTypeValues = [
  'AUTHORIZE_CONFIRMATION',
  'AUTHORIZE_REQUEST',
  'BOOT_NOTIFICATION_CONFIRMATION',
  'BOOT_NOTIFICATION_REQUEST',
  'CHANGE_CONFIGURATION_CONFIRMATION',
  'CHANGE_CONFIGURATION_REQUEST',
  'CHARGER_REGISTERED',
  'CLEAR_CHARGING_PROFILE_CONFIRMATION',
  'CLEAR_CHARGING_PROFILE_REQUEST',
  'DATA_TRANSFER_CONFIRMATION',
  'DATA_TRANSFER_REQUEST',
  'DIAGNOSTICS_STATUS_NOTIFICATION_CONFIRMATION',
  'DIAGNOSTICS_STATUS_NOTIFICATION_REQUEST',
  'FIRMWARE_STATUS_NOTIFICATION_CONFIRMATION',
  'FIRMWARE_STATUS_NOTIFICATION_REQUEST',
  'GET_COMPOSITE_SCHEDULE_CONFIRMATION',
  'GET_COMPOSITE_SCHEDULE_REQUEST',
  'GET_CONFIGURATION_CONFIRMATION',
  'GET_CONFIGURATION_REQUEST',
  'GET_DIAGNOSTICS_CONFIRMATION',
  'GET_DIAGNOSTICS_REQUEST',
  'HARD_RESET_REQUEST',
  'HEARTBEAT_CONFIRMATION',
  'HEARTBEAT_REQUEST',
  'LOG_OCPP_RAW_OCPP',
  'METER_VALUES_CONFIRMATION',
  'METER_VALUES_REQUEST',
  'REMOTE_START_TRANSACTION_CONFIRMATION',
  'REMOTE_START_TRANSACTION_REQUEST',
  'REMOTE_STOP_TRANSACTION_CONFIRMATION',
  'REMOTE_STOP_TRANSACTION_REQUEST',
  'RESET_CONFIRMATION',
  'SESSION_CREATED',
  'SESSION_REMOVED',
  'SET_CHARGING_PROFILE_CONFIRMATION',
  'SET_CHARGING_PROFILE_REQUEST',
  'SOFT_RESET_REQUEST',
  'START_TRANSACTION_CONFIRMATION',
  'START_TRANSACTION_REQUEST',
  'STATUS_NOTIFICATION_CONFIRMATION',
  'STATUS_NOTIFICATION_REQUEST',
  'STOP_TRANSACTION_CONFIRMATION',
  'STOP_TRANSACTION_REQUEST',
  'UNKNOWN',
  'UPDATE_FIRMWARE_CONFIRMATION',
  'UPDATE_FIRMWARE_REQUEST'
];

export const tableFilterType = {
  DATE_RANGE: 'DATE_RANGE',
  SEARCH: 'SEARCH',
}

export const sortOptions = {
  ascend: 'ASC',
  descend: 'DESC',
}