import { Form, Input, InputNumber, Switch } from "antd";
import { valueTypeFormConstants } from "../_constants/valueTypeFormConstants";
import KeyFormItem from "../_components/KeyFormItem";
import { formatFullYearMonths } from "./date";

const { TextArea } = Input;

/**
 * Helpers for any device setting (states, configs, global keys, etc) related forms
 */

export const defaultFormPageSize = 10;

export const getSelectableKeys = (keys, keyProperty, initialValues) => {
  return keys.filter((key) => {
    for (let initialVal of initialValues) {
      if (initialVal[keyProperty] === key[keyProperty]) return false;
    }
    return true;
  });
}

export const handleValuesChangeHelper = (newChange, initialValues, changedValues) => {
  let changedKey = Object.keys(newChange)[0];
  let initialVal = initialValues.find((val) => val.key === changedKey);

  let changeObj = {
    id: initialVal.id,
    key: changedKey,
    value: newChange[changedKey],
  };

  const existingChangeIndex = changedValues.findIndex((val) => val.key === changedKey);

  if (initialVal.value !== changeObj.value)
    if (existingChangeIndex >= 0) changedValues[existingChangeIndex] = changeObj;
    else changedValues.push(changeObj);
  else changedValues.splice(existingChangeIndex, 1);

  return changedValues;
};

export const hasChangesHelper = (initialValues, changedValues) => {
  for (let initVal of initialValues) {
    let changedValIndex = changedValues.findIndex((changedVal) => {
      return changedVal.id === initVal.id;
    });

    let payloadSetting = changedValues[changedValIndex];
    if (initVal.value !== payloadSetting.value) {
      return true;
    }
  }

  return false;
};

export const hasObjectChangesHelper = (initialValues, changedValues) => {
  for (let key in initialValues) {
    if (initialValues[key] !== changedValues[key]) {
      return true;
    }
  }

  return false;
};

export const formItemWithNoHandler = (newChange) => {
  // new form item is needed because reusing a form item and changing the child input type to match stateKey causes issues
  switch (newChange[Object.keys(newChange)[0]]) {
    case valueTypeFormConstants.toggle:
      return (
        <Form.Item key="toggleInput" name="toggleInput" label="Value"
                   rules={[{ required: true, message: `Input is required` }]}>
          <Switch/>
        </Form.Item>
      );
    case valueTypeFormConstants.number:
      return (
        <Form.Item key="numberInput" name="numberInput" label="Value"
                   rules={[{ required: true, message: `Input is required` }]}>
          <InputNumber/>
        </Form.Item>
      );
    case valueTypeFormConstants.text:
      return (
        <Form.Item key="textInput" name="textInput" label="Value"
                   rules={[{ required: true, message: `Input is required` }]}>
          <TextArea showCount maxLength={250}/>
        </Form.Item>
      );
    default:
      return (<div/>);
  }
}

export const keyFormItemWithHandler = (key, changesHandler) => {
  // using input on change rather than parent form on change because of Ant issues with dynamically generated fields
  switch (key.valueType) {
    case valueTypeFormConstants.toggle:
      return (
        <Switch
          defaultChecked={key.value}
          onChange={(change) => changesHandler({ [key.key]: change })}
        />
      );
    case valueTypeFormConstants.number:
      return (
        <InputNumber
          defaultValue={key.value}
          onChange={(change) => changesHandler({ [key.key]: change })}
        />
      );
    case valueTypeFormConstants.text:
      return (
        <TextArea
          defaultValue={key.value}
          showCount
          maxLength={250}
          onChange={(change) => changesHandler({ [key.key]: change.currentTarget.value })}
        />
      );
    default:
      return null;
  }
}

export const deviceFormItemWithHandler = (setting, changesHandler) => {
  switch (setting.valueType) {
    case valueTypeFormConstants.toggle:
      return (
        <Switch
          defaultChecked={setting.value}
          // using input on change rather than parent form on change because of Ant issues with dynamically generated fields
          onChange={(change) => changesHandler(change, setting.id)}
        />
      );
    case valueTypeFormConstants.number:
      return (
        <InputNumber defaultValue={setting.value} onChange={(change) => changesHandler(change, setting.id)} />
      );
    case valueTypeFormConstants.text:
      return (
        <TextArea
          defaultValue={setting.value}
          showCount
          maxLength={250}
          onChange={(e) => changesHandler(e.currentTarget.value, setting.id)}
        />
      );
    default:
      return null;
  }
}

export const createKeyDisplay = (keys, onDeleteClick, handleValuesChange) => {
  return keys.map((key) => {
    const inputComponent = deviceFormItemWithHandler(key, handleValuesChange);
    return (
      <KeyFormItem key={key.key} itemKey={key} keyProperty={"key"} disableDelete inputComponent={inputComponent} onDeleteClick={onDeleteClick}/>
    );
  });
}

export const formItemForRefresh = (newChange, selectedKey) => {
  switch (selectedKey.valueType) {
    case valueTypeFormConstants.toggle:
      return (
        <Form.Item key={selectedKey + "toggleInput"} name="toggleInput" label="Value">
          <Switch defaultChecked={selectedKey.value} />
        </Form.Item>
      );
    case valueTypeFormConstants.number:
      return (
        <Form.Item key={selectedKey + "numberInput"} name="numberInput" label="Value">
          <InputNumber defaultValue={selectedKey.value}/>
        </Form.Item>
      );
    case valueTypeFormConstants.text:
      return (
        <Form.Item key={selectedKey + "textInput"} name="textInput" label="Value">
          <TextArea defaultValue={selectedKey.value} showCount maxLength={250}/>
        </Form.Item>
      );
    default:
      return null;
  }
}

export const processAddKeyHandler = (values) => {
  switch (values.valueType) {
    case valueTypeFormConstants.toggle:
      // checking for undefined in case a value of false for boolean is set
      values.value =
        typeof values.toggleInput === "undefined" ? values.value : values.toggleInput;
      delete values.toggleInput;
      break;
    case valueTypeFormConstants.number:
      values.value = values.numberInput ? values.numberInput : values.value;
      delete values.numberInput;
      break;
    case valueTypeFormConstants.text:
      values.value = values.textInput ? values.textInput : values.value;
      delete values.textInput;
      break;
    default:
  }

  return values;
}

export const deviceKeyHistoryColumns = [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Previous Value",
    dataIndex: "previousValue",
    key: "previousValue",
    render: (prevVal) => {
      if (typeof prevVal === "boolean") return prevVal ? "Enabled" : "Disabled";
      return prevVal;
    },
  },
  {
    title: "Previous Value Type",
    dataIndex: "previousValueType",
    key: "previousValueType",
  },
  {
    title: "New Value",
    dataIndex: "currentValue",
    key: "currentValue",
    render: (newVal) => {
      if (typeof newVal === "boolean") return newVal ? "Enabled" : "Disabled";
      return newVal;
    },
  },
  {
    title: "New Value Type",
    dataIndex: "currentValueType",
    key: "currentValueType",
  },
];

export const getHistoryPage = (current, history) => {
  // history is not pre-paginated
  return history.slice((current - 1) * defaultFormPageSize, current * defaultFormPageSize)
    // doing this bc otherwise key warnings prevent pagination
    .map((history, index) => {
      const output = {...history}
      output.keyName = history.key;
      output.key = index;
      return output;
    });
};

const renderLogic = (value) => {
  if (typeof value === "boolean") return value ? "Enabled" : "Disabled";
  if (typeof value === "string" || typeof value === "number") return value === "" || !value ? "N/A" : value;
  return value;
};

export const deviceSettingsHistoryColumns = [
  {
    title: "Time",
    dataIndex: "timestamp",
    key: "timestamp",
    render: formatFullYearMonths,
  },
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName"
  },
  {
    title: "Available",
    dataIndex: "available",
    key: "available",
    render: renderLogic,
  },
  {
    title: "Freevend",
    dataIndex: "freevend",
    key: "freevend",
    render: renderLogic,
  },
  {
    title: "PncEnabled",
    dataIndex: "pncEnabled",
    key: "pncEnabled",
    render: renderLogic,
  },
  {
    title: "MaintenanceMode",
    dataIndex: "maintenanceMode",
    key: "maintenanceMode",
    render: renderLogic,
  },
  {
    title: "MaintenanceMode Message",
    dataIndex: "maintenanceModeEnabledMessage",
    key: "maintenanceModeEnabledMessage",
    ellipsis: false,
  },
  {
    title: "MaintenanceMode WorkOrderNumber",
    dataIndex: "maintenanceModeEnabledWorkOrderNumber",
    key: "maintenanceModeEnabledWorkOrderNumber",
    ellipsis: false,
  },
];
