import {Form, Input} from 'antd';

const NameFormItem = () => (
    <Form.Item
        name="alertName"
        label="Name"
        rules={[
            {
                required: true,
                message: "Alert name"
            },
        ]}
    >
        <Input placeholder={"Alert name"} autoComplete={"off"}/>
    </Form.Item>
);

export default NameFormItem;