import {Button, Form} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import EmailSelector from "./EmailSelector";
import {useEffect, useState} from "react";

const EmailFormItem = ({
                           selectedEmailRecipients,
                           setSelectedEmailRecipients,
                           alertData
                       }) => {
    const [keys, setKeys] = useState(new Set())

    const [isEmailRecipientModalVisible, setIsEmailRecipientModalVisible] = useState(false)

    const [intermediateSelectedEmailRecipients, setIntermediateSelectedEmailRecipients] = useState([]);

    // Update keys when `alertData` is updated
    useEffect(() => {
        const newKeys = new Set()
        if (alertData !== null) {
            alertData.forEach(a => {
                a.emailRecipients.forEach(newKeys.add, newKeys)
            })
        }
        setKeys(newKeys)
    }, [alertData])

    return (
        <Form.Item name="emailRecipients" label="Email recipients" required={true} rules={[
            {
                validator: async () => {
                    if (selectedEmailRecipients.length < 1) {
                        return Promise.reject(new Error('Must select at least 1 recipient'));
                    }
                },
            },
        ]}>
            {/* BUTTON TO MAKE EMAIL SELECTOR VISIBLE */}
            <Button
                onClick={() => {
                    // Reset `intermediateSelectedIds` before modal becomes visible
                    setIntermediateSelectedEmailRecipients(selectedEmailRecipients)
                    setIsEmailRecipientModalVisible(true)
                }}
                icon={selectedEmailRecipients.length > 0 ? null : <PlusOutlined/>}
                block>
                {
                    selectedEmailRecipients.length > 0 ? `Selected ${selectedEmailRecipients.length} recipients` : "Add recipients"
                }
            </Button>

            {/* EMAIL SELECTOR MODAL */}
            <EmailSelector
                keys={keys}
                setKeys={setKeys}
                isVisible={isEmailRecipientModalVisible}
                setIsVisible={setIsEmailRecipientModalVisible}
                intermediateSelectedIds={intermediateSelectedEmailRecipients}
                setIntermediateSelectedIds={setIntermediateSelectedEmailRecipients}
                selectedIds={selectedEmailRecipients}
                setSelectedIds={setSelectedEmailRecipients}>
            </EmailSelector>
        </Form.Item>
    );
}

export default EmailFormItem;