import React from "react";

import Routes from '_routes'
import 'App.less';
import {useAuth0} from "@auth0/auth0-react";
import {security} from "./_helpers/Security";

function App() {
    const {getIdTokenClaims} = useAuth0();

    // Need to set this so Token can be retrieved in non-react components
    security.setGetIdTokenClaims(getIdTokenClaims)

    return (
        <div>
            <Routes/>
        </div>
    );
}


export default App;
