import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  Switch,
  Checkbox,
} from 'antd';
import './styles.less';
import { convertToLabel } from '_helpers';
import MaintenanceModeBar from "../MaintenanceModeBar";
import * as permConsts from "../../_constants/permissionConstants";
import {usePermissions} from "../../_hooks/permissions.hook";

const deviceStates = ['freevend', 'available', 'pncEnabled', 'maintenanceMode'];
const initialStatesValue = deviceStates.reduce((result, key) => {
  result[key] = false;
  return result;
}, {});

const UpdateStateModal = ({
  open,
  data,
  title,
  handleClose,
  handleConfirm,
}) => {
  const [editingFields, setEditingFields] = useState({});
  const [disabled, setDisabled] = useState(true);
  const initialValues = {};

  const [updatePayload, setUpdatePayload] = useState(initialStatesValue);
  const permissions = usePermissions();

  const onClickOk = (payload) => () => {
    const finalPayload = { ...payload };
    Object.keys(payload).forEach(key => {
      if (!editingFields[key]) {
        delete finalPayload[key];
      }
    });
    handleConfirm(finalPayload);
  }

  const handleValuesChange = (newChange, key) => {
    let payloadCopy = { ...updatePayload };
    payloadCopy[key] = newChange;

    setUpdatePayload(payloadCopy);
  };

  const handleInput = (payload) => {
    let payloadCopy = { ...updatePayload };

    if (payload.target.name === "message") {
      payloadCopy["maintenanceModeEnabledMessage"] = payload.target.value;
    }
    if (payload.target.name === "workOrderNumber") {
      payloadCopy["maintenanceModeEnabledWorkOrderNumber"] = payload.target.value;
    }

    setUpdatePayload(payloadCopy);
  }

  const onChangeFieldEdit = (key) => (e) => {
    if (key === "maintenanceMode" && updatePayload[key] === true) {
      // do nothing; enabling maintenance mode this way is not allowed
    } else {
      const newEditingFields = { ...editingFields, [key]: e.target.checked };
      setEditingFields(newEditingFields);
      const isAnyFieldSelected = Object.values(newEditingFields).reduce((result, value) => result || value, false);
      setDisabled(isAnyFieldSelected === false);
    }
  }

  const disableSwitch = (key) => {
    // Only allow batch disable of maintenance mode
    if (key === "maintenanceMode") {
      return true
    }
    return !editingFields[key]
  }

  return (
    <Modal
      className='remote-action-confirm-modal'
      title={title}
      visible={open}
      onCancel={handleClose}
      bodyStyle={{
        height: 750,
      }}
      width={440}
      footer={
        <Row>
          <Col span={12}>
            <Button
              block
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              block
              disabled={disabled}
              onClick={onClickOk(updatePayload)}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form
            initialValues={initialValues}
            layout="vertical"
          >
            {deviceStates.filter(key => {
              switch (key) {
                case 'available':
                  return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateAvailable);
                case 'freevend':
                  return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateFreevend);
                case 'maintenanceMode':
                  return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateMaintenanceMode);
                case 'pncEnabled':
                  return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStatePncEnabled);
                default:
                  return true;
              }
            }).map(key => {
              return (
                <Form.Item
                  className="formItem"
                  key={key}
                  name={key}
                  label={
                    <div>
                      <Checkbox
                        onChange={onChangeFieldEdit(key)}
                      >
                        {convertToLabel(key)}
                      </Checkbox>
                    </div>
                  }
                >
                  <div className="formItemContent">
                    <Switch
                      defaultChecked={false}
                      disabled={disableSwitch(key)}
                      onChange={(change) => handleValuesChange(change, key)}
                    />
                  </div>
                </Form.Item>
              );
            })}
            {
              permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateMaintenanceMode) &&
                <MaintenanceModeBar
                  onChange={handleInput}
                />
            }
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default UpdateStateModal;