import { Button, Col, Descriptions, Form, Input, Modal, Row, Select, Space, Spin, } from "antd";
import { useEffect, useState } from "react";
import { tableFilterType } from "_constants";
import { useTableFilter } from "_hooks/tableFilter.hook";

import ChargingGroupEvsesList from './components/ChargingGroupEvsesList';
import ChargingGroupEvsesManagementDialog from './components/ChargingGroupEvsesManagementDialog';
import './styles.less';
import { formatFullYearMonths } from "../../_helpers";
import { fieldNames } from "../Device/fieldNames";
import { LinkOutlined } from "@ant-design/icons";
import { countryCodeOptions } from "./chargingGroupUtils";
import RefreshBtn from "../../_components/RefreshButton";
import { usePermissions } from "../../_hooks/permissions.hook";
import * as permConsts from "../../_constants/permissionConstants";

const { Option } = Select;

const ChargingGroupDetailView = ({
  detail,
  evsesColumns,
  isEditing,
  toggleEdit,
  tariffsData,
  tariffsLoading,
  getTariffsByCountryCode,
  evses,
  newEvses,
  evsesLoading,
  newEvsesLoading,
  isAddingEvse,
  onClickAddEvses,
  handleUpdateChargingGroup,
  selectedExistingRowKeys,
  selectedNewRowKeys,
  selectExistingRow,
  selectNewRow,
  onConfirmAddEvse,
  onConfirmDeleteEvse,
  onEvsesTableChange,
  pagination,
  filters,
  sorter,
  onNewEvsesTableChange,
  newPagination,
  newFilters,
  newSorter,
  publishChargingGroup,
  onDeselect,
  onSelectAll,
  getChargingGroup,
}) => {
  const [form] = Form.useForm();
  const permissions = usePermissions();

  const { columns: stationIdCols } = useTableFilter(evsesColumns, 'stationId', 0, true, tableFilterType.SEARCH);
  const { columns: chargeboxIdCols } = useTableFilter(stationIdCols, 'chargeboxId', 1, true, tableFilterType.SEARCH);
  const { columns: makeCols } = useTableFilter(chargeboxIdCols, 'make', 2, true, tableFilterType.SEARCH);
  const { columns: modelCols } = useTableFilter(makeCols, 'model', 3, true, tableFilterType.SEARCH);
  const { columns: networkCols } = useTableFilter(modelCols, 'networkOperator', 4, true, tableFilterType.SEARCH);
  const { columns: enterpriseCols } = useTableFilter(networkCols, 'enterprise', 5, true, tableFilterType.SEARCH);
  const { columns: locationCols } = useTableFilter(enterpriseCols, 'location', 6, true, tableFilterType.SEARCH);
  const { columns: addressCols } = useTableFilter(locationCols, 'address', 7, true, tableFilterType.SEARCH);
  const { columns: cityCols } = useTableFilter(addressCols, 'city', 8, true, tableFilterType.SEARCH);
  const { columns: finalCols } = useTableFilter(cityCols, 'state', 9, true, tableFilterType.SEARCH);

  const onFinish = (values) => {
    const newValues = { ...values };
    if (handleUpdateChargingGroup) {
      newValues.id = detail.id;
      handleUpdateChargingGroup(newValues);
      toggleEdit();
    }
  };

  const onCountryCodeChange = (value) => {
    getTariffsByCountryCode(value);
    return value;
  }

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [tariffsInit, setTariffsInit] = useState(false);

  const modalConfirm = () => {
    publishChargingGroup();
    setShowPublishModal(false);
  }

  const tariffList = (countryCode) => {
    if (!countryCode) {
      return <div>Please select country code</div>
    } else if (tariffsLoading) {
      return <Spin size={"small"}/>
    } else {
      return <Select
        dropdownMatchSelectWidth={false}
        placeholder="Select Tariff"
      >
        {tariffsData && tariffsData.map((tariff) => (
          <Option value={tariff.id} key={tariff.id}>{tariff.id}</Option>
        ))}
      </Select>
    }
  }

  useEffect(() => {
    const fillForm = (data) => {
      form.setFieldsValue({
        name: data?.name,
        description: data?.description,
        countryCode: data?.countryCode,
        partyId: data?.partyId,
        tariffId: data?.tariffId
      });

    };
    fillForm(detail);
  }, [detail, form]);

  useEffect(() => {
    if (detail && detail.countryCode && !tariffsInit) {
      setTariffsInit(true);
      getTariffsByCountryCode(detail.countryCode);
    }
  }, [detail, getTariffsByCountryCode, tariffsInit]);

  const refreshData = () => {
    getChargingGroup();
    // commented until resetting filters on table works
    // getEvsesForChargingGroup();
    if (detail && detail.countryCode) {
      getTariffsByCountryCode(detail.countryCode);
    }
  }

  const canUpdateChargingGroups = permissions.checkPermission(permConsts.components.chargingGroups, permConsts.actions.update);

  return (
    <div>
      <Modal
        okText="Confirm"
        title="Confirm Publish"
        visible={showPublishModal}
        onOk={() => modalConfirm()}
        onCancel={() => setShowPublishModal(false)}
      >
        <p>
          Are you sure you want to publish charging group?
          This will update the pricing for all chargers in this group if the tariff was updated.
        </p>
      </Modal>

      <Form
        className={"charging-group-form"}
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row justify={"end"} className={'header-button-group charging-group'}>
            <Form.Item>
              <Space size={"middle"}>
                {isEditing && (
                  <Button htmlType="button" onClick={toggleEdit}>
                    Cancel
                  </Button>
                )}
                {canUpdateChargingGroups && isEditing && (
                  <Button type="primary" htmlType="submit">
                    Save Update
                  </Button>
                )}
                {!isEditing && (
                  <RefreshBtn onClick={refreshData} tooltipText={'Refresh Charging Group Data'}/>
                )}
                {canUpdateChargingGroups && !isEditing && (
                  <Button onClick={() => setShowPublishModal(true)}>
                    Publish Charging Group
                  </Button>
                )}
                {canUpdateChargingGroups && !isEditing && (
                  <Button type="primary" onClick={toggleEdit}>
                    Edit Charging Group
                  </Button>
                )}
              </Space>
            </Form.Item>
        </Row>
        <Descriptions column={2} bordered={true}>
          <Descriptions.Item label={fieldNames.name}>
            {isEditing ? (
              <Form.Item
                name="name"
                className={"edit-form-label"}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            ) : (
              <div>
                {detail?.name}
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNames.description}>
            {isEditing ? (
              <Form.Item
                name="description"
                className={"edit-form-label"}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            ) : (
              <div>
                {detail?.description}
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNames.countryCode}>
            {isEditing ? (
              <Form.Item
                name="countryCode"
                className={"edit-form-label"}
                rules={[{ required: true }]}
              >
                <Select
                  dropdownMatchSelectWidth={true}
                  placeholder="Select Country Code"
                  onChange={onCountryCodeChange}
                >
                  {countryCodeOptions.map(code => (<Option value={code} key={code}>{code}</Option>))}
                </Select>
              </Form.Item>
            ) : (
              <div>
                {detail?.countryCode}
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNames.partyId}>
            {isEditing ? (
              <Form.Item
                name="partyId"
                className={"edit-form-label"}
                rules={[{ required: true }]}
              >
                <Input maxLength={3} placeholder={"e.g. EAM, ECA, etc"} />
              </Form.Item>
            ) : (
              <div>
                {detail?.partyId}
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNames.tariffId}>
            {isEditing ? (
              <Form.Item
                name="tariffId"
                className={"edit-form-label"}
                rules={[{ required: true }]}
              >
                {tariffList(detail?.countryCode)}
              </Form.Item>
            ) : (
              detail && detail.tariffId ?
              <a href={`${process.env.REACT_APP_PRICING_MANAGEMENT_URL}/admin/content/tariffs/${detail.tariffId}`}
                 target={"_blank"} rel="noopener noreferrer"><span>{detail.tariffId} <LinkOutlined /></span></a> : <div></div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNames.lastUpdatedInCPO}>
            {detail?.lastUpdatedInCPO ? formatFullYearMonths(detail?.lastUpdatedInCPO) : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNames.totalEvses}>
            {detail?.totalEvses}
          </Descriptions.Item>
        </Descriptions>
      </Form>
      <Row justify="space-between">
        <Col span={24}>
          <ChargingGroupEvsesList 
            columns={finalCols}
            dataSource={evses}
            loading={evsesLoading}
            pagination={pagination}
            filters={filters}
            sorter={sorter}
            onTableChange={onEvsesTableChange}
            selectExistingRow={selectExistingRow}
            selectedExistingRowKeys={selectedExistingRowKeys}
            onClickAddButton={onClickAddEvses}
            onConfirmDeleteEvse={onConfirmDeleteEvse}
            onDeselect={onDeselect}
            onSelectAll={onSelectAll}
            canUpdateChargingGroups={canUpdateChargingGroups}
          />
          <ChargingGroupEvsesManagementDialog
            open={isAddingEvse} 
            handleClose={onClickAddEvses}
            columns={finalCols}
            pagination={newPagination}
            filters={newFilters}
            sorter={newSorter}
            onTableChange={onNewEvsesTableChange}
            dataSource={newEvses}
            loading={newEvsesLoading}
            selectNewRow={selectNewRow}
            selectedNewRowKeys={selectedNewRowKeys}
            onConfirmAddEvse={onConfirmAddEvse}
            onDeselect={onDeselect}
            onSelectAll={onSelectAll}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ChargingGroupDetailView;