// use-api.js
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useApi = () => {
  const { getIdTokenClaims } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: true,
    userEmail: null,
    userPermissions: [],
    auth0Token: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const claims = await getIdTokenClaims();
        const auth0Token = claims.__raw;
        setState({
          ...state,
          loading: false,
          userEmail: claims?.email,
          userPermissions: claims?.user_authorization?.permissions, // custom claim
          auth0Token,
        })
      } catch (error) {
        setState({
          ...state,
          error,
          userEmail: null,
          userPermissions: [],
          loading: false,
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdTokenClaims]);

  return {
    ...state,
  };
};
