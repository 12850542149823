import { formatFullYearMonths } from '_helpers';
import StatusTag from "../../_components/StatusTag";

// not current used, will be used later on
const directusColumns = [
  {
    title: 'User',
    dataIndex: 'user',
    width: '15%',
    ellipsis: true,
  },
  {
    title: 'Action Type',
    dataIndex: 'action',
    width: '15%',
    ellipsis: true,
    filterMultiple: false,
    filterMode: 'menu',
    filterSearch: true,
  },
  {
    title: 'Time',
    dataIndex: 'timestamp',
    render: formatFullYearMonths,
    width: '18%',
    ellipsis: true,
  },
];

const ocppColumns = [
  {
    title: 'Chargebox ID',
    dataIndex: 'resources',
    ellipsis: true,
    width: '15%',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    filterSearch: true,
    render: formatFullYearMonths,
    width: '15%',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Vendor Error Code',
    dataIndex: '',
    filterSearch: true,
    // capital V seems to be simulator specific
    render: row => renderError(row.detail.payload.VendorErrorCode ? row.detail.payload.VendorErrorCode : row.detail.payload.vendorErrorCode),
    width: '10%',
  },
  {
    title: 'Error Code',
    dataIndex: '',
    filterSearch: true,
    // capital E seems to be simulator specific
    render: row => renderError(row.detail.payload.ErrorCode ? row.detail.payload.ErrorCode : row.detail.payload.errorCode),
    width: '10%',
  },
  {
    title: 'Status',
    dataIndex: '',
    filterSearch: true,
    render: row => <StatusTag status={row.detail.payload.status}/>,
    width: '10%',
  },
  {
    title: 'Details',
    dataIndex: '',
    render: row => JSON.stringify(row.detail, null, 2),
    width: '40%'
  }
];

const renderError = (error) => {
  return !error || error.length === 0 ? 'N/A' : error;
}

export { directusColumns, ocppColumns };