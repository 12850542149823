import {get} from '_helpers/api';

/**
 * @deprecated in favor of {@link LocationsAPI.js}
 */
function getSites(token) {
    return get({path: 'sites', parameters: {}}, token);
}

export const sitesService = {
    getSites
}