import { useLogs } from '_hooks/logs.hook';
import PermissionCheck from '_partial/PermissionCheck';
import LogsView from './LogsView';
import { columns } from './table';
import { components } from "../../_constants/permissionConstants";

const LogsController = props => {
  const {
    data,
    loading,
    pagination,
    sorter,
    onChange,
    onClickExport,
    columns: newColumns,
    getLogs
  } = useLogs(columns);
  return (
    <PermissionCheck page={components.logs}>
      <LogsView
        columns={newColumns}
        data={data}
        onChange={onChange}
        onClickExport={onClickExport}
        loading={loading}
        sorter={sorter}
        pagination={pagination}
        refreshLogs={getLogs}
      />
    </PermissionCheck>
  );
}

export default LogsController;