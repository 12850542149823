import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {statusOverviewService} from "_services";
import {downloadBlob} from "../../_helpers";
import {message} from "antd";

export const getStatusOverviewAction = createAsyncThunk(
  "get/status",
  async ({ auth0Token }) => await statusOverviewService.getStatusOverview(auth0Token)
);

export const exportStatusOverviewAction = createAsyncThunk(
    'devices/status/export',
    async ({ auth0Token }) => {
      const response = await statusOverviewService.exportStatusOverview(auth0Token);
      if(response)
        downloadBlob(response.filename, response.blob);
    }
);

const statusOverviewSlice = createSlice({
  name: "statusOverview",
  initialState: {
    statusOverview: {},
    statusOverviewLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getStatusOverviewAction.fulfilled]: (state, action) => {
      state.statusOverview = action.payload;
      state.statusOverviewLoading = false;
    },
    [getStatusOverviewAction.pending]: (state, action) => {
      state.statusOverviewLoading = true;
    },
    [getStatusOverviewAction.rejected]: (state, action) => {
      state.statusOverviewLoading = false;
    },
    [exportStatusOverviewAction.fulfilled]: (state, action) => {
      state.statusOverviewLoading = false;
    },
    [exportStatusOverviewAction.pending]: (state, action) => {
      state.statusOverviewLoading = true;
    },
    [exportStatusOverviewAction.rejected]: (state, action) => {
      state.statusOverviewLoading = false;
      message.error('Export status overview failed!');
    }
  },
});

export default statusOverviewSlice.reducer;
