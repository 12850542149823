import {Button, Form, Input, Modal} from 'antd';
import {useEffect} from "react";

/**
 * Modal for entering a new email.
 *
 * @param isVisible Boolean to toggle modal visibility
 * @param setIsVisible Function to set `isVisible`
 * @param keys Set of emails
 * @param selectedRowIds List of rows that are confirmed selected
 * @param intermediateSelectedIds List of rows that are un-confirmed selected
 * @returns {JSX.Element}
 * @constructor
 */
const EmailInputModal = ({
                             isVisible,
                             setIsVisible,
                             keys,
                             setKeys,
                             intermediateSelectedIds,
                             setIntermediateSelectedIds
                         }) => {
    const [form] = Form.useForm()

    // Reset form each time modal becomes visible
    useEffect(() => {
        if (isVisible) {
            form.resetFields();
        }
    }, [isVisible, form])

    return (
        <Modal
            title={"Enter new email"}
            visible={isVisible}
            onCancel={() => {
                setIsVisible(false)
            }}
            footer={null}
        >
            {/* EMAIL FORM */}
            <Form
                layout="vertical" name="control-hooks"
                onFinish={() => {
                    // Update `keys` and `intermediateSelectedIds` with the new email, and dismiss modal
                    let newKey = form.getFieldValue("email").trim()
                    setKeys(new Set([...keys, newKey]))
                    setIntermediateSelectedIds([...intermediateSelectedIds, newKey])
                    setIsVisible(false)
                }}
                form={form}>
                {/* FORM ITEM - EMAIL INPUT */}
                <Form.Item
                    required={true}
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Non-valid email!"
                        },
                        {
                            validator: async (rule, value) => {
                                if (value && keys.has(value.trim())) {
                                    return Promise.reject(new Error("Email already exists!"));
                                }
                            }
                        }
                    ]}
                >
                    <Input placeholder={"Email"}/>
                </Form.Item>
                {/* FORM ITEM - CONFIRM BUTTON */}
                <Form.Item>
                    <Button htmlType="submit" style={{marginTop: "16px"}} type="primary" block={true}>Add email</Button>
                </Form.Item>
            </Form>

        </Modal>
    );
};

export default EmailInputModal;