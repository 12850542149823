import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { configurationService } from "../../_services/configuration";

export const getDeviceConfiguration = createAsyncThunk(
  'getDeviceConfiguration',
  async ({chargeboxId, auth0Token}) => {
    return configurationService.getDeviceConfiguration(chargeboxId, auth0Token);
  }
);

const configurationSlice = createSlice({
  name: 'config',
  initialState: {
    configuration: { id: null, chargeboxId: null, keyTO: [], historyTO: [] },
    loading: true
  },
  reducers: {},
  extraReducers: {
    [getDeviceConfiguration.fulfilled]: (state, action) => {
      state.configuration = action.payload;
      state.loading = false;
    },
    [getDeviceConfiguration.pending]: (state, action) => {
      state.loading = true;
    },
    [getDeviceConfiguration.rejected]: (state, action) => {
      state.configuration = { id: null, chargeboxId: null, keyTO: [], historyTO: [] };
      state.loading = false;
    }
  }
})

export default configurationSlice.reducer;