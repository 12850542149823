import {
    AlertOutlined,
    CarOutlined,
    ClusterOutlined,
    DollarOutlined,
    FormOutlined,
    GlobalOutlined,
    GroupOutlined,
    HomeOutlined,
    SwapOutlined,
    UserOutlined
} from '@ant-design/icons';

export const navigationOptions = [
    {
        path: '/',
        label: 'Home',
        icon: <HomeOutlined/>,
    },
    {
        path: '/devices',
        label: 'Devices',
        icon: <CarOutlined/>,
    },
    {
        path: '/locations',
        label: 'Locations',
        icon: <GlobalOutlined/>,
    },
    {
        path: '/logs',
        label: 'Logs',
        icon: <FormOutlined/>,
    },
    {
        path: '/alerts',
        label: 'Alerts',
        icon: <AlertOutlined/>,
    },
    {
        path: '/charging-groups',
        label: 'Charging Groups',
        icon: <GroupOutlined/>,
    }
    /*  {
        path: '/settings',
        label: 'Settings',
        icon: <SettingOutlined />,
      }*/
];

export const externalMenuOptions = [
    {
        url: process.env.REACT_APP_ASSET_MANAGEMENT_URL,
        label: 'Assets',
        icon: <ClusterOutlined/>,
    },
    {
        url: process.env.REACT_APP_TRANSACTION_MANAGEMENT_URL,
        label: 'Transaction',
        icon: <SwapOutlined/>,
    },
    {
        url: process.env.REACT_APP_CA_TRANSACTION_MANAGEMENT_URL,
        label: '(CA) Transaction',
        icon: <SwapOutlined/>,
    },
    {
        url: process.env.REACT_APP_ACCESS_MANAGEMENT_URL,
        label: 'Access',
        icon: <UserOutlined/>,
    },
    {
        url: process.env.REACT_APP_PRICING_MANAGEMENT_URL,
        label: 'Pricing',
        icon: <DollarOutlined/>,
    }
];

export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const auth0RedirectUrl = process.env.REACT_APP_AUTH0_REDIRECT_URL || 'http://localhost:3000';

export const SocketState = {
    UNINSTANTIATED: -1,
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
};

export const defaultTablePageSize = {
    devices: 10,
    logs: 10,
    chargingGroups: 10,
    alerts: 10
}

export const plugTypeName = {
    DCCOMBOTYP1: 'CCS',
    CHADEMO: 'CHAdeMO',
    SAEJ1772: 'L2',
}
