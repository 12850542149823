import {Form, Select} from 'antd';

const {Option} = Select;

const SitesSelectionFormItem = () => {
    const sitesSelectionOptions = [
        {
            name: "INCLUSIVE",
            value: false
        },
        {   name: "EXCLUSIVE",
            value: true}
    ]

    return (
        <Form.Item
            name="excludeSites"
            label="Sites Selected For"
            rules={[
                {
                    required: true,
                    message: "Sites Selection type"
                },
            ]}
        >
            <Select mode="single">
                {
                    sitesSelectionOptions.map(type => {
                        return <Option value={type.value}>{type.name}</Option>
                    })
                }

            </Select>
        </Form.Item>
    );
}

export default SitesSelectionFormItem;