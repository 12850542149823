import { Col, Row, Table, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
const ChargingGroupEvsesManagementDialog = ({
  title = 'Add Evses',
  width = 1600,
  open,
  columns,
  dataSource,
  loading,
  selectedNewRowKeys,
  selectNewRow,
  handleClose,
  onConfirmAddEvse,
  onTableChange,
  pagination,
  filters,
  sorter,
  onDeselect,
  onSelectAll
}) => {
  const rowSelection = {
    selectedRowKeys: selectedNewRowKeys,
    onChange: selectNewRow,
    onSelect: onDeselect,
    onSelectAll: onSelectAll,
    preserveSelectedRowKeys: true
  };
  return (
    <Modal
      title={title}
      visible={open}
      onCancel={handleClose}
      width={width}
      footer={null}
    >
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            onClick={onConfirmAddEvse}
          >Add Evse</Button>
        </Col>
      </Row>
      <Row justify="start">
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            rowKey='chargeboxId'
            dataSource={dataSource}
            loading={loading}
            onChange={onTableChange}
            pagination={pagination}
            filters={filters}
            sorter={sorter}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ChargingGroupEvsesManagementDialog;