import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import './styles.less';

const GoBackButton = (props) => {
  return (
    <div className={'go-back-button'}>
      <Button type="default" shape="circle" icon={<ArrowLeftOutlined/>}  onClick={props.onClick}/>
      <h3>{props.title}</h3>
    </div>
  );
}

export default GoBackButton