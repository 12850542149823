import React from 'react';
import { Drawer, Typography, } from 'antd';
import './styles.less';
const { Title } = Typography;

const DeltaDetailDrawer = ({
  title = 'Detail',
  width = 400,
  open,
  data,
  handleClose,
}) => {
  return (
    <Drawer
      title={title}
      width={width}
      placement="right"
      onClose={handleClose}
      visible={open}
    >
      <div className='delta-detail-container'>
        {data.map(item => {
          return (
            <div
              key={item.label}
              className='delta-item'
              style={{ height: `${100 / data.length}%` }}
            >
              {item.label && <Title level={5}>{item.label}</Title>}
                <div className='detal-detail'>
                  <pre>
                    {JSON.stringify(item.detail, null, 2) }
                  </pre>
                </div>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
}

export default DeltaDetailDrawer;