import {useAuth0} from "@auth0/auth0-react";
import LoginView from './LoginView';

const LoginController = props => {
    const {loginWithRedirect} = useAuth0();

    const onClickLogin = () => {
        loginWithRedirect()
    }
    return (
        <LoginView onClickLogin={onClickLogin}/>
    );
}

export default LoginController;