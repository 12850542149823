import { useChargingGroups, useTariffs } from '_hooks/chargingGroups.hook';
import ChargingGroupsView from './ChargingGroupsView';
import PermissionCheck from '_partial/PermissionCheck';
import { cols } from './chargingGroupsTableConfig';
import { components } from "../../_constants/permissionConstants";

const ChargingGroupsController = () => {
  const {
    handleCreateChargingGroup,
    handleUpdateChargingGroup,
    handleDeleteChargingGroup,
    ...logic
  } = useChargingGroups();

  const {...tariffsLogic} = useTariffs();

  const handleChargingGroupSubmit = (data) => {
    if (data.id) {
      handleUpdateChargingGroup(data);
    } else {
      handleCreateChargingGroup(data);
    }
  }
  return (
    <PermissionCheck page={components.chargingGroups}>
      <ChargingGroupsView
        rowKey={record => record.id}
        columns={cols}
        handleSubmit={handleChargingGroupSubmit}
        handleDelete={handleDeleteChargingGroup}
        {...logic}
        {...tariffsLogic}
      />
    </PermissionCheck>
  );
}

export default ChargingGroupsController;