import { get } from "../_helpers";

function getTimeSeriesChartData(chargeboxId, chartDataName, timeGrainOption, dates, auth0Token) {
  const params = {};
  params['timeGrain'] = timeGrainOption.name;
  params['dateFrom'] = dates[0].toISOString();
  params['dateTo'] = dates[1].toISOString();

  return get({ path: `healthdata/${chargeboxId}/${chartDataName}`, parameters: params }, auth0Token);
}

export const timeSeriesChartService = {
  getTimeSeriesChartData
};