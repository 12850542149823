import { useAlerts } from '_hooks/alerts.hook';
import PermissionCheck from '_partial/PermissionCheck';
import AlertsView from './AlertsView';
import { components } from "../../_constants/permissionConstants";

const AlertsController = () => {
  const {
    alertsSlice,
    getAlerts,
    createOrUpdateAlert,
    deleteAlert
  } = useAlerts();

  return (
    <PermissionCheck page={components.alerts}>
      <AlertsView
        alertsSlice={alertsSlice}
        getAlerts={getAlerts}
        createOrUpdateAlert={createOrUpdateAlert}
        deleteAlert={deleteAlert}
      />
    </PermissionCheck>
  )
}

export default AlertsController;