import OperationalStatusTable from "././OperationalStatusTable/OperationalStatusTable";
import NeviExemptTable from "././NeviExemptTable/NeviExemptTable";
import {Col} from "antd";

const OperationalStatusPanel = ({
                                    device,
                                }) => {
    return (
        <>

            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <OperationalStatusTable device={device}/>
            </Col>
            <NeviExemptTable device={device}/>
        </>
    );
};

export default OperationalStatusPanel;