import { get, put, post, del } from "_helpers/api";

function getStateKeys(auth0Token) {
  return get({ path: "global-state-key" }, auth0Token);
}

function updateStateKeys(body, auth0Token) {
  return put(
    {
      path: "global-state-key",
      body: body,
      opts: { headers: { "Content-Type": "application/json" } },
    },
    auth0Token
  );
}
function addStateKey(body, auth0Token) {
  return post(
    {
      path: "global-state-key",
      body: body,
      opts: { headers: { "Content-Type": "application/json" } },
    },
    auth0Token
  );
}

function deleteStateKey(id, auth0Token) {
  return del(
    {
      path: "global-state-key",
      body: [id],
      opts: { headers: { "Content-Type": "application/json" } },
    },
    auth0Token
  );
}

export const stateKeysService = {
  getStateKeys,
  updateStateKeys,
  addStateKey,
  deleteStateKey,
};
