import PermissionCheck from '_partial/PermissionCheck';
import {components} from "../../_constants/permissionConstants";
import {useNavigate} from "react-router-dom";
import LocationsTable from "../../_components/LocationsTable/LocationsTable";

const Locations = () => {
    const navigate = useNavigate();

    const navigateToDetails = (record) => {
        navigate(`/locations/${record.id}`);
    }

    return (
        <PermissionCheck page={components.devices}>
            <LocationsTable onRowClick={navigateToDetails}/>
        </PermissionCheck>
    )
}

export default Locations;