import React, {useState} from 'react';
import moment from 'moment';
import {Button, Col, DatePicker, Form, Modal, Row,} from 'antd';
import './styles.less';

/**
 * View to configure GetDiagnostics.req.
 *
 * @param open
 * @param data
 * @param title
 * @param handleClose
 * @param handleConfirm
 * @returns {JSX.Element}
 * @constructor
 */
const GetDiagnosticsRequestModal = ({
                                        open,
                                        title,
                                        handleClose,
                                        handleConfirm,
                                    }) => {
    const [disabled, setDisabled] = useState(true);

    const {RangePicker} = DatePicker;

    const [dates, setDates] = useState(null);

    const disabledDate = (current) => {
        // Cannot select any future dates or dates
        return current > moment();
    }

    const onClickOk = () => {
        handleConfirm({
            "startTime": dates[0],
            "stopTime": dates[1],
        });
    }

    return (
        <Modal
            className='remote-action-confirm-modal'
            title={title}
            visible={open}
            onCancel={handleClose}
            width={440}
            footer={
                <Row>
                    <Col span={12}>
                        <Button
                            block
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            block
                            disabled={disabled}
                            onClick={onClickOk}
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="Date Range"
                    label="Date Range"
                    rules={[
                        {
                            required: true,

                        },
                        {
                            validator: async () => {
                                // Reject if date range is greater than 24 hours
                                let twentyFourHoursInSeconds = 24 * 60 * 60
                                if (dates && dates[0] && dates[1] && dates[1].diff(dates[0], "seconds") > twentyFourHoursInSeconds) {
                                    setDisabled(true)
                                    return Promise.reject(new Error('Cannot select time frame greater than 24 hours'));
                                }
                                // Re-enable `confirm` button if date range is within 24 hours
                                else if (dates && dates[0] && dates[1] && dates[1] && dates[1].diff(dates[0], "seconds") <= twentyFourHoursInSeconds) {
                                    setDisabled(false)
                                }
                            },
                        },
                    ]}
                >
                    <RangePicker
                        disabledDate={disabledDate}
                        onChange={(val) => {
                            if (val == null) {
                                setDisabled(true)
                            }
                            setDates(val)
                        }}
                        showTime={{
                            format: 'HH:mm:ss',
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default GetDiagnosticsRequestModal;