import {Button, Modal, Space, Table} from 'antd';
import {PlusCircleFilled} from "@ant-design/icons";
import {useState} from "react";
import EmailInputModal from "./EmailInputModal";

/**
 * Modal that contains a table of emails for selection, and a button to prompt the {@link EmailInputModal} for adding new emails.
 *
 * @param initialKeys Initial set of emails
 * @param isVisible Boolean to toggle modal visibility
 * @param setIsVisible
 * @param setSelectedIds
 * @param intermediateSelectedIds List of rows that are selected but unconfirmed
 * @param setIntermediateSelectedIds
 * @returns {JSX.Element}
 * @constructor
 */
const EmailSelector = ({
                           keys,
                           setKeys,
                           isVisible,
                           setIsVisible,
                           selectedIds,
                           setSelectedIds,
                           intermediateSelectedIds,
                           setIntermediateSelectedIds
                       }) => {


    const [isEmailInputModalVisible, setIsEmailInputModalVisible] = useState(false);

    const columns = [
        {
            "title": "EMAIL",
            "dataIndex": "email",
            "key": "email"
        }
    ]

    // Execute when "Confirm" button is clicked
    const onConfirm = () => {
        setIsVisible(false)
        setSelectedIds(intermediateSelectedIds)

    }

    // Execute when modal close button is clicked
    const onCancel = () => {
        setIsVisible(false)
        setIntermediateSelectedIds(selectedIds)
    }

    const rowSelection = {
        // Executed when user directly clicks on the row's checkbox
        onChange: (newSelectedRowKeys) => {
            setIntermediateSelectedIds(newSelectedRowKeys)
        },
        // Set the selected rows to be that of `intermediateSelectedIds`
        selectedRowKeys: intermediateSelectedIds
    };

    return (
        <Modal
            title={"Select email recipients"}
            visible={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            {/* EMAIL RECIPIENT TABLE */}
            <Table
                rowSelection={rowSelection}
                rowClassName="clickable-row"
                onRow={(record) => {
                    return {
                        // Executes when user clicks anywhere on the row
                        onClick: () => {
                            // If clicked row is in `intermediateSelectedIds`, remove it from `intermediateSelectedIds`
                            if (intermediateSelectedIds.includes(record.email)) {
                                setIntermediateSelectedIds(intermediateSelectedIds.filter(e => e !== record.email))
                            }
                            // Otherwise, add clicked row to `intermediateSelectedIds`
                            else {
                                setIntermediateSelectedIds([...intermediateSelectedIds, record.email])
                            }
                        }
                    }
                }}
                rowKey={(record) => record.email}
                columns={columns}
                dataSource={((() => {
                    // Convert key set to list of: { "email" : key[i] }
                    const a = [];
                    if (keys) {
                        keys.forEach(re => {
                            a.push({
                                "email": re
                            })
                        })
                    }
                    return a
                })())}
            />
            {/* ADD NEW EMAIL BUTTON */}
            <Button
                onClick={() => setIsEmailInputModalVisible(true)}
                size="middle"
                icon={<PlusCircleFilled/>}
                block={true}
                style={{marginBottom: "11px"}}
            >
                Add email
            </Button>
            <Space style={{width: '100%', justifyContent: "right"}}>
                {intermediateSelectedIds.length > 0 ? `Selected ${intermediateSelectedIds.length} recipients` : ''}
                {/* CONFIRM AND DISMISS MODAL BUTTON */}
                <Button
                    type="primary"
                    onClick={onConfirm}
                >Confirm</Button>
            </Space>

            {/* EMAIL INPUT MODAL */}
            <EmailInputModal isVisible={isEmailInputModalVisible} setIsVisible={setIsEmailInputModalVisible}
                             keys={keys} setKeys={setKeys} intermediateSelectedIds={intermediateSelectedIds}
                             setIntermediateSelectedIds={setIntermediateSelectedIds}/>
        </Modal>
    )
};

export default EmailSelector;