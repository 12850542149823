let getIdTokenClaims = null;

export const security = {
    getToken: () => {
        return getIdTokenClaims().then(idToken => {
            return idToken.__raw;
        })
    },
    setGetIdTokenClaims: (func) => (getIdTokenClaims = func)
};
