import {get, put, del} from '_helpers/api';

function getAlerts(token) {
    return get({path: 'alerts', parameters: {}}, token);
}

function createOrUpdateAlert(detail, token) {
    return put({path: `alerts/${detail.type}`, body: detail}, token);
}

function deleteAlert(id, token) {
    return del({path: `alerts/${id}`, body: {}}, token);
}

export const alertsService = {
    getAlerts,
    createOrUpdateAlert,
    deleteAlert,
}