import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {sitesService} from '_services';

/**
 * @deprecated in favor of {@link LocationsAPI.js}
 */
export const getSitesAsyncThunk = createAsyncThunk(
    'sites/async/get',
    async ({token}) => {
        return sitesService.getSites(token);
    }
)

/**
 * @deprecated in favor of {@link LocationsAPI.js}
 */
export default createSlice({
    name: 'sites',
    initialState: {
        data: [],
        loading: false,
        updating: false,
        pagination: {
            total: 0,
        },
        filters: {},
        sorter: {},
    },
    extraReducers: {
        [getSitesAsyncThunk.pending]: (state, action) => {
            state.loading = true
        },
        [getSitesAsyncThunk.fulfilled]: (state, action) => {
            state.loading = false
            state.data = action.payload
        }
    }
}).reducer;