import { get, put } from "_helpers/api";

function getDeviceStates(auth0Token, chargeboxId) {
  return get({ path: `state-settings/${chargeboxId}` }, auth0Token);
}

function getDeviceStatesHistory(auth0Token, chargeboxId) {
  return get({ path: `state-settings/history/${chargeboxId}` }, auth0Token);
}

function updateDeviceStates(body, auth0Token) {
  return put(
    {
      path: `state-settings/${body.chargeboxId}`,
      body: body,
      opts: { headers: { "Content-Type": "application/json" } },
    },
    auth0Token
  );
}

export const deviceStatesService = {
  getDeviceStates,
  getDeviceStatesHistory,
  updateDeviceStates,
};
