import { Button, Col, Row, Space, Table } from "antd";
import EvsesTable from "../../../../_components/EvsesTable/ChargingGroupEvsesTable";

const ChargingGroupEvsesList = ({
  columns,
  dataSource,
  loading,
  selectedExistingRowKeys,
  selectExistingRow,
  onClickAddButton,
  onConfirmDeleteEvse,
  onTableChange,
  onDeselect,
  onSelectAll,
  pagination,
  filters,
  sorter,
  canUpdateChargingGroups
}) => {
  const rowSelection = {
    selectedRowKeys: selectedExistingRowKeys,
    onChange: selectExistingRow,
    onSelect: onDeselect,
    onSelectAll: onSelectAll,
    preserveSelectedRowKeys: true
  };

  const expandableProps = {
    expandedRowRender: record => <EvsesTable evses={record.evses} />,
    rowExpandable: record => record.evses.length > 1,
  }

  return (
    <>
      { canUpdateChargingGroups &&
        <Row justify="end">
          <Space size={"middle"}>
            <Button
              onClick={onConfirmDeleteEvse}
              disabled={selectedExistingRowKeys.length === 0}
            >
              Delete Evses
            </Button>
            <Button
              type="primary"
              onClick={onClickAddButton}
            >
              Add Evses
            </Button>
          </Space>
        </Row>
      }
      <Row justify="start">
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            rowKey="chargeboxId"
            columns={columns}
            dataSource={dataSource}
            onChange={onTableChange}
            pagination={pagination}
            filters={filters}
            sorter={sorter}
            loading={loading}
            expandable={{
              ...expandableProps,
              expandedRowKeys: dataSource?.filter(device => device.evses.length > 1).map(device => device.chargeboxId)
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default ChargingGroupEvsesList;