import {Button, Checkbox, Table, Tag} from "antd";
import "./styles.css";
import {useGetNeviExemptMessageLogQuery} from "../../../../_services/NeviAPI";
import {useState} from "react";
import moment from "moment-timezone";
import NeviExemptModal from "./NeviExemptModal/NeviExemptModal";
import {PlusCircleFilled} from "@ant-design/icons";
import {usePermissions} from "../../../../_hooks/permissions.hook";
import * as permConsts from "../../../../_constants/permissionConstants";

const NeviExemptTable = ({
                             device,
                         }) => {
    const permissions = usePermissions();
    const canCreateNeviExemptMessage = (permissions.checkPermission(permConsts.components.neviOutageExemptionLogs, permConsts.actions.update));

    const [modalOpen, setModalOpen] = useState(false);

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            render: (value) => value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "N/A",
            width: "12%"
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            width: "13%"
        },
        {
            title: "EVSE UID",
            dataIndex: "evseUid",
            width: '13%'
        },
        {
            title: "Message",
            dataIndex: "message",
            width: "23%"
        },
        {
            title: "Interval Start",
            dataIndex: "intervalStart",
            render: (value) => value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "N/A",
            width: "12%"
        },
        {
            title: "Interval End",
            dataIndex: "intervalEnd",
            render: (value) => value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "N/A",
            width: "12%"
        },
        {
            title: "Category",
            dataIndex: "category",
            render: (value) => <Tag>{value}</Tag>,
            width: "10%"
        },
        {
            title: "NEVI Exempt",
            dataIndex: "neviExempt",
            render: (value) => <Checkbox indeterminate={value} disabled/>,
            width: "5%"
        }
    ]

    const [queryParams, setQueryParams] = useState({
        chargeboxId: device?.chargeboxId,
        page: 0,
        size: 5,
        device: device
    })

    const {
        data = {},
        isFetching,
        refetch
    } = useGetNeviExemptMessageLogQuery(queryParams, {skip: !queryParams.chargeboxId});

    return (<>
            <Table
                loading={isFetching}
                pagination={{
                    total: data.totalElements,
                    pageSize: data.size,
                    onChange: (page, pageSize) => {
                        setQueryParams({
                            chargeboxId: device?.chargeboxId,
                            page: page - 1,
                            size: pageSize
                        })
                    }
                }}
                rowClassName={(record, index) => {
                    if (record.valid) {
                        return "";
                    } else {
                        return "grayed-out-row";
                    }
                }}
                columns={columns}
                dataSource={data.content}
                rowKey={record => record.id}
            />
            <NeviExemptModal device={device} open={modalOpen} setOpen={setModalOpen} refetchMessages={refetch}/>
            {canCreateNeviExemptMessage &&
                <Button
                    onClick={() => setModalOpen(true)}
                    type='primary'
                    size="middle"
                    icon={<PlusCircleFilled/>}
                >
                    Add entry
                </Button>
            }
        </>
    );
};

export default NeviExemptTable;