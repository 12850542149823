import { configurationKeysService } from "../../_services/configurationKeys";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllGlobalConfigurationKeys = createAsyncThunk(
  'getAllGlobalConfigKeys',
  async ({ auth0Token }) => {
    return configurationKeysService.getAllGlobalConfigurationKeys(auth0Token);
  }
);

const configurationKeysSlice = createSlice({
  name: 'configKeys',
  initialState: {
    configKeys: [],
    configKeysLoading: true
  },
  reducers: {},
  extraReducers: {
    [getAllGlobalConfigurationKeys.fulfilled]: (state, action) => {
      state.configKeys = action.payload;
      state.configKeysLoading = false;
    },
    [getAllGlobalConfigurationKeys.pending]: (state, action) => {
      state.configKeysLoading = true;
    },
    [getAllGlobalConfigurationKeys.rejected]: (state, action) => {
      state.configKeys = [];
      state.configKeysLoading = false;
    }
  }
})

export default configurationKeysSlice.reducer;