import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useApi} from './useApi.hook';
import {createOrUpdateAlertAsyncThunk, deleteAlertAsyncThunk, getAlertsAsyncThunk} from "../_store/alerts";

export const useAlerts = () => {
    const alertsSlice = useSelector(state => state.alertsSlice);
    const {auth0Token: token, loading: tokenLoading} = useApi();

    const dispatch = useDispatch();

    // LOAD DATA UPON INITIALIZATION
    useEffect(() => {
        if (dispatch && token && !tokenLoading) {
            dispatch(getAlertsAsyncThunk({token}))
        }
    }, [token, dispatch, tokenLoading]);

    // GET ALERTS
    const getAlerts = () => {
        dispatch(getAlertsAsyncThunk({token}))
    }

    // CREATE/UPDATE ALERT
    const createOrUpdateAlert = (payload, callback) => {
        dispatch(createOrUpdateAlertAsyncThunk({token, payload, callback}));
    };

    // DELETE ALERT
    const deleteAlert = (id, callback) => {
        dispatch(deleteAlertAsyncThunk({id, token, callback}));
    };

    return {
        alertsSlice,
        getAlerts,
        createOrUpdateAlert,
        deleteAlert
    }
};