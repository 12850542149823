import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogsDataAction, exportLogsDataAction } from '_store/logs';
import { useTableFilter } from '_hooks/tableFilter.hook';
import { defaultTablePageSize, tableFilterType } from '_constants';
import { useApi } from './useApi.hook';

//needs to accept selectedAlert if id in param
export const useLogs = (columns) => {
  const { data, loading, pagination, filters, sorter } = useSelector(state => state.logsReducer);

  const { auth0Token, loading: tokenLoading} = useApi();

  const { columns: columnsWithSearch } = useTableFilter(columns, 'resources', 2, true, tableFilterType.SEARCH);
  const { columns: columnsAllFilter } = useTableFilter(columnsWithSearch, 'time', 4, false, tableFilterType.DATE_RANGE);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && auth0Token && dispatch && filters) {
      dispatch(getLogsDataAction({
        page: 1,
        pageSize: defaultTablePageSize.logs,
        filters,
        sorter,
        auth0Token,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token]);

  const getLogs = () => {
    dispatch(getLogsDataAction({
      page: 1,
      pageSize: defaultTablePageSize.logs,
      filters: {},
      sorter: {},
      auth0Token,
    }));
  }

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(getLogsDataAction({
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
      sorter,
      auth0Token,
    }));
  }

  const onClickExport = () => {
    dispatch(exportLogsDataAction({
      filters,
      sorter,
      auth0Token,
    }));
  }

  return {
    data,
    loading,
    pagination,
    onChange,
    onClickExport,
    columns: columnsAllFilter,
    getLogs
  };
};
