import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Row, Col, Radio, Space, DatePicker, Button, Tooltip } from 'antd';
import { remoteActionTypes, stateUpdateRemoteActionTypes } from '_constants'
import './styles.less';
import { getBrowserTimezone } from "../../_helpers";
import { openNotification } from "../Notification";

const ConfirmModal = ({
  open,
  title,
  selectedAction,
  handleClose,
  handleConfirm,
}) => {
  const [remoteActionType, setRemoteActionType] = useState("IMMEDIATE");
  const [scheduledTime, setScheduledTime] = useState(null);
  const [actionTypes, setActionTypes] = useState({});

  const onDateChange = (date, dateString) => {
    setScheduledTime(date);
  }
  const onTypeChange = e => {
    setRemoteActionType(e.target.value);
  };

  useEffect(() => {
    if (
      selectedAction && (
        selectedAction.name === 'update_state' ||
        selectedAction.name === 'trigger_message_request' ||
        selectedAction.name === 'get_diagnostics_request'
      )
    ) {
      setActionTypes(stateUpdateRemoteActionTypes);
    } else {
      setActionTypes(remoteActionTypes);
    }
  }, [selectedAction]);

  const disabledDate = (current) => {
    // cannot select days before today
    return current < moment().startOf('day');
  }

  const onClickOk = () => {
    if (remoteActionType === remoteActionTypes.SCHEDULED.value && !validScheduledTime()) {
      openNotification('Invalid scheduled time', 'Scheduled time must be after current time!')
    } else {
      handleConfirm({ remoteActionType, scheduledTime });
    }
  }

  const validScheduledTime = () => {
    return scheduledTime > moment.utc(new Date()).tz(getBrowserTimezone());
  }

  return (
    <Modal
      className='remote-action-confirm-modal'
      title={title}
      visible={open}
      onCancel={handleClose}
      bodyStyle={{
        height: 360,
      }}
      width={440}
      footer={
        <Row>
          <Col span={12}><Button block onClick={handleClose}>Cancel</Button></Col>
          <Col span={12}>
            {remoteActionType === remoteActionTypes.SCHEDULED.value ?
              <Tooltip placement="top"
                       title={!validScheduledTime() ? `Scheduled time must be after current time!` : "Scheduled time is valid"}>
                <Button disabled={!validScheduledTime()} block onClick={onClickOk}>Confirm</Button>
              </Tooltip> :
              <Button block disabled={!remoteActionType} onClick={onClickOk}>Confirm</Button>
            }

          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={24}>
          <Radio.Group onChange={onTypeChange} value={remoteActionType}>
            <Space direction="vertical">
              {Object.values(actionTypes).map(type => (
                <Radio key={type.value} value={type.value}>
                  <div>{type.label}</div>
                  {type.value === remoteActionType && remoteActionType === remoteActionTypes.SCHEDULED.value ? (
                    <div>
                      <DatePicker
                        status="error"
                        size="medium"
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onDateChange}
                        value={scheduledTime}
                        disabledDate={disabledDate}
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      />
                    </div>
                  ) : null}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    </Modal>
  );
}

export default ConfirmModal;