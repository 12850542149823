import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { deviceStatesService } from "_services";

export const getDeviceStatesAction = createAsyncThunk(
  "deviceStates/get",
  async ({ auth0Token, chargeboxId }) => await deviceStatesService.getDeviceStates(auth0Token, chargeboxId)
);

export const getDeviceStatesHistoryAction = createAsyncThunk(
  "deviceStates/history/get",
  async ({ auth0Token, chargeboxId }) => await deviceStatesService.getDeviceStatesHistory(auth0Token, chargeboxId)
);

const deviceStatesSlice = createSlice({
  name: "deviceStates",
  initialState: {
    deviceStates: {
      chargeboxId: null,
      freevend: false,
      available: true,
      pncEnabled: true
    },
    deviceStatesHistory: [],
    deviceStatesLoading: true,
    deviceStatesHistoryLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getDeviceStatesAction.fulfilled]: (state, action) => {
      state.deviceStates = action.payload;
      state.deviceStatesLoading = false;
    },
    [getDeviceStatesAction.pending]: (state, action) => {
      state.deviceStatesLoading = true;
    },
    [getDeviceStatesAction.rejected]: (state, action) => {
      state.deviceStates = {};
      state.deviceStatesLoading = false;
    },
    [getDeviceStatesHistoryAction.fulfilled]: (state, action) => {
      state.deviceStatesHistory = action.payload;
      state.deviceStatesHistoryLoading = false;
    },
    [getDeviceStatesHistoryAction.pending]: (state, action) => {
      state.deviceStatesHistoryLoading = true;
    },
    [getDeviceStatesHistoryAction.rejected]: (state, action) => {
      state.deviceStatesHistory = [];
      state.deviceStatesHistoryLoading = false;
    },
  },
});

export default deviceStatesSlice.reducer;
