// helper file to convert field names from device to display names

export const fieldNames = {
  id: 'Id',
  stationId: 'Station Id',
  chargeboxId: 'Chargebox Id',
  connectionStatus: 'Connection Status',
  operationalStatus: 'Operational Status',
  maintenanceMode: 'Maintenance Mode',
  networkOperator: 'Network Operator',
  partyId: 'Party Id',
  enterprise: 'Enterprise',
  location: 'Location',
  siteId: 'Location/Site Id',
  serialNumber: 'Serial Number',
  make: 'Make',
  model: 'Model',
  lastUpdated: 'Last Updated',
  timeSinceLastCharge: 'Time Since Last Charge',

  // location fields
  address: 'Address',
  city: 'City',
  state: 'State',
  postalCode: 'Postal Code',
  country: 'Country',
  facilityType: 'Facility Type',

  // chargebox fields
  firmwareVersion: 'Firmware Version',
  firmwareUpdateTimestamp: 'Firmware Update Timestamp',
  diagnosticsTimestamp: 'Diagnostics Timestamp',
  heartbeatTimestamp: 'Heartbeat Timestamp',
  communicationTimestamp: 'Websocket Communication Timestamp',
  generation: 'Generation',
  powerBudget: 'Power Budget',
  derated: 'Derated',
  
  // chargingGroup fields
  name: 'Name',
  description: 'Description',
  tariffId: 'Tariff',
  totalEvses: 'Total Evses',
  lastUpdatedInCPO: 'Last Published',
  countryCode: 'Country Code'
}

export const portFieldNames = {
  connectorIds: 'connectorIds',
  status: 'status',
  message: 'message'
}