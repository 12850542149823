import { useParams } from "react-router-dom";
import { useChargingGroup, useTariffs } from '_hooks/chargingGroups.hook';
import PermissionCheck from '_partial/PermissionCheck';
import ChargingGroupDetailView from './ChargingGroupDetailView';
import { evsesColumns } from './evsesTable';
import { components } from "../../_constants/permissionConstants";

const ChargingGroupDetailController = () => {
  const { chargingGroupId } = useParams();

  const { chargingGroup, ...logic } = useChargingGroup(chargingGroupId);
  const { ...tariffsLogic } = useTariffs();

  return (
    <PermissionCheck page={components.chargingGroups}>
      <ChargingGroupDetailView
        detail={chargingGroup}
        evsesColumns={evsesColumns}
        {...logic}
        {...tariffsLogic}
      />
    </PermissionCheck>
  )
}

export default ChargingGroupDetailController;