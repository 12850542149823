import {Tag} from "antd";

/**
 * @param tags a list of tag contents
 * @param color color of tags
 * @returns a list of tags and each tag is shown in a new paragraph
 */
const ParagraphedTags = ({tags, color}) => {
    const result = [];

    if (tags) {
        for (let i = 0; i < tags.length; i++) {
            result.push(<p><Tag key={tags[i]} color={color}>{tags[i]?tags[i]:"N/A"}</Tag></p>);
        }
    }

    return (
        result
    );
}

export default ParagraphedTags;