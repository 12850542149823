import { useDevices } from '_hooks/devices.hook';
import DevicesView from './DevicesView';
import PermissionCheck from '_partial/PermissionCheck';
import { cols } from './devicesTableConfig';
import { components } from "../../_constants/permissionConstants";
import { allCols } from "../../_constants/devicesTableColConstants";
import { useState } from "react";

const DevicesController = () => {
  const {
    columns,
    ...logic
  } = useDevices(cols);

  // columnState is the initial column state,
  // but we need to use latest colum state in the view, and it is unused
  // eslint-disable-next-line
  const [columnState, setColumnState] = useState(columns);

  function selectColumns(dataIndices) {
    const columns = prepareColumns(dataIndices);
    localStorage.setItem('selectedColumns', JSON.stringify(columns));
    // whenever columns changes by selecting or deselecting,
    // following code triggers rerender for devices table
    setColumnState(columns);
  }

  // "dataIndices" is a collection of 'column.dataIndex' from the column-selector
  function prepareColumns(dataIndices) {
    // Init with default cols
    const columns = Array.from(cols);
    // Find selected cols inside column-selector and push them as well
    const colsFromDataIndices = allCols.filter((column, i) => {return dataIndices.includes(column.dataIndex)});
    columns.push(...colsFromDataIndices);
    return columns;
  }

  return (
    <PermissionCheck page={components.devices}>
      <DevicesView
        rowKey={record => record.id}
        columns={columns}
        setColumns={selectColumns}
        {...logic}
      />
    </PermissionCheck>
  );
}

export default DevicesController;