import {Form, Select} from 'antd';

const {Option} = Select;

const IntervalFormItem = () => (
    <Form.Item
        name="alertInterval"
        label="Interval"
        rules={[{required: true, message: 'Please select an interval!'}]}
    >
        <Select placeholder="Select interval">
            <Option value={900}>15 minutes</Option>
            <Option value={1800}>30 minutes</Option>
            <Option value={3600}>1 hour</Option>
            <Option value={7200}>2 hours</Option>
            <Option value={14400}>4 hours</Option>
            <Option value={43200}>12 hours</Option>
            <Option value={86400}>1 day</Option>
        </Select>
    </Form.Item>
);

export default IntervalFormItem;