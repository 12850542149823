// use-api.js
import { useEffect, useState } from 'react';
import { useApi } from './useApi.hook';
import { useAuth0 } from '@auth0/auth0-react';
import { hasPermission } from "../_constants/permissionConstants";

export const usePermissions = () => {
  const { user } = useAuth0();
  const { userPermissions } = useApi();
  const [state, setState] = useState({
    loading: true
  });

  const checkPermission = (component, action) => {
    if (!user) {
      return false;
    }

    return hasPermission(userPermissions, component, action);
  };

  const checkMultiplePermissions = (componentActionList) => {
    if (!user) {
      return false;
    }

    for (const componentActionListElement of componentActionList) {
      const {component, action} = componentActionListElement;
      if (hasPermission(userPermissions, component, action)) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    setState({
      loading: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    ...state,
    checkPermission,
    checkMultiplePermissions
  };
};
