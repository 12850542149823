export function getRandomId() {
  return Math.random().toString(36).substr(2, 9);
}

export function convertToLabel(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).replaceAll("_", " ")
}

export function truncateString(str, maxLen, index) {
    return str.length > maxLen ?  str.substring(0, index) + '...' : str;
}