import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { chargingGroupService } from '_services';
import { defaultTablePageSize } from "../../_constants";

export const getDeviceChargingGroupsDataAction = createAsyncThunk(
  'device/chargingGroups/get',
  async ({ deviceId, page, pageSize, filters, sorter, auth0Token }) => {
    return chargingGroupService.getChargingGroups({ page, pageSize, filters, sorter }, auth0Token);
  }
);

export const getDeviceChargingGroupDataAction = createAsyncThunk(
  'device/chargingGroup/get',
  async ({ deviceId, auth0Token }) => {
    return chargingGroupService.getDeviceChargingGroup(deviceId, auth0Token);
  }
);

const deviceChargingGroupsSlice = createSlice({
  name: 'deviceChargingGroups',
  initialState: {
    data: [],
    pagination: {
      pageSize: defaultTablePageSize.chargingGroups,
      total: 0,
    },
    filters: {},
    sorter: {},
    deviceChargingGroup: {},
  },
  reducers: {},
  extraReducers: {
    [getDeviceChargingGroupsDataAction.fulfilled]: (state, action) => {

      state.data = action.payload.content;
      state.pagination.current = action.payload.number + 1;
      state.pagination.total = action.payload.totalElements;
      state.pagination.pageSize = action.payload.size;
      state.filters = action.meta.arg.filters;
      state.sorter = action.meta.arg.sorter;
      state.loading = false;
    },
    [getDeviceChargingGroupsDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getDeviceChargingGroupsDataAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDeviceChargingGroupDataAction.fulfilled]: (state, action) => {
      state.deviceChargingGroup = action.payload;
      state.loading = false;
    },
    [getDeviceChargingGroupDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getDeviceChargingGroupDataAction.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default deviceChargingGroupsSlice.reducer;
