import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useApi} from './useApi.hook';
import {getSitesAsyncThunk} from "../_store/sites";

export const useSites = () => {
    /**
     * @deprecated in favor of {@link LocationsAPI.js}
     */
    const sitesSlice = useSelector(state => state.sitesSlice);
    const {auth0Token: token, loading: tokenLoading} = useApi();

    const dispatch = useDispatch();

    // LOAD DATA UPON INITIALIZATION
    useEffect(() => {
        if (dispatch && token && !tokenLoading) {
            dispatch(getSitesAsyncThunk({token}))
        }
    }, [token, tokenLoading, dispatch]);

    return {
        sitesSlice
    }
};