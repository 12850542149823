import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { downloadBlob } from '_helpers/api';

import { logsService } from '_services';
import { defaultTablePageSize } from "../../_constants";

export const getLogsDataAction = createAsyncThunk(
  'logs/get',
  async ({ page, pageSize, filters, sorter, auth0Token }) => {
    const response = await logsService.getLogs({ page, pageSize, filters, sorter }, auth0Token);
    response.filters = filters;
    response.sorter = sorter;
    return response;
  }
);

export const exportLogsDataAction = createAsyncThunk(
  'logs/export',
  async ({ filters, sorter, auth0Token }) => {
    const response = await logsService.exportLogs({ filters, sorter }, auth0Token);
    downloadBlob(response.filename, response.blob);
  }
);

export const exportRawLogsDataAction = createAsyncThunk(
  'logs/export/raw',
  async ({ deviceId, startDate, endDate, auth0Token }) => {
    const response = await logsService.exportRawLogs({ deviceId, startDate, endDate }, auth0Token);
    downloadBlob(response.filename, response.blob);
  }
);

const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    data: [],
    pagination: {
      pageSize: defaultTablePageSize.logs,
      total: 0,
      pageSizeOptions: [10, 25, 50],
      showSizeChanger: true
    },
    filters: {},
    sorter: {},
  },
  reducers: {},
  extraReducers: {
    [getLogsDataAction.fulfilled]: (state, action) => {
      state.data = action.payload.content;
      state.pagination.current = action.payload.number + 1;
      state.pagination.total = action.payload.totalElements;
      state.pagination.pageSize = action.payload.size;
      state.filters = action.payload.filters;
      state.sorter = action.payload.sorter;
      state.loading = false;
    },
    [getLogsDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getLogsDataAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [exportLogsDataAction.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [exportLogsDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [exportLogsDataAction.rejected]: (state, action) => {
      state.loading = false;
    },
    [exportRawLogsDataAction.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [exportRawLogsDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [exportRawLogsDataAction.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default logsSlice.reducer;
