import {Form} from 'antd';
import TextArea from "antd/es/input/TextArea";

const DescriptionFormItem = () => {
    return (
        <Form.Item
            name="alertDescription"
            label="Description"
            rules={[
                {
                    required: false
                }
            ]}
        >
            <TextArea rows={4}/>
        </Form.Item>
    );
}

export default DescriptionFormItem;