import { Descriptions } from "antd";
import './styles.less';

const StatusOverview = ({
  overview
}) => {
  const createStatuses = (locationObject, list) => {
    if (locationObject) {
      for (const [key, value] of Object.entries(locationObject)) {
        list.push(<Descriptions.Item key={key} label={key}>{value}</Descriptions.Item>);
      }
    }

    return list;
  }

  const createGeneralItems = () => {
    const items = [];

    if (overview) {
      items.push(<Descriptions.Item label={"Total Connections"}>{overview.websocketConnections}</Descriptions.Item>);
      items.push(<Descriptions.Item label={"Connected Evses"}>{overview.connectedEvses}</Descriptions.Item>);
      items.push(<Descriptions.Item label={"Not Connected Evses"}>{overview.notConnectedEvses}</Descriptions.Item>);
    }

    return items;
  }

  const evseStatusView = (type, title) => {
    const items = overview ? createStatuses(overview[type], []) : [];
    if (items.length > 0) {
      return <Descriptions title={title} column={2} bordered={true}>{items}</Descriptions>
    } else {
      return <div>No statuses available.</div>
    }
  }

  return (
    <>
      <div className={'home-view'}>
        <div className={'connections'}>
          <Descriptions title={"Connections"} column={2} bordered={true}>
            {createGeneralItems()}
          </Descriptions>
        </div>
        <div className={'statuses connected'}>
          {evseStatusView("connectedStatuses", "Connected Evses Statuses")}
        </div>
        <div className={'statuses not-connected'}>
          {evseStatusView("notConnectedStatuses", "Not Connected Evses Statuses")}
        </div>
      </div>
    </>
  );
}

export default StatusOverview