
import { Layout, Typography, Space, Button } from 'antd';
import './styles.less';

const { Title } = Typography;

const LogsView = (props) => {
  return (
    <Layout className="login-layout padB40">
      <div className="login-layout-content">
        <Space direction='vertical' size='large' align='center'>
          <Title level={4}>Device Management Portal</Title>
          <Button
            size='large'
            type="primary"
            onClick={props.onClickLogin}
          >
            Log in with Electrify America
          </Button>
        </Space>
      </div>
    </Layout>
  );
}

export default LogsView;