import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { stateKeysService } from "_services";

export const getStateKeysAction = createAsyncThunk(
  "stateKeys/get",
  async ({ auth0Token }) => await stateKeysService.getStateKeys(auth0Token)
);

const stateKeysSlice = createSlice({
  name: "stateKeys",
  initialState: {
    stateKeys: [],
    stateKeysLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getStateKeysAction.fulfilled]: (state, action) => {
      state.stateKeys = action.payload;
      state.stateKeysLoading = false;
    },
    [getStateKeysAction.pending]: (state, action) => {
      state.stateKeysLoading = true;
    },
    [getStateKeysAction.rejected]: (state, action) => {
      state.stateKeysLoading = false;
    },
  },
});

export default stateKeysSlice.reducer;
