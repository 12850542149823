import StatusTag from "../StatusTag";
import {Table} from "antd";

/**
 * Table to represent multiple EVSEs belonging to one device.
 * Intended to b used in Devices Table's expandable row structure.
 */
const EvsesTable = ({evses}) => {
    const columns = [
        {
            title: 'Station Id',
            dataIndex: 'stationId'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, record) => (
                <StatusTag status={record.status} />
            )
        }
    ]

    return (
        <>
            <Table
                className="device-evses-table"
                key='evses'
                dataSource={evses}
                columns={columns}
                rowKey={record => record.stationId}
                pagination={{
                    defaultPageSize: 1000,
                    hideOnSinglePage: true
                }}
            />
        </>
    )
};

export default EvsesTable