import { useRef, useEffect, useState } from "react";
import { openNotification } from "_components/Notification";
import { socketUrl } from "_constants";
import { useApi } from "./useApi.hook";

const SOCKET_CONNECTION_LIMIT = 3600000;

export const useDeviceLogsSocket = (device) => {
  const { auth0Token } = useApi();
  const [messages, _setMessages] = useState([]);
  const messagesRef = useRef(messages);
  const setMessages = data => {
    messagesRef.current = data;
    _setMessages(data);
  };
  const [readyState, setReadyState] = useState(null);
  const [wsLoading, setWsLoading] = useState(false);
  const chargeboxId = device?.chargeboxId;
  const ws = useRef(null);

  useEffect(() => {
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const openSocket = () => {
    setWsLoading(true);
    if (!chargeboxId || !auth0Token) {
      setWsLoading(false);
      return null;
    }

    const onMessage = (message) => {
      const newMessages = [
        JSON.parse(message?.data),
        ...messagesRef.current,
      ];
      setMessages(newMessages);
    };

    const evseUids = device?.evses?.map(evse => evse.evseUid);

    // add chargebox id as an "evse uid" if there are multiple evses
    // supports filtering of websocket events
    if (evseUids && evseUids.length > 1) {
      evseUids.unshift(device.chargeboxId);
    }

    const searchParams = new URLSearchParams({
      chargeboxId,
      ...{ evseUids: evseUids ?? [] },
      Authorization: `Bearer ${auth0Token}`
    });

    ws.current = new WebSocket(`${socketUrl}?${searchParams.toString()}`);
    ws.current.onopen = () => {
      console.log("ws opened")
      setWsLoading(false);
      setReadyState(ws.current?.readyState);
    };
    ws.current.onclose = () => {
      console.log("ws closed")
      setWsLoading(false);
      setReadyState(ws.current?.readyState);
    };
    ws.current.onmessage = onMessage;
    setTimeout(() => {
      openNotification("Live messages stopped", 'Since an hour has passed, live messages have been stopped. If you want to see live messages again, toggle live mode on.');
      toggleSocket(false);
    }, SOCKET_CONNECTION_LIMIT)
  }

  const clearMessages = () => {
    setMessages([]);
  }
  
  const toggleSocket = (checked) => {
    if (checked) {
      openSocket();
    } else {
      ws.current.close();
    }
  }

  return {
    messages,
    readyState,
    wsLoading,
    toggleSocket,
    clearMessages,
  };
};