import {Button, Checkbox, Form, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/es/modal/Modal";
import OperationalStatusTable from "../../OperationalStatusTable/OperationalStatusTable";
import {
    useGetNeviOutageCategoriesQuery,
    useCreateNeviExemptMessageMutation
} from "../../../../../_services/NeviAPI";
import {errorNotification} from "../../../../../_components/Notification";
import {usePermissions} from "../../../../../_hooks/permissions.hook";
import * as permConsts from "../../../../../_constants/permissionConstants";

const NeviExemptModal = ({
                             device,
                             open,
                             setOpen,
                             refetchMessages
                         }) => {
    const permissions = usePermissions();
    const canCreateNeviExemptMessage = (permissions.checkPermission(permConsts.components.neviOutageExemptionLogs, permConsts.actions.update));

    const outageCategories = useGetNeviOutageCategoriesQuery().data?.map((item) => {
        return {value: item, label: item}
    })

    const dismissModal = () => {
        setOpen(false)
    }

    const [createNeviExemptMessage, {isLoading: isCreating}] = useCreateNeviExemptMessageMutation();


    const submit = (values) => {
        createNeviExemptMessage({evseUid: device?.chargeboxId, body: values}).unwrap().then(fulfilled => {
            setOpen(false)
            refetchMessages()
        }).catch(rejected => {
            errorNotification("Error", {message: rejected.data})
        })
    }

    return (
        <Modal
            destroyOnClose={true}
            title={"Add new record"}
            open={open}
            closable={true}
            footer={null}
            onCancel={dismissModal}
        >
            <Form
                layout="vertical"
                onFinish={submit}
            >
                <Form.Item
                    name="selectedOutage"
                    label={"Select outage"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <OperationalStatusTable device={device} selectable={true}/>
                </Form.Item>
                <Form.Item
                    name="category"
                    label={"Category"}
                    initialValue={outageCategories ? outageCategories[0].value : null}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        options={outageCategories}
                    />
                </Form.Item>
                <Form.Item
                    name="neviExempt"
                    valuePropName="checked"
                    initialValue={false}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>NEVI Exempt</Checkbox>
                </Form.Item>
                <Form.Item
                    label={"Message"}
                    name="message"
                    rules={[{
                        required: true,
                        max: 255
                    }]}
                >
                    <TextArea placeholder="Message" rows={2}/>
                </Form.Item>
                {canCreateNeviExemptMessage &&
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isCreating}
                            block
                        >
                            Create
                        </Button>
                    </Form.Item>
                }
            </Form>
        </Modal>
    );
};

export default NeviExemptModal;