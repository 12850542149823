import {Collapse} from 'antd';
import locationDetailsPanel from "./LocationDetailsPanel/LocationDetailsPanel";

const LocationDetailsTabItem = ({
                                    location = {}
                                }) => {
    return (
        <Collapse defaultActiveKey={['details']} ghost>
            {locationDetailsPanel(location)}
        </Collapse>
    );
}
export default LocationDetailsTabItem;