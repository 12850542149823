import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {alertsService} from '_services';

export const getAlertsAsyncThunk = createAsyncThunk(
    'alerts/async/get',
    async ({token}) => {
        return alertsService.getAlerts(token);
    }
)

export const createOrUpdateAlertAsyncThunk = createAsyncThunk(
    'alerts/async/create-update',
    async ({token, payload, callback}) => {
        return alertsService.createOrUpdateAlert({...payload}, token).then(() => callback());
    }
)

export const deleteAlertAsyncThunk = createAsyncThunk(
    'alerts/async/delete',
    async ({id, token, callback}) => {
        return alertsService.deleteAlert(id, token).then(() => callback());
    }
)

export default createSlice({
    name: 'alerts',
    initialState: {
        data: [],
        loading: false,
        updating: false,
        pagination: {
            total: 0,
        },
        filters: {},
        sorter: {},
    },
    extraReducers: {
        [getAlertsAsyncThunk.pending]: (state, action) => {
            state.loading = true
        },
        [getAlertsAsyncThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [createOrUpdateAlertAsyncThunk.pending]: (state, action) => {
            state.updating = true
        },
        [createOrUpdateAlertAsyncThunk.fulfilled]: (state, action) => {
            state.updating = false
        },
        [deleteAlertAsyncThunk.pending]: (state, action) => {
            state.updating = true
        },
        [deleteAlertAsyncThunk.fulfilled]: (state, action) => {
            state.updating = false
        }
    }
}).reducer;