import {configureStore} from '@reduxjs/toolkit'

import authReducer from './auth';
import logsReducer from './logs';
import deviceLogsReducer from './deviceLogs';
import tariffsReducer from './tariffs';
import alertsSlice from './alerts';
import sitesSlice from './sites';
import chargingGroupsReducer from './chargingGroups';
import evsesReducer from './evses';
import devicesReducer from './devices';
import deviceChargingGroupsReducer from './deviceChargingGroups';
import settingsReducer from './settings';
import stateKeysReducer from './stateKeys';
import deviceStatesReducer from './deviceStates';
import configurationKeysReducer from './configurationKeys';
import deviceConfigurationsReducer from './deviceConfigurations';
import statusOverviewReducer from './statusOverview';
import deviceDiagnosticsSlice from "./deviceDiagnostics";
import {locationsAPI} from "../_services/LocationsAPI";
import {setupListeners} from "@reduxjs/toolkit/query";
import {neviAPI} from "../_services/NeviAPI";

/**
 * @see https://redux-toolkit.js.org/rtk-query/api/created-api/redux-integration
 */
export const store = configureStore({
    reducer: {
        alertsSlice,
        sitesSlice,
        authReducer,
        logsReducer,
        deviceLogsReducer,
        chargingGroupsReducer,
        tariffsReducer,
        devicesReducer,
        deviceChargingGroupsReducer,
        evsesReducer,
        settingsReducer,
        stateKeysReducer,
        deviceStatesReducer,
        configurationKeysReducer,
        deviceConfigurationsReducer,
        statusOverviewReducer,
        deviceDiagnosticsSlice,
        [locationsAPI.reducerPath]: locationsAPI.reducer,
        [neviAPI.reducerPath]: neviAPI.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false // This is to disable the SerializableStateInvariantMiddleware warning
        }).concat(locationsAPI.middleware).concat(neviAPI.middleware),
})

setupListeners(store.dispatch)