import {Descriptions} from "antd";

const TagsOverview = ({
                          overview
                      }) => {
    const createTags = (tagsObject, list) => {
        if (tagsObject) {
            for (const [key, value] of Object.entries(tagsObject)) {
                list.push(<Descriptions.Item key={key} label={key}>{value}</Descriptions.Item>);
            }
        }

        return list;
    }

    const availableTagsView = () => {
        const items = overview ? createTags(overview, []) : [];
        if (items.length > 0) {
            return <Descriptions column={2} bordered={true}>{items}</Descriptions>
        } else {
            return <div>No tags available.</div>
        }
    }

    return (
        <div className={'homeView'}>
            <div className={'availableTags'}>
                {availableTagsView()}
            </div>
        </div>
    );
}

export default TagsOverview