export const timeFrame = [
    {
        key: 'H4',
        label: '4 Hours'
    }, 
    {
        key: 'D1', 
        label: '1 Day'
    }, 
    {
        key: 'D7',
        label: '7 Days'
    }
]