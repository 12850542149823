import {Button, Input, Modal, Space, Table} from 'antd';
import {useEffect, useState} from "react";
import {useSites} from "../../../_hooks/sites.hook";
import {SearchOutlined} from "@ant-design/icons";

/**
 * Modal that contains a table of Sites for selection.
 *
 * @param isVisible boolean to toggle modal visibility
 * @param setIsVisible
 * @param selectedIds list of ids that were confirmed selected
 * @param setSelectedIds
 * @param intermediateSelectedIds list of rows that are selected but unconfirmed
 * @param setIntermediateSelectedIds
 * @param hideSelectAll boolean to hide "select all" checkbox
 * @param maxSelectable maximum number of selectable rows
 * @returns {JSX.Element}
 * @constructor
 */
const SiteSelector = ({
                          isVisible,
                          setIsVisible,
                          selectedIds,
                          setSelectedIds,
                          intermediateSelectedIds,
                          setIntermediateSelectedIds,
                          hideSelectAll,
                          maxSelectable
                      }) => {
    const {sitesSlice} = useSites()

    const [operators, setOperators] = useState(new Set())

    const [states, setStates] = useState(new Set())

    const [countries, setCountries] = useState(new Set())

    // Column filters that generates based on the column data
    const getColumnFilters = (columnData) => {
        return [...columnData].sort().map(e => {
            return {
                text: e,
                value: e
            }
        })
    }

    // Column properties to enable column search functionality
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                {/*  Search box input */}
                <Input
                    placeholder={`Search ${dataIndex}`}
                    // Update `selectedKeys` with value from input, and confirm selection without dismissing search box
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({closeDropdown: false})
                    }}
                    // Dismiss search box on `enter` press
                    onPressEnter={() => confirm()}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space style={{width: '100%', justifyContent: "right"}}>
                    {/*  Button to dismiss filter dropdown */}
                    <Button
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined/>),
        // Filter out the `record` if it does not partially match the `value`
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    });

    const columns = [
        {
            title: "Site ID",
            dataIndex: "id",
            ...getColumnSearchProps("id")
        }, {
            title: "NAME",
            dataIndex: "name",
            ...getColumnSearchProps("name")
        }, {
            title: "Operator",
            dataIndex: ["operator", "name"],
            filters: getColumnFilters(operators),
            onFilter: (value, record) => record.operator && record.operator.name.includes(value),
            filterSearch: true
        }, {
            title: "Address",
            dataIndex: "address",
            ...getColumnSearchProps("address")
        }, {
            title: "City",
            dataIndex: "city",
            ...getColumnSearchProps("city")
        }, {
            title: "State",
            dataIndex: "state",
            filters: getColumnFilters(states),
            onFilter: (value, record) => record.state.includes(value),
            filterSearch: true
        }, {
            title: "Postal Code",
            dataIndex: "postal_code",
            ...getColumnSearchProps("postal_code")
        }, {
            title: "Country",
            dataIndex: "country",
            filters: getColumnFilters(countries),
            onFilter: (value, record) => record.country.includes(value),
        }
    ]

    // Update column filters whenever Sites data is updated
    useEffect(() => {
        setOperators(new Set(sitesSlice.data.filter(s => s.operator).map(s => s.operator.name)))
        setStates(new Set(sitesSlice.data.map(s => s.state)))
        setCountries(new Set(sitesSlice.data.map(s => s.country)))
    }, [sitesSlice.data])

    const rowSelection = {
        // Executed when user directly clicks on the row's checkbox
        onChange: (newSelectedRowKeys) => {
            setIntermediateSelectedIds(newSelectedRowKeys)
        },
        // Set the selected rows to be that of `intermediateSelectedIds`
        selectedRowKeys: intermediateSelectedIds,
        getCheckboxProps: (record) => ({
            // Disable record selection if `maxSelectable` is reached
            disabled: maxSelectable !== null && intermediateSelectedIds.length >= maxSelectable && !intermediateSelectedIds.includes(record.id)
        }),
        hideSelectAll: hideSelectAll
    };

    const onConfirm = () => {
        setIsVisible(false)
        setSelectedIds(intermediateSelectedIds)
    }

    return (
        <Modal
            title={"Select Sites"}
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            width={1600}
            footer={null}
        >
            {/* SITES TABLE */}
            <Table
                rowSelection={rowSelection}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={((() => {
                    const selectedSites = sitesSlice.data.filter(s => selectedIds.includes(s.id))
                    const nonSelectedSites = sitesSlice.data.filter(s => !selectedIds.includes(s.id))
                    return [...selectedSites, ...nonSelectedSites]
                })())}
            />
            <Space style={{width: '100%', justifyContent: "right"}}>
                {intermediateSelectedIds.length > 0 ? `Selected ${intermediateSelectedIds.length} sites` : ''}
                {/* CONFIRM SELECTION BUTTON */}
                <Button
                    type="primary"
                    onClick={onConfirm}
                >Confirm</Button>
            </Space>
        </Modal>
    );
};

export default SiteSelector;