import { Input, Form } from "antd";
import './styles.less';

const { TextArea } = Input;

/**
 * Component that appears at bottom of state/configuration global key/device setting forms
 * add, save, and history button
 */
const MaintenanceModeBar = ({ onChange }) => {
  return (
    <Form.Item className="maintenanceModeInfo" onChange={onChange}>
      <p>Maintenance Mode Message:</p>
      <Form.Item
        rules={[{ max: 255 }]}
      >
        <TextArea name="message" rows={4}/>
      </Form.Item>
      <p>Work Order Number:</p>
      <Form.Item 
         rules={[{ max: 20 }]}
      >
        <TextArea name="workOrderNumber" rows={1}/>
      </Form.Item>
    </Form.Item>
  )
}

export default MaintenanceModeBar;