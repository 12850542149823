import { Button, Tooltip } from "antd";
import { SyncOutlined } from "@ant-design/icons";

const RefreshBtn = (props) => {
  const {
    onClick,
    tooltipText
  } = props;

  // known bug: refreshing a TABLE doesn't clear filters :|
  return (
    <div className={'refresh'}>
      <Tooltip placement={'left'} title={tooltipText}>
        <Button
          type="secondary"
          size="middle"
          onClick={onClick}
          icon={<SyncOutlined/>}
        />
      </Tooltip>
    </div>
  );
}

export default RefreshBtn;