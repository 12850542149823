export function buildDeviceEventPayload(eventType, deviceList, resetType, remoteActionType, scheduledTime, requestedMessage, startTime, stopTime) {
  const data = {
    deviceList,
    remoteActionType,
  }
  if (eventType) {
    data['eventType'] = eventType;
  }
  if (resetType) {
    data['resetType'] = resetType;
  }
  if (scheduledTime) {
    data['scheduledTime'] = scheduledTime;
  }
  if (requestedMessage) {
    data['requestedMessage'] = requestedMessage;
  }
  if (startTime) {
    data['startTime'] = startTime;
  }
  if (stopTime) {
    data['stopTime'] = stopTime;
  }
  return data;
}

export function buildDeviceStatePayload(chargeboxPartyIds, remoteActionType, scheduledTime) {
  const data = {
    chargeboxPartyIds,
    remoteActionType,
  }
  if (scheduledTime) {
    data['scheduledTime'] = scheduledTime;
  }
  return data;
}