
import { useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import ConfirmModal from './ConfirmModal';
import './styles.less';
import UpdateStateModal from "./UpdateStateModal";
import TriggerMessageRequestModal from "./TriggerMessageRequestModal";
import GetDiagnosticsRequestModal from "./GetDiagnosticsRequestModal"

const RemoteActionsBtn = (props) => {
  const {
    title = 'Execute Action',
    onClick,
    disabled,
    showCancel,
    actions = [],
    confirm = false,
    onClickConfirm,
    selectedAction,
    clearSelection,
    currentFilters
  } = props;
  const [confirmed, setConfirmed] = useState(false);
  const [stateSelected, setStateSelected] = useState(false);
  const [showTriggerModal, setShowTriggerModal] = useState(false);
  const [showGetDiagnosticsModal, setShowGetDiagnosticsModal] = useState(false);
  const [stateData, setStateData] = useState()
  const toggleConfirm = (action) => {
    if (action.name === 'update_state') {
      setStateSelected(!stateSelected);
    } else if (action.name === 'trigger_message_request') {
      setShowTriggerModal(!showTriggerModal);
    } else if (action.name === 'get_diagnostics_request') {
      setShowGetDiagnosticsModal(!showGetDiagnosticsModal);
    } else {
      setConfirmed(!confirmed);
    }
  }
  const handleConfirm = (data) => {
    const finalData = { ...stateData, ...data, currentFilters };
    onClickConfirm(selectedAction.name)(finalData);
    handleCancel(selectedAction);
  }
  const handleSelectState = (data) => {
    setStateSelected(false);
    setStateData(data);
    setConfirmed(true);
  }
  const handleSelectTriggerType = (data) => {
    setShowTriggerModal(false);
    setStateData(data);
    setConfirmed(true);
  }

  const handleSelectGetDiagnotics = (data) => {
    setShowGetDiagnosticsModal(false);
    setStateData(data);
    setConfirmed(true);
  }

  const handleCancel = () => {
    if (clearSelection) {
      clearSelection();
    }
    setShowTriggerModal(false);
    setShowGetDiagnosticsModal(false);
    setStateSelected(false);
    setConfirmed(false);
  }

  const handleClick = (action) => () => {
    onClick(action)();
    if (!confirm) {
      toggleConfirm(action);
    }
  }
  const menu = (
    <Menu className="remote-action-menu" selectable={false}>
      {actions.map((action) => (
        <Menu.Item key={action.name}>
          <Button
            type="text"
            size="small"
            disabled={disabled}
            block
            onClick={handleClick(action)}
          >{action.label}</Button>
        </Menu.Item>
      ))}
    </Menu>
  );
  const ConfirmButton = () => {
    if (showCancel) {
      return (
        <Button
          type="primary"
          size="middle"
          onClick={clearSelection}
        >
          Cancel {selectedAction.label}
        </Button>
      );
    }
    return (
      <Button
        type="primary"
        size="middle"
        onClick={() => toggleConfirm(selectedAction)}
      >
        Confirm {selectedAction.label}
      </Button>
    );
  }
  return (
    <div className={'action-button'}>
      {confirm && selectedAction ? (
        <ConfirmButton />
      ) : (
        <Dropdown overlay={menu} placement="topRight">
          <Button
            type="primary"
            size="middle"
            icon={<PlusCircleFilled />}
          >{title}</Button>
        </Dropdown>
      )}
      <ConfirmModal
        open={confirmed}
        handleClose={handleCancel}
        handleConfirm={handleConfirm}
        selectedAction={selectedAction}
        title={`Confirm ${selectedAction && selectedAction.label}`}
      />
      <TriggerMessageRequestModal
        open={showTriggerModal}
        handleClose={handleCancel}
        handleConfirm={handleSelectTriggerType}
        title={`Confirm ${selectedAction && selectedAction.label}`}
      />
      <UpdateStateModal
        open={stateSelected}
        handleClose={handleCancel}
        handleConfirm={handleSelectState}
        title={`Confirm ${selectedAction && selectedAction.label}`}
      />
      <GetDiagnosticsRequestModal
        open={showGetDiagnosticsModal}
        handleClose={handleCancel}
        handleConfirm={handleSelectGetDiagnotics}
        title={`Confirm ${selectedAction && selectedAction.label}`}
      />
    </div>

  )
}

export default RemoteActionsBtn;