import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {settingsService} from '_services';

export const getSettingsAction = createAsyncThunk(
    'settings/get',
    async ({auth0Token}) => await settingsService.getSettings(auth0Token));

export const getSettingsHistoryAction = createAsyncThunk(
    'settings/history/get',
    async ({auth0Token}) => await settingsService.getSettingsHistory(auth0Token));

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        settings: [],
        history: [],
        loading: true,
        historyLoading: false
    },
    reducers: {},
    extraReducers: {
        [getSettingsAction.fulfilled]: (state, action) => {
            state.settings = action.payload;
            state.loading = false;
        },
        [getSettingsAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getSettingsAction.rejected]: (state, action) => {
            state.loading = false;
        },
        [getSettingsHistoryAction.fulfilled]: (state, action) => {
            state.history = action.payload
            state.historyLoading = false;
        },
        [getSettingsHistoryAction.pending]: (state, action) => {
            state.historyLoading = true;
        },
        [getSettingsHistoryAction.rejected]: (state, action) => {
            state.historyLoading = false;
        },
    },
});

export default settingsSlice.reducer;
