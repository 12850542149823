import moment from 'moment-timezone';

export function formatDateForApi(date) {
  return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function formatFullYearMonths(value) {
  return moment.utc(value).tz(getBrowserTimezone()).format('MM/DD/YYYY h:mm:ss.SSS A');
}

export function formatTime(value) {
  return moment.utc(value).tz(getBrowserTimezone()).format('h:mm:ss A');
}

export function getBrowserTimezone() {
  return moment.tz.guess();
}