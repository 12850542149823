import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from '_constants';
import {security} from "../_helpers/Security";

export const neviAPI = createApi({
    reducerPath: 'deviceStatusAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/`,
        prepareHeaders: async (headers, {getState}) => {
            const token = await security.getToken()
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getOperationalStatusLog: builder.query({
            query: (params) => {
                return `devices/${params.chargeboxId}/operational-status-log?page=${params.page}&size=${params.size}`
            }
        }),
        getNeviOutageCategories: builder.query({
            query: () => {
                return `nevi/outage-categories`
            }
        }),
        getNeviExemptMessageLog: builder.query({
            query: (params) => {
                return `devices/${params.chargeboxId}/nevi-log?page=${params.page}&size=${params.size}`
            }
        }),
        createNeviExemptMessage: builder.mutation({
            query: ({evseUid: chargeboxId, body}) => ({
                url: `devices/${chargeboxId}/nevi-log`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const {
    useGetOperationalStatusLogQuery,
    useGetNeviOutageCategoriesQuery,
    useGetNeviExemptMessageLogQuery,
    useCreateNeviExemptMessageMutation
} = neviAPI