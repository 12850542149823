import { formatFullYearMonths } from "../../_helpers";

const columns = [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    width: '15%',
    render: formatFullYearMonths,
    ellipsis: true,
    sorter: false,
  },  {
    title: 'User',
    dataIndex: 'userName',
    width: '15%',
    ellipsis: true,
    sorter: false,
  },
  {
    title: "Message",
    dataIndex: 'message',
    width: '70%',
    sorter: false
  }
];

export { columns };