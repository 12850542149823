import {Collapse, Descriptions, Typography} from "antd";

const {Panel} = Collapse;

const locationDetailsPanel = (
    location = {}
) => {
    return (
        <Panel header={<Typography.Text strong>Details</Typography.Text>} key="details">
            <Descriptions column={2} bordered={true}>
                {[
                    <Descriptions.Item label={"Location ID"} key={"locationId"}>
                        {location.id}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Country Code"} key={"countryCode"}>
                        {location.countryCode}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Party ID"} key={"partyId"}>
                        {location.partyId}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Publish"} key={"publish"}>
                        {location.publish ? location.publish.toString() : ""}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Type"} key={"type"}>
                        {location.type}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Name"} key={"name"}>
                        {location.name}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Address"} key={"address"}>
                        {location.address}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"City"} key={"city"}>
                        {location.city}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Postal Code"} key={"postalCode"}>
                        {location.postalCode}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"State"} key={"state"}>
                        {location.state}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Country"} key={"country"}>
                        {location.country}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Latitude"} key={"latitude"}>
                        {location.coordinates ? location.coordinates.latitude : ""}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Longitude"} key={"longitude"}>
                        {location.coordinates ? location.coordinates.longitude : ""}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Operator"} key={"operator"}>
                        {location.operator}
                    </Descriptions.Item>,
                    <Descriptions.Item label={"Time Zone"} key={"timeZone"}>
                        {location.timeZone}
                    </Descriptions.Item>,
                ]}
            </Descriptions>
        </Panel>
    );
}
export default locationDetailsPanel;