import { useState } from 'react';
import { Table, Row } from 'antd';
import DeltaDetailDrawer from '_components/DeltaDetailDrawer';
import ExportCSVButton from '_components/ExportCSVButton';

const LogsView = ({
  columns,
  data,
  onChange,
  pagination,
  loading,
  sorter,
  onClickExport,
  refreshLogs
}) => {
  const [open, setOpen] = useState(false);
  const [logData, setLogData] = useState([]);

  const handleOpen = (dataRecord) => {
    const data = [
      {
        label: 'Data',
        detail: dataRecord,
      },
    ];
    setLogData(data);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <div className={'header-button-group'}>
        {/* commented until resetting filters on tables works
          <RefreshBtn onClick={refreshLogs} tooltipText={'Refresh Logs'} />*/}
        <ExportCSVButton onClick={onClickExport} disabled={loading}>Export CSV</ExportCSVButton>
      </div>
      <Row>
        <Table
        rowClassName="clickable-row"
          columns={columns}
          dataSource={data}
          loading={loading}
          sorter={sorter}
          rowKey='id'
          onChange={onChange}
          pagination={pagination}
          tableLayout='fixed'
          onRow={(record) => {
            return {
              onClick: () => {
                handleOpen(record);
              }
            }
          }}
        />
        <DeltaDetailDrawer
          open={open}
          width={'60%'}
          title='Log Detail'
          handleClose={handleClose}
          data={logData}
        />
      </Row>
    </>
  );
}

export default LogsView;