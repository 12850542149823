import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Layout from '_partial/Layout';
import PageLoader from '_partial/PageLoader';

const RegisteredApp = (props) => {
  const navigate = useNavigate();

  const {
    isLoading,
    isAuthenticated,
    error,
  } = useAuth0();

  useEffect(() => {
    if (!isLoading && !error && !isAuthenticated) {
      navigate('login');
    }
  }, [isLoading, error, isAuthenticated, navigate]);

  if (isLoading) {
    return <PageLoader title="Loading ..." />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>; // TODO should be replace with beautiful UI.
  }
  if (isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return null;
}


export default RegisteredApp;
