import { useState } from "react";
import { Button, Dropdown, Menu, } from 'antd';
import { PlusCircleFilled } from "@ant-design/icons";
import { timeGrainOptions } from '_constants';

const TimeGrainOptionsBtn = ({
    onClick,
    defaultValue
}) => {

    const [timeGrainOptionLabel, setTimeGrainOptionLabel] = useState(defaultValue.label);

    const handleClick = (timeGrainOption) => () => {
        setTimeGrainOptionLabel(timeGrainOption.label)
        onClick(timeGrainOption);
    }

    const timeGrain = (
        <Menu className="time-grain-menu" selectable={false}>
            {timeGrainOptions.map((timeGrainOption) => (
                <Menu.Item key={timeGrainOption.name}>
                    <Button
                        type="text"
                        size="small"
                        block
                        onClick={handleClick(timeGrainOption)}
                    >{timeGrainOption.label}</Button>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className={'action-button'}> {(
            <Dropdown overlay={timeGrain}>
                <Button
                    type="primary"
                    size="middle"
                    icon={<PlusCircleFilled />}
                >{timeGrainOptionLabel}</Button>
            </Dropdown>)}
        </div>);
};

export default TimeGrainOptionsBtn;