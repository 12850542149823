import React from 'react';
import 'antd/dist/antd.css';
import { Select, Tag } from 'antd';

class EditableTags extends React.Component {

    state = {
        tags: [], // device tags
        dropdownData: [], // dropdown data after user typed search
        inputValue: null, // record what the user has typed and create new tags based on it
        allTags: [], // all tags available from all devices
        tagsInit: false
    };

    constructor(props) {
        super(props);
        props.tags.forEach((t) => {
            this.state.tags.push(t)
        }) // get existing tags for this device
    }

    componentDidMount = async () => {
        if (!this.props.tagTokenLoading && !this.state.tagsInit) {
            await this.fetchAllTags()
        }
    }

    selectTags = async (searchTag) => {
        const result = this.state.allTags.filter((tag) => {
            return tag.includes(searchTag.toUpperCase())
        })
        const data = result.map((item) => ({value: item, text: item}));
        this.setState({inputValue: searchTag, dropdownData: data})
    }

    fetchAllTags = async () => {
        const tags = await this.props.getTags()
        const data = tags.map((item) => ({value: item, text: item}));
        if (!this.state.tagsInit) {
            // the state has not yet init, so cannot use this.setState({...})
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.allTags = tags;
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.dropdownData = data;
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.tagsInit = true;
        } else {
            this.setState({allTags: tags, tagsInit: true, dropdownData: data})
        }
    }

    removeTag = async (tag) => {
        await this.props.deleteTag(tag)
        this.setState({tags: this.state.tags.filter(t => t !== tag)})
        await this.fetchAllTags()
    }

    SearchInput = (props) => {

        const handleChange = (newValue) => {
            this.setState({inputValue: newValue});
        };

        const handleKeyEnter = async (key) => {
            if (key["code"] === "Enter") {
                const inputTag = this.state.inputValue.toUpperCase();
                const tempTags = this.state.tags;
                // only create new a tag if the device doesn't have it.
                if (!tempTags.includes(inputTag)) {
                    await this.props.addTagToDevice(this.state.inputValue);
                    await this.fetchAllTags();
                    tempTags.push(inputTag);
                    this.setState({tags: tempTags});
                }
                this.setState({inputValue: ""});
            }
        }

        return (
            <Select
                size={"middle"}
                showSearch
                value={this.state.inputValue}
                placeholder={props.placeholder}
                style={props.style}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={this.selectTags}
                onChange={handleChange}
                notFoundContent={null}
                onInputKeyDown={handleKeyEnter}
                options={this.state.dropdownData}
            />
        );
    };

    render() {
        return (
            <div style={{alignItems: 'center'}}>
                {this.state.tags.map(
                    tag =>
                        <Tag
                            key={tag}
                            color={"blue"}
                            style={{fontSize: "15px", textAlign: 'center'}}
                            closable={this.props.canUpdateDeviceTags}
                            onClose={() => {
                                this.removeTag(tag)
                            }}>{tag}
                        </Tag>
                )}
                {this.props.canUpdateDeviceTags &&
                    <div>
                        Add Tag:
                        <this.SearchInput
                            placeholder="input tag"
                            style={{
                                width: 200,
                                marginLeft: '.5rem',
                                marginTop: '.5rem'
                            }}
                        />
                    </div>}
            </div>
        )
    }
}

export default EditableTags;
