import Modal from "antd/es/modal/Modal";
import AlertForm from "../AlertForm/AlertForm";

/**
 * Modal that contains a {@link AlertForm} for creating or updating an Alert.
 *
 * @param open Boolean to toggle modal visibility
 * @param setOpen
 * @param selectedRecord The currently selected alert
 * @param createOrUpdateAlert Function to createOrUpdate an alert on the backend
 * @param emailRecipientPool Set of all emails present in the alerts
 * @returns {JSX.Element}
 * @constructor
 */
const CreateOrUpdateAlertModal = ({
                                      open,
                                      setOpen,
                                      selectedRecord,
                                      setSelectedRecord,
                                      records,
                                      getAlerts,
                                      createOrUpdateAlert,
                                  }) => {
    const width = 800

    const dismissModal = () => {
        // Reset `selectedRecord` when modal is dismissed
        setSelectedRecord(null)
        setOpen(false)
    }

    const onSave = (values) => {
        // Save data to backend
        createOrUpdateAlert({
            id: selectedRecord ? selectedRecord.id : null,
            name: values.alertName,
            description: values.alertDescription,
            type: values.alertType,
            interval: values.alertInterval,
            active: values.active,
            emailRecipients: values.emailRecipients,
            siteIds: values.alertType === "LOW_NETWORK_AVAILABILITY" ? null : values.sites,
            excludeSites: values.alertType === "SITE_DOWN" ? values.excludeSites : null,
            severity: values.alertSeverity,
            threshold: values.sitesThreshold
        }, () => {
            // Refresh alerts when an alert update request has finished
            getAlerts()
        })
        dismissModal()
    };

    return (
        <Modal
            destroyOnClose={true}
            title={selectedRecord ? 'Update Alert' : 'Create Alert'}
            width={width}
            open={open}
            closable={false}
            footer={null}
        >
            {/* ALERT FORM */}
            <AlertForm selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord}
                       records={records}
                       onSave={onSave} onCancel={dismissModal}
                       open={open}/>
        </Modal>
    )
};

export default CreateOrUpdateAlertModal;