import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createChargingGroup,
  deleteChargingGroup,
  getChargingGroupDataByIdAction,
  getChargingGroupsDataAction,
  updateChargingGroup,
} from '_store/chargingGroups';
import { useApi } from "./useApi.hook";
import { getTariffs } from '_store/tariffs';
import { useEvses } from './evses.hook';
import { chargingGroupService } from "../_services";
import { errorNotification, openNotification } from "../_components/Notification";
import { defaultTablePageSize } from "../_constants";

export const useEditChargingGroup = () => {
  const { user } = useAuth0();
  const { auth0Token } = useApi();
  const dispatch = useDispatch();

  const handleCreateChargingGroup = (payload) => {
    dispatch(createChargingGroup({
      ...payload,
      userId: [user.email],
      auth0Token,
    }));
  };

  const handleUpdateChargingGroup = (payload) => {
    dispatch(updateChargingGroup({
      userId: [user.email],
      ...payload,
      auth0Token,
    }));
  };

  const handleDeleteChargingGroup = (payload) => {
    dispatch(deleteChargingGroup({
      id: payload.id,
      auth0Token,
    }));
  };

  return {
    handleCreateChargingGroup,
    handleUpdateChargingGroup,
    handleDeleteChargingGroup,
  }
}

export const useTariffs = () => {
  const {
    data: tariffsData,
    loading: tariffsLoading,
  } = useSelector(state => state.tariffsReducer);
  const { auth0Token } = useApi();
  const dispatch = useDispatch();

  const getTariffsByCountryCode = (countryCode) => {
    dispatch(getTariffs({countryCode, auth0Token}));
  }

  return {
    tariffsData,
    tariffsLoading,
    getTariffsByCountryCode
  };

}

export const useChargingGroups = () => {
  const {
    data,
    loading,
    updating,
    pagination,
    filters,
    sorter,
  } = useSelector(state => state.chargingGroupsReducer);

  const { auth0Token, loading: tokenLoading } = useApi();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && auth0Token && dispatch) {
      dispatch(getChargingGroupsData(null, {}, sorter, auth0Token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token, dispatch]);

  useEffect(() => {
    if (updating === false) {
      dispatch(getChargingGroupsData(pagination, filters, sorter, auth0Token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating])

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(getChargingGroupsData(pagination, filters, sorter, auth0Token));
  }

  const refreshChargingGroups = () => {
    dispatch(getChargingGroupsData(null, {}, sorter, auth0Token));
  }

  const getChargingGroupsData = (pagination, filters, sorter, auth0Token) => {
    const page = pagination ? pagination.current : 1;
    const pageSize = pagination ? pagination.pageSize : defaultTablePageSize.chargingGroups;

    return getChargingGroupsDataAction({
      page: page,
      pageSize: pageSize,
      filters: filters,
      sorter: sorter,
      auth0Token,
    });
  };

  return {
    data,
    loading,
    pagination,
    onChange,
    refreshChargingGroups,
    ...useEditChargingGroup(),
  };
};

export const useChargingGroup = (id) => {
  const { chargingGroup, loading, updating } = useSelector(state => state.chargingGroupsReducer);
  const { auth0Token, loading: tokenLoading } = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const evses = useEvses(id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenLoading && auth0Token && dispatch) {
      dispatch(getChargingGroupDataByIdAction({ id, auth0Token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token]);

  useEffect(() => {
    if (updating === false) {
      dispatch(getChargingGroupDataByIdAction({ id, auth0Token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const getChargingGroup = () => {
    dispatch(getChargingGroupDataByIdAction({ id, auth0Token }));
  }

  const publishChargingGroup = () => {
    chargingGroupService.publishChargingGroup(id, auth0Token)
      .then(() => {
        openNotification("Charging Group published", "Changes will appear in a few minutes.");
      })
      .catch((err) => {
        errorNotification("Could not publish charging group!", err);
      });
  }

  return {
    chargingGroup,
    loading,
    isEditing,
    toggleEdit,
    publishChargingGroup,
    getChargingGroup,
    ...evses,
    ...useEditChargingGroup(),
  }
}
