import { get, put } from '_helpers/api';

function getSettings(auth0Token) {
    return get({path: 'general-settings'}, auth0Token);
}

function getSettingsHistory(auth0Token) {
    return get({path: 'general-settings/history'}, auth0Token);
}

function updateSettings(body, auth0Token) {
    return put({path: 'general-settings', body}, auth0Token);
}

export const settingsService = {
    getSettings,
    getSettingsHistory,
    updateSettings,
};
