import { Form, Input, Button, Select, Space, Row, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { countryCodeOptions } from "../../chargingGroupUtils";
const { Option } = Select;

const ChargingGroupFormDialog = ({
  title = 'Create Charging Group',
  width = 600,
  open,
  record,
  tariffsData,
  tariffsLoading,
  getTariffsByCountryCode,
  handleClose,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [tariffsInit, setTariffsInit] = useState(false);

  const onFinish = (values) => {
    const newValues = { ...values };
    if (handleSubmit) {
      if (record) {
        newValues.id = record.id;
      }
      newValues.evses = [];
      handleSubmit(newValues);
      handleClose();
    }
  };

  const onReset = () => {
    form.resetFields();
    setSelectedCountryCode(null);
    handleClose();
  };

  const tariffList = () => {
    if (!selectedCountryCode) {
      return <div>Please select country code</div>
    } else if (tariffsLoading) {
      return <Spin size={"small"}/>
    } else {
      return <Select
        dropdownMatchSelectWidth={false}
        placeholder="Select Tariff"
      >
        {tariffsData && tariffsData.map((tariff) => (
          <Option value={tariff.id} key={tariff.id}>{tariff.id}</Option>
        ))}
      </Select>
    }
  }

  const onCountryCodeChange = (value) => {
    setSelectedCountryCode(value);
    getTariffsByCountryCode(value);
    return value;
  }

  useEffect(() => {
    const fillForm = (data) => {
      form.setFieldsValue({
        name: data?.name,
        description: data?.description,
        tariffId: data?.tariffId,
        countryCode: data?.countryCode,
        partyId: data?.partyId
      });
    };

    fillForm(record);
  }, [record, form]);

  useEffect(() => {
    if (record && record.countryCode && !tariffsInit) {
      setTariffsInit(true);
      getTariffsByCountryCode(record.countryCode);
    }
  }, [record, getTariffsByCountryCode, tariffsInit]);

  return (
    <Modal
      title={title}
      visible={open}
      onCancel={onReset}
      width={width}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="countryCode"
          label="Country Code"
          rules={[{ required: true }]}
        >
          <Select
            dropdownMatchSelectWidth={true}
            placeholder="Select Country Code"
            onChange={onCountryCodeChange}
          >
            {countryCodeOptions.map(code => (<Option value={code} key={code}>{code}</Option>))}
          </Select>
        </Form.Item>
        <Form.Item
          name="partyId"
          label="Party Id"
          rules={[{ required: true }]}
        >
          <Input maxLength={3} placeholder={"e.g. EAM, ECA, etc."}/>
        </Form.Item>
        <Form.Item
          name="tariffId"
          label="Tariff Id"
          rules={[{ required: true }]}
        >
          {tariffList()}
        </Form.Item>
        <Row justify={"end"}>
          <Form.Item>
            <Space size={"middle"}>
              <Button htmlType="button" onClick={onReset} className={"charging-group-button"}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChargingGroupFormDialog;