import {Form, Slider} from 'antd';

const SitesThresholdFormItem = ({
                                    hidden,
                                }) => {
    const sliderMarks = {
        0: '0%',
        25: '25%',
        50: '50%',
        75: '75%',
        100: "100%"
    };

    return (
        <Form.Item name="sitesThreshold" label="Send notification when availability is less than"
                   hidden={hidden}>
            <Slider marks={sliderMarks} step={1} defaultValue={50} tipFormatter={(value) => `${value}%`}/>
        </Form.Item>
    );
}

export default SitesThresholdFormItem;