export const actions = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
  execute: "execute",
  updateFirmware: "dm-devices-update-firmware",
  reset: "dm-devices-reset",
  triggerMessageRequest: "dm-devices-trigger-message-request",
  getDiagnosticsRequest: "dm-devices-get-diagnostics-request",
  deviceStateFreevend: "dm-devices-state-freevend",
  deviceStateAvailable: "dm-devices-state-available",
  deviceStatePncEnabled: "dm-devices-state-pnc-enabled",
  deviceStateMaintenanceMode: "dm-devices-state-maintenance-mode",
  devicePortStatus: "dm-devices-port-status",
  deviceTags: "dm-devices-tags"
}

export const components = {
  alerts: "alerts",
  chargingGroups: "charging-groups",
  devices: "devices",
  logs: "logs",
  neviOutageExemptionLogs: "nevi_outage_exemption_logs",
  settings: "settings", // currently not used for anything,
}

const formatPermission = (action, component) => {
  return `${action}:${component}`
}

const permissionsMap = {
  [components.alerts]: {
    [actions.create]: formatPermission(actions.create, components.alerts),
    [actions.read]: formatPermission(actions.read, components.alerts),
    [actions.update]: formatPermission(actions.update, components.alerts),
    [actions.delete]: formatPermission(actions.delete, components.alerts)
  },
  [components.chargingGroups]: {
    [actions.create]: formatPermission(actions.create, components.chargingGroups),
    [actions.read]: formatPermission(actions.read, components.chargingGroups),
    [actions.update]: formatPermission(actions.update, components.chargingGroups),
    [actions.delete]: formatPermission(actions.delete, components.chargingGroups)
  },
  [components.devices]: {
    [actions.read]: formatPermission(actions.read, components.devices),
    [actions.updateFirmware]: formatPermission(actions.execute, actions.updateFirmware),
    [actions.reset]: formatPermission(actions.execute, actions.reset),
    [actions.triggerMessageRequest]: formatPermission(actions.execute, actions.triggerMessageRequest),
    [actions.getDiagnosticsRequest]: formatPermission(actions.execute, actions.getDiagnosticsRequest),
    [actions.deviceStateFreevend]: formatPermission(actions.update, actions.deviceStateFreevend),
    [actions.deviceStateAvailable]: formatPermission(actions.update, actions.deviceStateAvailable),
    [actions.deviceStatePncEnabled]: formatPermission(actions.update, actions.deviceStatePncEnabled),
    [actions.deviceStateMaintenanceMode]: formatPermission(actions.update, actions.deviceStateMaintenanceMode),
    [actions.devicePortStatus]: formatPermission(actions.update, actions.devicePortStatus),
    [actions.deviceTags]: formatPermission(actions.update, actions.deviceTags)
  },
  [components.logs]: {
    [actions.read]: formatPermission(actions.read, components.logs)
  },
  [components.neviOutageExemptionLogs]: {
    [actions.update]: formatPermission(actions.update, components.neviOutageExemptionLogs)
  },
}

export const hasPermission = (permissions, component, action) => {
  return permissions && component && action && permissions.includes(permissionsMap[component][action]);
}

