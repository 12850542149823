import {Col, Row, Button, Form, Select} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import TextArea from "antd/es/input/TextArea";

const DevicePortStatusDialog = ({
                                    title = 'Change Port Status',
                                    width = 500,
                                    device,
                                    open,
                                    handleClose,
                                    togglePortStatus,
                                }) => {
    let [form] = Form.useForm();
    const portOptions = device && device.ports ? device.ports.map(port => ({label: port.id, value: port.id})) : [];
    const statusOptions = [{label: "Available", value: "Operative"}, {label: "Unavailable", value: "Inoperative"}]

    const onTogglePortStatus = (values) => {
        form.resetFields();
        togglePortStatus(values);
    }

    return (
        <Modal
            title={title}
            visible={open}
            onCancel={handleClose}
            width={width}
            footer={null}
        >
            <Row justify="start">
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="change-port-status"
                        onFinish={onTogglePortStatus}
                    >
                        <Form.Item
                            rules={[{required: true, message: `Port IDs are required`}]}
                            key="connectorIds"
                            name="connectorIds"
                            label="Port IDs"
                        >
                            <Select mode="multiple" options={portOptions}/>
                        </Form.Item>
                        <Form.Item
                            rules={[{required: true, message: `Status is required`}]}
                            key="status"
                            name="status"
                            label="Status"
                        >
                            <Select options={statusOptions} on/>
                        </Form.Item>
                        <Form.Item
                            rules={[{required: true, message: `Message is required`}]}
                            key="message"
                            name="message"
                            label="Message"
                        >
                            <TextArea
                                showCount
                                maxLength={222} // DB allows 255 characters but backend will prepend a prefix
                                rows={2}/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >Change Port Status</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};

export default DevicePortStatusDialog;