import { notification } from 'antd';

export const openNotification = (message, description) => {
    notification.open({
        message: message,
        description: description
    });
};

export const errorNotification = (notificationHeader, err) => {
    const errorBody = err.body ? err.body : { error: "Internal Server Error" };
    const errorMessage = err.message && err.message.length > 0 ? err.message : errorBody.error;
    openNotification(notificationHeader, errorMessage);
}
