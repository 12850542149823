import { useState } from 'react';
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import SideMenu from '_components/SideMenu';
import Header from '_components/Header';
import './styles.less';

const { Content } = Layout;


const CustomLayout = () => {
  const { logout } = useAuth0();
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };
  
  return (
    <Layout>
      <SideMenu
        collapsed={collapsed}
        onCollapse={onCollapse}
        onClickLogout={handleLogout}
      />
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? 80 : 270 }}
      >
        <Header/>
        <Content>
          <div className="site-layout-background site-content">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}


export default CustomLayout;
