import { Button } from "antd";
import './styles.less';

const ExportCSVButton = ({ children, ...props }) => {
  return (
    <div className={'action-button'}>
      <Button
        type="primary"
        {...props}
        size="middle"
      >
        {children}
      </Button>
    </div>
  );
}

export default ExportCSVButton;