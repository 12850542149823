import { Button, Form } from "antd";
import IntervalFormItem from "./IntervalFormItem/IntervalFormItem";
import EmailFormItem from "./EmailFormItem/EmailFormItem";
import NameFormItem from "./NameFormItem/NameFormItem";
import TypeFormItem from "./TypeFormItem/TypeFormItem";
import SitesSelectionFormItem from "./SitesSelectionFormItem/SitesSelectionFormItem";
import DescriptionFormItem from "./DescriptionFormItem/DescriptionFormItem";
import SeverityFormItem from "./SeverityFormItem/SeverityFormItem";
import SitesFormItem from "./SitesFormItem/SitesFormItem";
import SitesThresholdFormItem from "./SitesThresholdFormItem/SitesThresholdFormItem";
import IsActiveFormItem from "./IsActiveFormItem/IsActiveFormItem";
import { useCallback, useEffect, useState } from "react";
import { usePermissions } from "../../_hooks/permissions.hook";
import * as permConsts from "../../_constants/permissionConstants";

const AlertForm = ({
                       open,
                       selectedRecord,
                       records,
                       onSave,
                       onCancel
                   }) => {
    const [form] = Form.useForm()
    const [selectedAlertType, setSelectedAlertType] = useState(undefined)
    const [selectedSiteIds, setSelectedSiteIds] = useState([]);
    const [selectedEmailRecipients, setSelectedEmailRecipients] = useState([]);
    const permissions = usePermissions();

    const canCreateOrUpdateAlerts = (selectedRecord && permissions.checkPermission(permConsts.components.alerts, permConsts.actions.update)) ||
      (!selectedRecord && permissions.checkPermission(permConsts.components.alerts, permConsts.actions.create));

    const resetForm = useCallback(() => {
        form.setFieldsValue({
            alertName: selectedRecord ? selectedRecord.name : undefined,
            alertType: selectedRecord ? selectedRecord.type : undefined,
            alertDescription: selectedRecord ? selectedRecord.description : undefined,
            alertInterval: selectedRecord ? selectedRecord.interval : undefined,
            alertSeverity: selectedRecord ? selectedRecord.severity : "LOW",
            excludeSites: selectedRecord ? selectedRecord.excludeSites : false,
            sitesThreshold: selectedRecord ? selectedRecord.threshold : 50,
            active: selectedRecord ? selectedRecord.active : true,
        })
        if (selectedRecord && selectedRecord.type === "LOW_SITE_AVAILABILITY") {
            setSelectedSiteIds(selectedRecord.siteIds)
        } else if (selectedRecord && selectedRecord.type === "SITE_DOWN") {
            setSelectedSiteIds(selectedRecord.siteIds)
        } else {
            setSelectedSiteIds([])
        }
        setSelectedAlertType(selectedRecord ? selectedRecord.type : undefined)
        setSelectedEmailRecipients(selectedRecord ? selectedRecord.emailRecipients : [])
    }, [selectedRecord, form])

    useEffect(() => {
        // Reset form whenever the modal opens
        resetForm()
    }, [open, resetForm]);

    useEffect(() => {
        // Set the selectedSiteIds Form.Item when the `selectedSiteIds` state updates
        form.setFieldsValue({sites: selectedSiteIds})
    }, [selectedSiteIds, form])

    useEffect(() => {
        // Set selectedEmailRecipients Form.Item when the `selectedEmailRecipients` state updates
        form.setFieldsValue({emailRecipients: selectedEmailRecipients})
    }, [selectedEmailRecipients, form])

    return (
        <Form
            layout="vertical" form={form} name="control-hooks" onFinish={onSave}>

            <NameFormItem/>
            <TypeFormItem setSelectedAlertType={setSelectedAlertType}/>
            <DescriptionFormItem/>
            <IntervalFormItem/>
            <SeverityFormItem/>
            <SitesFormItem
                hidden={selectedAlertType === undefined || selectedAlertType === "LOW_NETWORK_AVAILABILITY"}
                selectedAlertType={selectedAlertType}
                selectedSiteIds={selectedSiteIds}
                setSelectedSiteIds={setSelectedSiteIds}/>
            {selectedAlertType === "SITE_DOWN" && <SitesSelectionFormItem/>}
            <SitesThresholdFormItem  hidden={selectedAlertType === undefined || selectedAlertType === "SITE_DOWN"}/>
            <EmailFormItem
                alertData={records}
                selectedEmailRecipients={selectedEmailRecipients}
                setSelectedEmailRecipients={setSelectedEmailRecipients}/>
            <IsActiveFormItem/>

            {/* SAVE BUTTON */}
            { canCreateOrUpdateAlerts &&
              <Form.Item>
                <Button type="primary" htmlType="submit" block>Save</Button>
              </Form.Item>
            }

            {/* CANCEL BUTTON */}
            <Form.Item>
                <Button htmlType="button" onClick={onCancel} block>Cancel</Button>
            </Form.Item>
        </Form>
    )
}

export default AlertForm;