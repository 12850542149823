const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '20%',
    sorter: false
  },
  {
    title: "Subject",
    dataIndex: 'subject',
    width: '20%',
    sorter: false
  },
  {
    title: 'Valid From',
    dataIndex: 'validFrom',
    width: '20%',
    sorter: false
  },
  {
    title: "Valid To",
    dataIndex: 'validTo',
    width: '20%',
    sorter: false
  },
  {
    title: "Installation Status",
    dataIndex: 'status',
    width: '20%',
    sorter: false
  }
];

export { columns };