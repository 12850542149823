import { useRef } from 'react';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { tableFilterType } from '_constants';
import SearchFilterForm from '_components/SearchFilterForm';

/* For further refactor, please consider _pages/Devices/columnsFilter.js */
export const useTableFilter = (columns, dataIndex, columnIndex, autoFocus, fieldType = tableFilterType.SEARCH) => {
  const searchInputRef = useRef(null);

  let Icon = FilterFilled;
  if (fieldType === tableFilterType.SEARCH) {
    Icon = SearchOutlined;
  }

  const getColumnSearchProps = () => ({
    filterDropdown: (props) => (
      <SearchFilterForm
        {...props}
        autoFocus={autoFocus}
        searchInputRef={searchInputRef}
        dataIndex={dataIndex}
        fieldType={fieldType}
      />
    ),
    filterIcon: filtered => <Icon style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: visible => {
      if (autoFocus && visible && searchInputRef && searchInputRef.current) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
  });

  const newColumns = [...columns];
  newColumns[columnIndex] = {
    ...newColumns[columnIndex],
    ...getColumnSearchProps(),
  };
  return {
    columns: newColumns,
  };
};
