import { Badge, Button, Popconfirm, Table, Tag } from "antd";
import { DeleteTwoTone, ExclamationCircleOutlined, setTwoToneColor } from "@ant-design/icons";
import { useState } from "react";
import { usePermissions } from "../../_hooks/permissions.hook";
import * as permConsts from "../../_constants/permissionConstants";

/**
 * Table for displaying Alerts.
 *
 * @param alertsSlice Slice of the state belonging to Alerts
 * @param setOpen
 * @param setSelectedRecord
 * @param getAlerts
 * @param deleteRecord Function to delete an alert record from the backend
 * @returns {JSX.Element}
 * @constructor
 */
const AlertsTable = ({
                         alertsSlice,
                         setOpen,
                         setSelectedRecord,
                         getAlerts,
                         deleteRecord
                     }) => {
    const [recordToDelete, setRecordToDelete] = useState(null);
    const permissions = usePermissions();

    setTwoToneColor("#ff0000");

    const canDeleteAlerts = permissions.checkPermission(permConsts.components.alerts, permConsts.actions.delete);

    const onCellClickedDefaultBehavior = (record, rowIndex) => {
        return {
            onClick: () => {
                setSelectedRecord(record)
                setOpen(true)
            }
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: '16%',
            sorter: (a, b) => b.name.localeCompare(a.name),
            onCell: onCellClickedDefaultBehavior,
            defaultSortOrder: 'descend'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: '16%',
            sorter: (a, b) => b.type.localeCompare(a.type),
            render: (value, row, index) => {
                return <Tag key={value}>{value}</Tag>
            },
            onCell: onCellClickedDefaultBehavior
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            width: '16%',
            sorter: (a, b) => b.severity.localeCompare(a.severity),
            render: (value, row, index) => {
                if (value === "LOW") {
                    return <Tag key={value}>{value}</Tag>
                } else if (value === "HIGH") {
                    return <Tag color="orange" key={value}>{value}</Tag>
                } else if (value === "CRITICAL") {
                    return <Tag color="red" key={value}>{value}</Tag>
                }
            },
            onCell: onCellClickedDefaultBehavior
        },
        {
            title: 'Status',
            dataIndex: 'active',
            width: '16%',
            sorter: (a, b) => a.active - b.active,
            render: (value, row, index) => {
                if (value === true) {
                    return <Badge status="success" text="Active"/>
                } else {
                    return <Badge status="error" text="Inactive"/>
                }
            },
            onCell: onCellClickedDefaultBehavior
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            width: '3%',
            render: () => canDeleteAlerts ?
                <Popconfirm okText="Yes" cancelText="No" title="Delete alert?"
                    // Delete selected record when confirmation is clicked
                            onConfirm={() => {
                                deleteRecord(recordToDelete, getAlerts)
                            }}
                            icon={<ExclamationCircleOutlined style={{color: 'red'}}/>}>
                    <Button><DeleteTwoTone/></Button>
                </Popconfirm> : <></>,
            onCell: (record, rowIndex) => {
                return {
                    // Set `recordToDelete` when cell is clicked
                    onClick: () => {
                        if (canDeleteAlerts) {
                            setRecordToDelete(record.id)
                        }
                    }
                }
            }
        }
    ];

    return (<Table
        rowClassName="clickable-row"
        columns={columns}
        dataSource={alertsSlice.data}
        rowKey={record => record.id}
        loading={alertsSlice.loading}
    />)
}

export default AlertsTable;