export const cols = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '20%',
    sorter: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '20%',
    sorter: true
  },
  {
    title: 'Tariff Id',
    dataIndex: 'tariffId',
    width: '10%',
    sorter: true,
  },
  {
    title: 'Country Code',
    dataIndex: 'countryCode',
    width: '10%',
  },
  {
    title: 'Party Id',
    dataIndex: 'partyId',
    width: '10%',
  },
  {
    title: 'Evses',
    dataIndex: 'totalEvses',
    width: '10%',
  }
];